import { Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "../../../components/TabPanel/TabPanel";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import CategoriesView from "./CategoriesView";
import OptionsView from "./OptionsView";
import "./SettingsPage.scss";

export default function SettingsPage() {
  const { t } = useTranslation();
  const { currentPage, options, categories, setCategories, setOptions } =
    useDatabaseStore();

  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      if (categories.length === 0) {
        setCategories(await GetCategories(currentPage));
      }

      if (options.length === 0) {
        let currentOptions = [] as InputDataCatalogEnum[];
        switch (currentPage) {
          case AggregatePageEnum.CostKeyFigure:
            currentOptions = [
              InputDataCatalogEnum.InputDataCostCategory,
              InputDataCatalogEnum.InputDataCostCode,
              InputDataCatalogEnum.InputDataCostUnit,
              InputDataCatalogEnum.InputDataObjectType,
              InputDataCatalogEnum.InputDataDepartment,
            ];
            break;
          case AggregatePageEnum.ProductionKeyFigure:
            currentOptions = [
              InputDataCatalogEnum.InputDataDimensionUnit,
              InputDataCatalogEnum.InputDataEquipmentType,
              InputDataCatalogEnum.InputDataObjectType,
              InputDataCatalogEnum.InputDataProductionUnit,
              InputDataCatalogEnum.InputDataDepartment,
            ];
            break;
          case AggregatePageEnum.EquipmentKeyFigure:
            currentOptions = [
              InputDataCatalogEnum.InputDataBrand,
              InputDataCatalogEnum.InputDataEquipmentType,
              InputDataCatalogEnum.InputDataDepartment,
            ];
            break;
          case AggregatePageEnum.Activities:
            currentOptions = [
              InputDataCatalogEnum.InputDataDepartment,
              InputDataCatalogEnum.InputDataPhase,
            ];
            break;
        }

        setOptions(currentOptions);
      }
    })();
  }, []);

  return (
    <div className="settings-page">
      <div className="settings-page-content">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            height: "100vh",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            width: "225px",
          }}
        >
          <Tab label={t("General")} />
          <Tab disabled label={t("Rights")} />
        </Tabs>
        <div>
          <TabPanel value={value} index={0}>
            <div className="settings-page-content-general">
              <h2>{t("General settings")}</h2>
              <Grid container spacing={2}>
                <Grid item xs>
                  <CategoriesView />
                </Grid>
                <Grid item xs>
                  <div className="titel-black">{t("Options")}</div>
                  <OptionsView />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="settings-page-content-rights">
              <h3>{t("Rights")}</h3>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "gantt-task-react/dist/index.css";
import React, { useEffect, useRef, useState } from "react";
import {
  adjustScrollBottom,
  adjustScrollWidth,
  initilaizeActivities,
  projectStyleMap,
} from "../PlanningView/helperFunctions";
import PlanningColumnDefsComponent from "../PlanningView/PlanningColumnDefs";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import useNitrogenStore from "../../../../state/NitrogenState/nitrogenState";
import Divider from "../Divider/Divider";
import "../PlanningView/PlanningView.scss";
import { EquipmentKeyFiguresTable } from "./EquipmentKeyFiguresTable";

const EquipmentSelectionView: React.FC = () => {
  const {
    mainPhases,
    activities,
    selectedActivity,
    setActivities,
    setSelectedActivity,
  } = useNitrogenStore();

  const [leftWidth, setLeftWidth] = useState(50);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activities.length === 0 && mainPhases.length > 0) {
      initilaizeActivities(mainPhases, setActivities);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      adjustScrollBottom();
      adjustScrollWidth(leftWidth);
    }, 0);
  });

  const handleRowClick = React.useCallback(
    (params: any) => {
      if (selectedActivity !== params.data) {
        setSelectedActivity(params.data);
      }
    },
    [selectedActivity]
  );

  const columnDefs = PlanningColumnDefsComponent(
    activities,
    undefined,
    undefined,
    false,
    false
  );

  return (
    activities.length > 0 && (
      <div className="planning-view" ref={containerRef}>
        <div
          className="left-pane ag-theme-quartz"
          style={{
            width: `${leftWidth}%`,
            flexGrow: 1,
            height: "fit-content",
            maxWidth: "100vw",
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={activities}
            onRowClicked={handleRowClick}
            getRowStyle={(params) => {
              const baseStyle = {
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: "300",
                color: "#0C0800",
                paddingLeft: "10px",
                borderBottom: ".5px solid #f5f5f5",
                backgroundColor: "white",
                border: "none",
              };

              if (params.node.data === selectedActivity) {
                baseStyle.border = "1.5px dashed #000000";
              }

              if (Object.keys(projectStyleMap).includes(params.data.name)) {
                baseStyle.backgroundColor =
                  projectStyleMap[params.data.name].backgroundColor;
              }
              return {
                ...baseStyle,
              };
            }}
            onSelectionChanged={(event) => {
              const selectedRows = event.api.getSelectedRows();
              if (selectedRows.length > 0) {
                setSelectedActivity(selectedRows[0]);
              } else {
                setSelectedActivity(null);
              }
            }}
          />
        </div>
        <Divider containerRef={containerRef} setLeftWidth={setLeftWidth} />
        <div
          className="right-pane nitrogen"
          style={{
            width: `${100 - leftWidth}%`,
            height: "fit-content",
          }}
        >
          <EquipmentKeyFiguresTable pageType={TypeObject.NitrogenAnalyser} />
        </div>
      </div>
    )
  );
};

export default EquipmentSelectionView;

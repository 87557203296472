import React from "react";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/styles";
import { DTO } from "../../state/ExploreState/ExploreState";

interface PlusMinusButtonProps<T extends DTO> {
  object?: T;
  selectedExportedBuilingsIds?: { code: string; count: number }[];
  setSelectedExportedBuildingsIds?: (
    values: { code: string; count: number }[]
  ) => void;
}
export default function PlusMinusButton<T extends DTO>({
  object,
  selectedExportedBuilingsIds,
  setSelectedExportedBuildingsIds,
}: PlusMinusButtonProps<T>) {
  const handleIncrement = () => {
    const updatedSelectedIds = selectedExportedBuilingsIds!.map((obj) => {
      if (obj.code === object?.code) {
        return { ...obj, count: obj.count + 1 };
      }
      return obj;
    });
    setSelectedExportedBuildingsIds!(updatedSelectedIds);
    setTimeout(() => {}, 0);
  };

  const handleDecrement = () => {
    const updatedSelectedIds = selectedExportedBuilingsIds!.map((obj) => {
      if (obj.code === object?.code && obj.count! > 0) {
        return { ...obj, count: obj.count - 1 };
      }
      return obj;
    });
    setSelectedExportedBuildingsIds!(updatedSelectedIds);
    setTimeout(() => {}, 0);
  };

  const CounterContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    border: "1px solid #003466",
    borderRadius: "10px",
    fontSize: "12px",
    height: "15px",
  });

  const CountDisplay = styled("div")({
    padding: "0px 0px",
  });

  return (
    <div>
      <CounterContainer>
        <IconButton
          style={{ padding: "0px 2px" }}
          onClick={handleDecrement}
          aria-label="minus"
        >
          <RemoveIcon sx={{ fontSize: "13px" }} />
        </IconButton>
        <CountDisplay style={{ padding: "0px" }}>
          {selectedExportedBuilingsIds!.find((x) => x.code === object?.code)
            ?.count ?? 1}
        </CountDisplay>
        <IconButton
          style={{ padding: "0px 2px" }}
          onClick={handleIncrement}
          aria-label="plus"
        >
          <AddIcon sx={{ fontSize: "13px" }} />
        </IconButton>
      </CounterContainer>
    </div>
  );
}

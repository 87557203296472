import { create } from "zustand";
import { BuildingThumbnailGenerationQueue } from "../services/BuildingThumbnailGenerationQueue";

interface BuildingThumbnailGenerationState {
  processing: boolean;
  buildingThumbnailGenerationQueue: BuildingThumbnailGenerationQueue;
}

interface BuildingThumbnailGenerationActions {
  setProcessing: (value: boolean) => void;
  setBuildingThumbnailGenerationQueue: (
    value: BuildingThumbnailGenerationQueue
  ) => void;
}

const useBuildingThumbnailGenerationStore = create<
  BuildingThumbnailGenerationState & BuildingThumbnailGenerationActions
>()((set) => ({
  processing: false,
  buildingThumbnailGenerationQueue: new BuildingThumbnailGenerationQueue(),
  setProcessing: (value: boolean) =>
    set(() => ({ processing: value })),
  setBuildingThumbnailGenerationQueue: (
    value: BuildingThumbnailGenerationQueue
  ) => set(() => ({ buildingThumbnailGenerationQueue: value })),
}));

export default useBuildingThumbnailGenerationStore;

export class BuildingThumbnailGenerationQueue {
  private queue: Set<string> = new Set();
  private processing: Set<string> = new Set();

  enqueue(buildingCode: string): boolean {
    if (this.isProcessing(buildingCode)) {
      return false;
    }
    this.queue.add(buildingCode);
    return true;
  }

  dequeue(): string | undefined {
    const next = Array.from(this.queue)[0];
    if (next) {
      this.queue.delete(next);
      this.processing.add(next);
    }
    return next;
  }

  complete(buildingCode: string): void {
    this.processing.delete(buildingCode);
  }

  reset(): void {
    this.queue.clear();
    this.processing.clear();
  }

  isProcessing(buildingCode: string): boolean {
    return this.processing.has(buildingCode) || this.queue.has(buildingCode);
  }

  size(): number {
    return this.queue.size;
  }
}
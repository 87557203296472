import { GetAllSpaceResidentials } from "../../../HSA/services/ModuleService";
import { GetAllSpaceBuildingsShortInfo } from "../../../HSA/services/BuildingService";
import { SpaceDto, SpaceResidentialDto } from "../../../HSA/types/api";
import { TrackException } from "../../logging/LoggingManager";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import { TypeObject } from "../../types/enums";

interface LoadItemsParams<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
  projectExternalId: string;
  type: TypeObject;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleObjectFilters: (objects: T[]) => void;
}

export async function LoadItems<T extends DTO>({
  currentState,
  projectExternalId,
  type,
  setIsSidebarOpen,
  handleObjectFilters,
}: LoadItemsParams<T>) {
  try {
    let tempObjects: T[] = currentState.objects ?? [];
    let selectedObjects: T[] = [];
    switch (type) {
      case TypeObject.HSABuilding:
        tempObjects = (await GetAllSpaceBuildingsShortInfo(
          currentState as unknown as ExploreState<SpaceDto> &
            ExploreActions<SpaceDto>,
          projectExternalId
        ))! as unknown as T[];
        break;
      case TypeObject.HSAResidential:
        if (tempObjects.length === 0) {
          tempObjects = (await GetAllSpaceResidentials(
            currentState as unknown as ExploreState<SpaceResidentialDto> &
              ExploreActions<SpaceResidentialDto>,
            projectExternalId
          ))! as unknown as T[];
        }
        break;
      default:
        break;
    }

    setIsSidebarOpen(selectedObjects.length > 0);
    currentState.setObjects([...tempObjects]);

    const objectsToFilter: T[] = tempObjects;

    handleObjectFilters(objectsToFilter);
    currentState.setIsObjectsLoaded(true);
  } catch (ex) {
    TrackException(ex);
  }
}

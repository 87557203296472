import React from "react";
import { useTranslation } from "react-i18next";
import CustomInputLabel from "../../../../../components/InputLabel/CustomInputLabel";
import PercentageInput from "../../PercentageInput/PercentageInput";
import NumberField from "../../PropertySectionView/NumberField";

interface BuildableAreaProps {
  percentage: number | null;
  buildableSurface: number;
  disabled?: boolean;
  onPercentageChange?: (percentage: number | null) => void;
}

const BuildableArea: React.FC<BuildableAreaProps> = ({
  percentage,
  buildableSurface,
  disabled = false,
  onPercentageChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt10">
      <CustomInputLabel
        id={"buildable-area-label"}
        showInfoButton={true}
        labelText={t("MapViewPage.BuildableArea")}
        tooltipText={t("MapViewPage.BuildableAreaTooltip")}
      />
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #acacac",
          paddingBottom: "2px",
          marginTop: "-10px",
          width: "100%",
        }}
      >
        <PercentageInput
          disabled={disabled}
          value={percentage}
          onChange={(value) => onPercentageChange?.(value)}
        />
        <NumberField
          onChange={() => {}}
          disabled={true}
          value={buildableSurface}
          unit={"m²"}
        />
      </div>
    </div>
  );
};

export default BuildableArea;

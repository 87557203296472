import { CustomTask } from "../components/PlanningView/public-types";
import { formatDateToLocalDateString } from "../../sharedLogic/utils/format";
import { PlanningActivityDto } from "./api";

export function MapToPlanningActivityDto(
  data: Partial<CustomTask>
): PlanningActivityDto {
  return {
    id: data.id ?? "",
    name: data.name ?? "Unnamed Activity",
    mainPhase: data.mainPhase ?? "",
    startDate: formatDateToLocalDateString(data.start || new Date()),
    endDate: formatDateToLocalDateString(data.end || new Date()),
    amount: data.quantity ?? 0,
    workForces: data.numberOfTeams ?? 0,
    productionPerHour: data.productionRate ?? 0,
    selectedEquipments: data.selectedEquipments || [],
    equipmentUsagePercentage: data.equipmentUsagePercentage ?? 100,
  };
}

import { convertMetersToMilimeters } from "../../../sharedLogic/utils/format";
import { SupportedValueNumbersDto } from "../../types/api";

export function CalculateMinMaxnumberOfApartments(
  accessibilityType: string,
  dimensionsLength: [number, number],
  dimensionsHeight: [number, number],
  currentSupportedValues?: SupportedValueNumbersDto
): [number, number] {
  if (!currentSupportedValues) {
    return [1, 1];
  }

  const bayWidthEntranceInMm = currentSupportedValues.bayWidthEntranceInMm ?? 0;
  const bayWidthCoreInMm = currentSupportedValues.bayWidthCoreInMm ?? 0;
  const minBayWidthInMm = currentSupportedValues.minBayWidthInMm ?? 0;
  const maxBayWidthInMm = currentSupportedValues.maxBayWidthInMm ?? 0;
  const levelHeightInMm = currentSupportedValues.levelHeightInMm ?? 0;
  const minHeightOffsetInMm = currentSupportedValues.minHeightOffsetInMm ?? 0;

  const minLengthInMm = convertMetersToMilimeters(dimensionsLength[0]);
  const maxLengthInMm = convertMetersToMilimeters(dimensionsLength[1]);
  const minHeightInMm = convertMetersToMilimeters(dimensionsHeight[0]);
  const maxHeightInMm = convertMetersToMilimeters(dimensionsHeight[1]);

  let minApartments = 1;
  let maxApartments = 1;
  if (
    accessibilityType === "HSAGallery" ||
    accessibilityType === "HSACorridor"
  ) {
    const multiplyBy = accessibilityType === "HSACorridor" ? 2 : 1;
    minApartments = calculateNumberOfApartments(
      minLengthInMm,
      minHeightInMm,
      maxBayWidthInMm,
      bayWidthEntranceInMm,
      bayWidthCoreInMm,
      levelHeightInMm,
      minHeightOffsetInMm,
      multiplyBy
    );
    maxApartments = calculateNumberOfApartments(
      maxLengthInMm,
      maxHeightInMm,
      minBayWidthInMm,
      bayWidthEntranceInMm,
      bayWidthCoreInMm,
      levelHeightInMm,
      minHeightOffsetInMm,
      multiplyBy
    );
  } else if (accessibilityType === "HSAPorchway") {
    minApartments =
      4 * Math.floor((minHeightInMm - minHeightOffsetInMm) / levelHeightInMm);
    maxApartments =
      5 * Math.floor((maxHeightInMm - minHeightOffsetInMm) / levelHeightInMm);
  }
  return [minApartments, maxApartments];
}

const calculateNumberOfApartments = (
  lengtInMm: number,
  heightInMm: number,
  bayWidthInMm: number,
  bayWidthEntranceInMm: number,
  bayWidthCoreInMm: number,
  levelHeightInMm: number,
  minHeightOffsetInMm: number,
  multiplyBy: number = 1
) => {
  const numberOfApartmentsGroundFloor = Math.floor(
    (lengtInMm - bayWidthEntranceInMm - bayWidthCoreInMm) / bayWidthInMm
  );
  const numberOfApartmentsPerFloor = Math.floor(lengtInMm / bayWidthInMm);
  const numberOfFloors = Math.floor(
    (heightInMm - minHeightOffsetInMm) / levelHeightInMm
  );

  return (
    numberOfApartmentsGroundFloor * multiplyBy +
    numberOfApartmentsPerFloor * numberOfFloors * multiplyBy
  );
};

import { Cancel, CancelOutlined } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { SpringValue, animated } from "react-spring";
import { OpenIcon } from "../../icons/OpenIcon";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import SummaryInfoDto from "../../interfaces/SummaryInfoDto";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import PlusMinusButton from "./PlusMinusButton";
import SummaryView from "./SummaryView";

interface RenderHelperProps<T extends DTO> {
  currentPageType?: TypeObject | null;
  currentObject?: T;
  isCandidate?: boolean;
  isInOriginalList?: boolean;
  isHovered?: boolean;
  setIsHovered?: Dispatch<SetStateAction<boolean>>;
  handleSelectingDatacenterComponent?: () => void;
  removeFromCandidateList?: () => void;
  checkboxAnimationStyle?: {
    backgroundColor: SpringValue<string>;
    borderColor: SpringValue<string>;
  };
  checkmarkLength?: number;
  checkmarkAnimationStyle?: {
    x: SpringValue<number>;
  };
  t?: TFunction<"translation", undefined>;
  summaryInfo?: SummaryInfoDto[];
  color?: string;
  currentStore?: ExploreState<T> & ExploreActions<T>;
}

export function renderSummaryViewSection<T extends DTO>({
  currentObject,
  summaryInfo,
}: RenderHelperProps<T>) {
  const objectType = currentObject?.typeObject;
  if (
    currentObject &&
    (objectType === TypeObject.LowVoltageRoom ||
      objectType === TypeObject.LowVoltageRoomTooling ||
      objectType === TypeObject.Datacenter ||
      objectType === TypeObject.Whitespace ||
      objectType === TypeObject.WhitespaceTooling ||
      objectType === TypeObject.NitrogenAnalyser)
  ) {
    return (
      <SummaryView
        currentObject={currentObject}
        summaryInfo={summaryInfo || []}
      />
    );
  }
  return null;
}

export function shouldRenderSummaryView(typeObject: TypeObject): boolean {
  const summaryViewTypes: TypeObject[] = [
    TypeObject.LowVoltageRoom,
    TypeObject.LowVoltageRoomTooling,
    TypeObject.Datacenter,
    TypeObject.Whitespace,
    TypeObject.WhitespaceTooling,
    TypeObject.NitrogenAnalyser,
  ];

  return summaryViewTypes.includes(typeObject);
}

export function renderExportButtonSection<T extends DTO>({
  currentPageType,
  currentObject,
  currentStore,
}: RenderHelperProps<T>) {
  if (currentPageType === TypeObject.Export) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ padding: "15px 0px" }}
      >
        <PlusMinusButton object={currentObject} />
      </Grid>
    );
  }
  return null;
}

export function renderCandidateSection<T extends DTO>(
  {
    currentPageType,
    isCandidate,
    isInOriginalList,
    handleSelectingDatacenterComponent,
    checkboxAnimationStyle,
    checkmarkLength,
    checkmarkAnimationStyle,
    t,
  }: RenderHelperProps<T>,
  setCheckmarkLength: Dispatch<SetStateAction<number>>
) {
  if (
    (isCandidate || !isCandidate) &&
    isInOriginalList &&
    currentPageType !== TypeObject.Export
  ) {
    return (
      <Grid item xs={4} justifyContent="flex-end" sx={{ marginTop: "-5px" }}>
        <div className="container-next-each-other-center">
          {!isCandidate && (
            <Button
              className="button"
              variant="contained"
              color="secondary"
              onClick={handleSelectingDatacenterComponent}
            >
              {t && t("Select")}
            </Button>
          )}
          {isCandidate && (
            <label>
              <input
                type="checkbox"
                onChange={handleSelectingDatacenterComponent}
              />
              <animated.svg
                style={checkboxAnimationStyle}
                className={`checkbox ${isCandidate ? "checkbox--active" : ""}`}
                viewBox="0 0 15 11"
                fill="none"
              >
                <animated.path
                  d="M1 4.5L5 9L14 1"
                  strokeWidth=".7"
                  stroke="#fff"
                  transform="scale(0.6) translate(5, 4)"
                  ref={(ref) => {
                    if (ref) {
                      setCheckmarkLength(ref.getTotalLength());
                    }
                  }}
                  strokeDasharray={checkmarkLength}
                  strokeDashoffset={checkmarkAnimationStyle?.x}
                />
              </animated.svg>
            </label>
          )}
          <IconButton onClick={handleSelectingDatacenterComponent}>
            <OpenIcon />
          </IconButton>
        </div>
      </Grid>
    );
  }
  return null;
}

export function renderNotInOriginalListSection<T extends DTO>({
  currentPageType,
  isInOriginalList,
  isHovered,
  setIsHovered,
  removeFromCandidateList,
}: RenderHelperProps<T>) {
  if (!isInOriginalList && currentPageType !== TypeObject.Export) {
    return (
      <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          style={{ height: "26px", width: "26px" }}
          onClick={removeFromCandidateList}
          onMouseEnter={() => {
            setIsHovered && setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered && setIsHovered(false)}
        >
          {isHovered ? (
            <Cancel
              style={{ fontSize: "1.5rem", fill: "rgba(0, 52, 102, 1)" }}
            />
          ) : (
            <CancelOutlined
              style={{ fontSize: "1.5rem", fill: "rgba(0, 52, 102, 1)" }}
            />
          )}
        </IconButton>
      </Grid>
    );
  }
  return null;
}

export function renderBestFitRateInfoCardSection<T extends DTO>({
  currentPageType,
  currentObject,
  t,
}: RenderHelperProps<T>) {
  if (currentPageType !== TypeObject.Export) {
    return (
      <>
        <Grid item xs={4}>
          <div className="sub-titel">{t && t("BestFitRateInfoCard")}:</div>
        </Grid>
        <Grid item xs={8}>
          <LinearProgressWithLabel
            backgroundColor={(currentObject as { color: string })?.color}
            value={(currentObject as { value: number })?.value}
          />
        </Grid>
      </>
    );
  }
  return null;
}

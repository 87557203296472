import CancelIcon from "@mui/icons-material/Close";
import { Box, Grid, InputBase } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnGroupingModel,
  GridEventListener,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  ValueOptions,
  useGridApiRef,
} from "@mui/x-data-grid";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/AddButton/AddButton";
import StyledDataGrid from "../../../components/StyledDataGrid/StyledDataGrid";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import { PenIcon } from "../../../icons/PenIcon";
import { SaveIcon } from "../../../icons/SaveIcon";
import { TrashIcon } from "../../../icons/TrashIcon";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { BaseKeyFigureDto } from "../../../interfaces/aggregate/BaseKeyFigureDto";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import themeVariables from "../../../styles/theme-variables.module.scss";
import { AutoComplete } from "./AutoComplete/AutoComplete";
import { OwnerDto } from "../../../interfaces/aggregate/OwnerDto";

interface DatabaseDataGridProps {
  data: { [key: string]: BaseKeyFigureDto[] };
  columns: GridColDef[];
  columnGroupingModel: GridColumnGroupingModel;
  haveEditRight?: boolean;
  addNewItem(group?: string): void;
  handleCancel(id: GridRowId): void;
  handleDelete(id: GridRowId): void;
  baseProcessRowUpdate?(newRow: GridRowModel): Promise<BaseKeyFigureDto>;
}

const DatabaseDataGrid: React.FC<DatabaseDataGridProps> = ({
  data,
  columns,
  addNewItem,
  columnGroupingModel,
  haveEditRight,
  handleCancel,
  handleDelete,
  baseProcessRowUpdate,
}) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { dataGridColumnWidth } = themeVariables;
  const {
    selectedNode,
    currentPage,
    departments,
    owners,
    isEditMode,
    gridState,
    setOwners,
    setDepartments,
  } = useDatabaseStore();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const flattenedData = useMemo(() => {
    return Object.entries(data).flatMap(([group, items]) => {
      const mappedItems = items.map((item, index) => ({
        ...item,
        isFirstInGroup: index === 0,
        isLastInGroup: index === items.length - 1,
      }));

      if (isEditMode) {
        return [
          ...mappedItems,
          { id: `add-${group}`, isAddRow: true, isLastInGroup: true, group },
        ];
      } else {
        return mappedItems;
      }
    });
  }, [data, isEditMode]);

  async function processRowUpdate(newRow: GridRowModel) {
    if (!baseProcessRowUpdate) {
      return;
    }
    const object = await baseProcessRowUpdate(newRow);
    if (!object || object.id === 0) {
      setRowModesModel({
        ...rowModesModel,
        [newRow.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      throw new Error("Error while saving");
    }
    newRow.id = object.id;
    return object;
  }

  const ownerColumn: GridColDef[] = [
    {
      field: "department",
      headerName: t("Department"),
      width: Number(dataGridColumnWidth),
      valueFormatter(value, row) {
        return row?.department?.name;
      },
      editable: true,
      valueOptions: departments.sort((a, b) => a.name.localeCompare(b.name)),
      getOptionLabel: (value: ValueOptions) =>
        (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value: ValueOptions) =>
        (value as BaseDatabaseOptionDto).id,
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={departments.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "owner",
      headerName: t("Owner"),
      width: Number(dataGridColumnWidth),
      valueFormatter(value, row) {
        return row?.owner?.emailAddress || "";
      },
      type: "singleSelect",
      editable: useUserStore
        .getState()
        .appAbility?.rules.some((rule) => rule.subject === "all"),
      valueOptions: owners.sort((a, b) =>
        a.emailAddress.localeCompare(b.emailAddress)
      ),
      getOptionLabel: (value: ValueOptions) => (value as OwnerDto).emailAddress,
      getOptionValue: (value: ValueOptions) => (value as OwnerDto).id,
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={owners.sort((a, b) =>
              a.emailAddress.localeCompare(b.emailAddress)
            )}
          />
        );
      },
    },
  ];

  const editColumns: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: t("Actions"),
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (row.isAddRow) {
          return [];
        }
        const values = Object.values(data).flat();
        const currentObject = values.find((x) => x.id === id);
        const canbeEditted =
          !currentObject?.owner ||
          currentObject.owner.emailAddress ===
            useUserStore.getState().emailAddress ||
          useUserStore
            .getState()
            .appAbility?.rules.some((rule) => rule.subject === "all") ||
          haveEditRight;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (canbeEditted && !isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<PenIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<TrashIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        } else if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [];
      },
    },
  ];

  const addColumn: GridColDef = {
    field: "addButton",
    headerName: "",
    width: 50,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.isAddRow) {
        return (
          <Box
            component="div"
            sx={{
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AddButton
              width={40}
              hoverDisabled={true}
              disableRipple={true}
              disabled={!isEditMode}
              onClick={() => {
                addNewItem(params.row.group);
              }}
              fontSize="1.5rem"
            />
          </Box>
        );
      }
      return null;
    },
  };

  const columnsWithOwner = [...columns, ...ownerColumn];
  const allColumns = isEditMode
    ? [addColumn, ...columnsWithOwner, ...editColumns]
    : columnsWithOwner;

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowEditStart: GridEventListener<"rowEditStart"> = (
    params,
    event
  ) => {
    const currentObject = Object.entries(data).flatMap(([group, items]) =>
      items.find((x) => x.id === params.id)
    )[0];
    const ableToEdit =
      currentObject?.owner?.emailAddress ===
      useUserStore.getState().emailAddress;

    if (!isEditMode || !ableToEdit) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    handleDelete(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    handleCancel(id);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    const newRow = Object.values(data)
      .flat()
      .find((x) => x.id === 0);
    if (newRow) {
      setRowModesModel((oldModel) => ({
        ...oldModel,
        0: { mode: GridRowModes.Edit },
      }));
    }

    if (departments.length === 0) {
      const fetchData = async () => {
        setDepartments(
          await GetInputDataByType(InputDataCatalogEnum.InputDataDepartment)
        );
      };
      fetchData().catch(console.error);
    }
    if (owners.length === 0) {
      const fetchData = async () => {
        setOwners(
          (await GetInputDataByType(
            InputDataCatalogEnum.InputDataOwner
          )) as OwnerDto[]
        );
      };
      fetchData().catch(console.error);
    }
  }, [data]);

  const productionKeyFigurePage =
    currentPage === AggregatePageEnum.ProductionKeyFigure ||
    currentPage === AggregatePageEnum.Activities;

  const heightGrid =
    productionKeyFigurePage && isEditMode
      ? "calc(100vh - 240px)"
      : productionKeyFigurePage && !isEditMode
      ? "calc(100vh - 180px)"
      : !productionKeyFigurePage && isEditMode
      ? "calc(100vh - 160px)"
      : "calc(100vh - 110px)";

  return (
    <Grid
      container
      direction="column"
      sx={{ height: "100vh", overflowX: "hidden" }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          height: heightGrid,
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <StyledDataGrid
          apiRef={apiRef}
          initialState={gridState}
          rows={flattenedData}
          columns={allColumns}
          isEditMode={isEditMode}
          showCellHorizontalBorders={false}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSorting
          disableColumnMenu
          hideFooter={false}
          pageSizeOptions={[50, 100]}
          rowModesModel={rowModesModel}
          columnGroupingModel={columnGroupingModel}
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          onRowEditStart={handleRowEditStart}
          onRowModesModelChange={handleRowModesModelChange}
          editMode="row"
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 3,
            bottom: params.isLastVisible ? 0 : 3,
          })}
          getRowHeight={(params) => {
            if (params.model.isGroupRow) return 50;
            if (params.model.isAddRow) return 20;
            return 35;
          }}
          getRowClassName={(params) =>
            `${params.row.isFirstInGroup ? "first-in-group" : ""} ${
              params.row.isLastInGroup ? "last-in-group" : ""
            } ${params.row.isAddRow ? "add-row" : ""}`
          }
        />
      </Grid>
      {isEditMode && (
        <Box component={"div"} sx={{ marginLeft: "-10px" }}>
          <AddButton
            width={200}
            disabled={selectedNode === ""}
            hoverDisabled={true}
            disableRipple={true}
            onClick={() => {
              const rows = apiRef.current?.getAllRowIds();
              const pages = Math.ceil(
                apiRef.current?.state.pagination.paginationModel.pageSize /
                  rows.length
              );
              apiRef.current?.setPage(pages);
              addNewItem("");

              const lastRowIndex = flattenedData.length - 1;
              if (lastRowIndex >= 0 && apiRef.current) {
                apiRef.current.scrollToIndexes({
                  rowIndex: lastRowIndex + 2,
                  colIndex: 0,
                });
              }
            }}
            fontSize="1.5rem"
            text={t("Add new type")}
          />
        </Box>
      )}
    </Grid>
  );
};

export default React.memo(DatabaseDataGrid);

import i18next from "i18next";
import { downloadFile } from "../../../http/aggregate/ImportExportService";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import {
  ExploreActions,
  ExploreState,
} from "../../../state/ExploreState/ExploreState";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../state/NitrogenState/nitrogenState";
import { aggregateApiClient } from "../../sharedLogic/services/AxiosClient";
import useProjectsStore from "../../sharedLogic/state/projectsState";
import { ScenarioDto } from "../types/api";
import { map } from "d3";
import { MapToPlanningActivityDto } from "../types/mapper";
import { IsStringUndefinedOrNullOrEmpty } from "../../sharedLogic/utils/stringUtils";

const baseUrl = "/api/emission";
export async function GetNitrogenScenarios(
  signal: AbortSignal,
  currentState?: ExploreState<ScenarioDto> & ExploreActions<ScenarioDto>
): Promise<ScenarioDto[]> {
  try {
    const nitrogenInputPaneState = useNitrogenInputPaneStore.getState();
    const tasks = useNitrogenStore.getState().activities;
    const workingDays = useNitrogenStore.getState().workingDays;
    const filteredTasks = tasks.filter(
      (x) =>
        x.id.startsWith("task-") &&
        x.selectedEquipments &&
        x.selectedEquipments.length > 0
    );
    if (filteredTasks.length === 0) {
      return [];
    }

    const planningActivities = JSON.stringify(
      filteredTasks.map((x) => MapToPlanningActivityDto(x))
    );
    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/calculate/scenarios`,
      {
        annualNOxLimit: nitrogenInputPaneState.annualNOxLimit,
        annualNH3Limit: nitrogenInputPaneState.annualNH3Limit,
        annualCO2Limit: nitrogenInputPaneState.annualCO2Limit,
        workingDays: workingDays,
        planningActivities: planningActivities,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );

    const scenarios = response.data.map((s, index) => ({
      ...s,
      id: index + 1,
      typeObject: TypeObject.NitrogenAnalyser,
      color: "#FF0000",
      renderType: "chart" as "chart" | "image" | "other",
      fuelConsumptionInL: Number.parseInt(s.fuelConsumptionInL.toFixed()),
      periods: s.periods.map((p) => ({
        ...p,
        name: p.name.replace("Period", i18next.t("Period")),
      })),
    }));

    currentState?.setObjectsTotalCount(scenarios.length);
    return scenarios;
  } catch (ex) {
    console.error("Fout bij het ophalen van stikstofscenario's:", ex);
    TrackException(ex);
    return [];
  }
}

export async function GetScenario(id: number): Promise<ScenarioDto> {
  return {} as ScenarioDto;
}

export async function ExportScenario(scenario: ScenarioDto): Promise<void> {
  try {
    var projectName = useProjectsStore.getState().currentProject?.name;
    if (IsStringUndefinedOrNullOrEmpty(projectName)) {
      projectName = "(unknown)";
    }

    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/export/scenario?projectName=${projectName}`,
      scenario,
      {
        responseType: "arraybuffer",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Type": "application/json",
        },
      }
    );

    await downloadFile(response, "scenario", "excel");
  } catch (ex) {
    TrackException(ex);
  }
}

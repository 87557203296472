import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import React, { forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { SpaceDto } from "../../../../HSA/types/api";
import BoxComponent from "./BoxComponent";

interface ThreeBoxComponentProps {
  currentSpace: SpaceDto;
  buildingCenter: { x: number; y: number; z: number };
}

const ThreeBoxComponent = forwardRef((props: ThreeBoxComponentProps, ref) => {
  const gl = useThree((state) => state.gl);

  useImperativeHandle(ref, () => ({
    downloadImage: () => {
      return gl.domElement.toDataURL("image/png");
    },
  }));

  return (
    <group rotation={[0, THREE.MathUtils.degToRad(270), 0]}>
      <OrbitControls
        enableDamping
        dampingFactor={0.1}
        rotateSpeed={0.5}
        target={new THREE.Vector3(0, 0, 0)}
        mouseButtons={{
          RIGHT: THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.PAN,
          LEFT: THREE.MOUSE.PAN,
        }}
      />
      <ambientLight intensity={0.5} />
      <directionalLight position={[-10, 10, -5]} intensity={1} />
      {props.currentSpace?.layout?.floorPlans?.map((floorPlan, idx) => (
        <React.Fragment key={idx}>
          {floorPlan.elements.map((element, elemIdx) => (
            <BoxComponent
              key={elemIdx}
              element={element}
              buildingCenter={props.buildingCenter}
            />
          ))}
        </React.Fragment>
      ))}
    </group>
  );
});

export default ThreeBoxComponent;

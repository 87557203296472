import { GetModule } from "../../../HSA/services/ModuleService";
import { GetSpaceBuilding } from "../../../HSA/services/BuildingService";
import { TrackException } from "../../logging/LoggingManager";
import { DTO } from "../../state/ExploreState/ExploreState";
import { TypeObject } from "../../types/enums";

export const typeObjectFetchMap: {
  [key in TypeObject]: (code: string) => Promise<any>;
} = {
  [TypeObject.HSAResidential]: GetModule,
  [TypeObject.HSABuilding]: GetSpaceBuilding,
  [TypeObject.MapView]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.ExportView]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  [TypeObject.HSACountingModel]: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
};

export async function getCurrentObjectDetails<T extends DTO>(currentObject: T) {
  try {
    const object = currentObject as { code: string; typeObject: TypeObject };
    const fetchFunction = typeObjectFetchMap[object.typeObject];
    if (fetchFunction) {
      var details = await fetchFunction(object.code);
      if (!details.code) {
        details = currentObject;
      }
      return details;
    } else {
      throw new Error("Unsupported typeObject");
    }
  } catch (ex) {
    TrackException(ex);
  }
}

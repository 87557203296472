import { SpaceBuildingDto } from "../../HSA/types/api";
import { SpaceType } from "../../HSA/types/enums";
import useUserStore from "../state/userState";
import DrawedPlot from "./DrawedPlot";

export default interface ProjectDto {
  externalId: string | undefined;
  name: string;
  projectAddress: string | undefined;
  companyName: string;
  createdBy: string;
  configuration: string; //JSON format --> Inputpane with marker/plot etc + selected buildings
  lastModifiedAt: string;
  projectType: string;
  planning: PlanningDto | undefined;
}

export interface CarParkingDto {
  parkingSpotPerResidentialFactor: number;
  covered: boolean;
  type: string;
  nrOfParkingSpots: number;
  parkingLotInMm2: number;
}

export interface ProjectForgeDto extends ProjectDto {
  accessTypes: string[];
  plotAreaInMm2: number | undefined;
  maxBuildingHeightInMm: number;
  maxBuildingGutterHeightInMm: number;
  residentialRange: {
    min: number;
    max: number;
  };
  residentialInformation: ResidentialInformation[];
  selectedModuleCodes: string[];
  buildingCodes: string[];
  parking: CarParkingDto;
}

export interface ProjectFoundryDto {
  id: number;
  name: string;
  plot: string;
  type_of: string;
  params: string;
  buildings: string;
  imgUrl: string;
  created_by: string;
}

export function GetEmptyProjectDto(): ProjectFoundryDto {
  return {
    id: 0,
    name: "",
    created_by: useUserStore.getState().emailAddress,
  } as ProjectFoundryDto;
}

export interface DimensionsDto {
  xSizeInMm: number | undefined;
  ySizeInMm: number | undefined;
  zSizeInMm: number | undefined;
  xSizeInM: number | undefined;
  ySizeInM: number | undefined;
  zSizeInM: number | undefined;
}

export interface PlanningDto {
  id: number;
  planningAsJson: string;
  createdBy: string;
  createdAt: string;
}

export interface ConfigurationDto {
  drawedPlot: DrawedPlot;
  currentBuildings: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  >;
  currentApartmentBuildings: SpaceBuildingDto[];
  selectedExportedBuilingsIds: { code: string; count: number }[];
  carParkingDto: CarParkingDto;
}

export interface ResidentialInformation{
  moduleType: SpaceType;
  ratio: number;
  minUsableAreaInMm2: number;
  maxUsableAreaInMm2: number;
  count: number;
}
import { ProjectTypeEnum } from "../../../interfaces/enums/ProjectTypeEnum";
import { TrackException } from "../../../logging/LoggingManager";
import ProjectDto, { ProjectForgeDto } from "../types/api";
import { ferroApiClient, forgeApiClient } from "./AxiosClient";

const companyName = "Heijmans";
const apiUri = "api/v1/projects";

export async function GetProject(externalId: string): Promise<ProjectDto> {
  try {
    const response = await ferroApiClient.get<ProjectDto>(
      `${apiUri}/${externalId}`
    );
    return response.data;
  } catch (error) {
    TrackException(error);
  }
  return {} as ProjectDto;
}

export async function GetProjectDetails<T extends ProjectDto = ProjectDto>(
  project: ProjectDto
): Promise<T> {
  try {
    if (project.projectType === ProjectTypeEnum[ProjectTypeEnum.HSA]) {
      return (await forgeApiClient.get<T>(`${apiUri}/${project.externalId}`))
        .data as T;
    }
  } catch (error) {
    TrackException(error);
  }

  return project as T;
}

export async function GetProjects<T extends ProjectDto = ProjectDto>(
  type: ProjectTypeEnum
): Promise<T[]> {
  try {
    const response = await ferroApiClient.get<T[]>(
      `${apiUri}?projectType=${ProjectTypeEnum[type]}`
    );
    return response.data;
  } catch (error) {
    TrackException(error);
    return [] as T[];
  }
}

export async function SaveProject(
  type: ProjectTypeEnum,
  dto: ProjectDto
): Promise<string> {
  dto.projectType = ProjectTypeEnum[type];
  dto.companyName = companyName;
  const response = await ferroApiClient.post<string>(apiUri, dto);
  if (response.status === 201) {
    if (type === ProjectTypeEnum.HSA) {
      await forgeApiClient.post<string>(`${apiUri}/${response.data}`, dto);
    }
  }

  return response.data;
}

export async function UpdateProject(
  type: ProjectTypeEnum,
  projectData: ProjectDto
): Promise<ProjectDto> {
  const response = await ferroApiClient.put<ProjectDto>(
    `${apiUri}/${projectData.externalId}`,
    projectData
  );

  if (response.status === 200) {
    if (type === ProjectTypeEnum.HSA) {
      const projectForgeData = projectData as ProjectForgeDto;
      await forgeApiClient.put<ProjectDto>(
        `${apiUri}/${projectData.externalId}`,
        projectForgeData
      );
    }
  }

  return response.data;
}

export async function RenameProject(projectData: ProjectDto) {
  await ferroApiClient.put<ProjectDto>(
    `${apiUri}/${projectData.externalId}`,
    projectData
  );
}

export async function DeleteProject(
  type: ProjectTypeEnum,
  project: ProjectDto
): Promise<number> {
  const response = await ferroApiClient.delete<number>(
    `${apiUri}/${project.externalId}`
  );

  if (response.status === 204) {
    if (type === ProjectTypeEnum.HSA) {
      await forgeApiClient.delete<number>(`${apiUri}/${project.externalId}`);
    }
  }

  return response.data;
}

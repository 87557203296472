import React from "react";
import { useTranslation } from "react-i18next";
import MetricsSection from "../../../../sharedLogic/components/MetricsSection/MetricsSection";
import { SpaceBuildingDto } from "../../../types/api";

interface CalculationModelProps {
  currentApartmentBuildings: SpaceBuildingDto[];
  selectedExportedBuilingsIds: {
    code: string;
    count: number;
  }[];
}

const CalculationModel: React.FC<CalculationModelProps> = ({
  currentApartmentBuildings,
  selectedExportedBuilingsIds,
}) => {
  const { t } = useTranslation();
  const repeatedApartmentBuildings = currentApartmentBuildings.reduce(
    (acc, building) => {
      const exportedBuilding = selectedExportedBuilingsIds.find(
        (exported) => exported.code === building.code
      );
      if (exportedBuilding) {
        return [...acc, ...Array(exportedBuilding.count).fill(building)];
      }
      return acc;
    },
    [] as SpaceBuildingDto[]
  );

  const buildingCosts = {
    title: t("StackedHousing.CalculationModel.DirectConstructionCosts"),
    subtitle: t("StackedHousing.CalculationModel.CostsOfBuildings"),
    items: currentApartmentBuildings
      .sort((a, b) => t(a.name).localeCompare(t(b.name)))
      .map((building) => ({
        name: `${t("Building")} ${building.name}`,
        quantity:
          selectedExportedBuilingsIds.find((x) => x.code === building.code)
            ?.count || 0,
        amount:
          (selectedExportedBuilingsIds.find((x) => x.code === building.code)
            ?.count || 0) * building.costInEuro,
        unit: "€",
      })),
    total: repeatedApartmentBuildings.reduce(
      (acc, building) => acc + building.costInEuro,
      0
    ),
  };

  const totalNumbers = {
    items: [
      {
        name: t("StackedHousing.CalculationModel.TotalBVOLiving"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.grossFloorAreaLivingInM2,
          0
        ),
        unit: "m²",
      },
      {
        name: t("StackedHousing.CalculationModel.TotalGOLiving"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.usableAreaInM2,
          0
        ),
        unit: "m²",
      },
      {
        name: t("StackedHousing.CalculationModel.TotalDirectCostsBVO"),
        amount: (() => {
          const totalCost = repeatedApartmentBuildings.reduce(
            (acc, building) => acc + building.costInEuro,
            0
          );
          const totalArea = repeatedApartmentBuildings.reduce(
            (acc, building) => acc + building.grossFloorAreaLivingInM2,
            0
          );
          return totalArea === 0 ? 0 : totalCost / totalArea;
        })(),
        unit: "€/m²",
      },
      {
        name: t("StackedHousing.CalculationModel.TotalDirectCostsGO"),
        amount: (() => {
          const totalCost = repeatedApartmentBuildings.reduce(
            (acc, building) => acc + building.costInEuro,
            0
          );
          const totalArea = repeatedApartmentBuildings.reduce(
            (acc, building) => acc + building.usableAreaInM2,
            0
          );
          return totalArea === 0 ? 0 : totalCost / totalArea;
        })(),
        unit: "€/m²",
      },
    ],
  };

  const totalCosts = {
    title: t("Total"),
    items: [
      {
        name: t("StackedHousing.CalculationModel.TotalBudget"),
        amount: buildingCosts.total,
        unit: "€",
      },
      {
        name: t("StackedHousing.NumberOfAppartments"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.nrOfAppartments,
          0
        ),
        unit: "st",
      },
      {
        name: t("StackedHousing.BVOHousing"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.grossFloorAreaLivingInM2,
          0
        ),
        unit: "m²",
      },
      {
        name: t("StackedHousing.BVOOutdoorSpace"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.grossFloorAreaOutdoorInM2,
          0
        ),
        unit: "m²",
      },
      {
        name: t("StackedHousing.GOHousing"),
        amount: repeatedApartmentBuildings.reduce(
          (acc, building) => acc + building.usableAreaInM2,
          0
        ),
        unit: "m²",
      },
    ],
    total: buildingCosts.total,
  };

  return (
    <div>
      <MetricsSection
        title={buildingCosts.title}
        subtitle={buildingCosts.subtitle}
        items={buildingCosts.items}
        total={buildingCosts.total}
        intentItems={true}
      />
      <MetricsSection items={totalNumbers.items} />
      <hr />
      <MetricsSection title={totalCosts.title} items={totalCosts.items} />
    </div>
  );
};

export default CalculationModel;

import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonDefinition } from "../../types/ButtonDefinition";
import "./DataGridContainer.scss";

interface DataGridContainerProps<T> {
  title: string;
  subtitle?: string;
  buttons?: ButtonDefinition[];
  GridComponent: React.ComponentType<any>;
}

export function DataGridContainer<T>({
  title,
  subtitle,
  buttons,
  GridComponent,
}: DataGridContainerProps<T>) {
  const { t } = useTranslation();

  return (
    <div className="data-grid-container">
      <div className="header-view">
        <div className="title-view">
          <div className="title">{t(title)}</div>
          <div className="subtitle">{t(subtitle ?? "")}</div>
        </div>
        <div className="buttons-view">
          {buttons &&
            buttons.map((button, index) => (
              <div key={index}>
                {button.customComponent ? (
                  button.customComponent
                ) : (
                  <Button
                    onClick={button.onClick}
                    style={{ width: "auto", height: "35px" }}
                    className="action-button"
                    type="submit"
                    variant="text"
                  >
                    {t(button.label)}
                  </Button>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="grid-view">
        <GridComponent />
      </div>
    </div>
  );
}

import { useMemo } from "react";
import { ExploreConfigStore } from "../../../../sharedLogic/types/ExploreConfig";
import InfoField from "../../../../sharedLogic/types/InfoField";
import { createExploreStore } from "../../../../sharedLogic/state/ExploreState/ExploreState";
import { SpaceBuildingDto } from "../../../types/api";
import ExportedBuildingsControl from "../../ExportedGeneratorViewControl/ExportedBuildingsControl";

export const useExportedBuildingsViewConfigStore = (
  currentApartmentBuildings: SpaceBuildingDto[],
  selectedExportedBuilingsIds: { code: string; count: number }[],
  setCurrentApartmentBuildings: (value: SpaceBuildingDto[]) => void,
  setSelectedExportedBuildingsIds: (
    values: { code: string; count: number }[]
  ) => void
): ExploreConfigStore<SpaceBuildingDto> => {
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );

  const summaryInfo: InfoField[] = [
    { label: "StackedHousing.AccessType", property: "accessType", unit: "" },
    { label: "Apartments", property: "nrOfAppartments", unit: "st" },
    { label: "absoluteValue", property: "costInEuro", unit: "€" },
  ];

  const inputPaneContent = () => (
    <>
      <ExportedBuildingsControl
        summaryInfo={summaryInfo}
        currentBuildings={currentApartmentBuildings}
        setCurrentBuildings={setCurrentApartmentBuildings}
        selectedExportedBuilingsIds={selectedExportedBuilingsIds}
        setSelectedExportedBuildingsIds={setSelectedExportedBuildingsIds}
      />
    </>
  );

  return {
    currentStore,
    inputPaneContent,
    summaryInfo,
  };
};

import { Link, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../sharedLogic/components/Dialog/CustomDialog";
import BuildableArea from "../../../sharedLogic/components/InputPane/MapView/BuildableArea";
import LabeledField from "../../../sharedLogic/components/PropertySectionView/LabeledField";
import NumberField from "../../../sharedLogic/components/PropertySectionView/NumberField";
import SceneDetail from "../../../sharedLogic/components/Scene/SceneDetail/SceneDetail";
import DrawedPlot from "../../../sharedLogic/types/DrawedPlot";
import { UnitType } from "../../../sharedLogic/types/UnitType";
import { HsaConfiguratorMapViewInputPaneState } from "../../state/hsaConfiguratorMapViewInputPaneState";
import { SpaceBuildingDto } from "../../types/api";
import { SpaceType } from "../../types/enums";
import { getColor } from "../../utils/colors";
import { BuildingDetailedInfo } from "../ConfiguratorPage/Configs/BuildingConfig";
import UnitMix from "../UnitMix/UnitMix";

interface ExportedBuildingDetailsPaneProps {
  drawedPlot: DrawedPlot;
  selectedExportedBuilingsIds: {
    code: string;
    count: number;
  }[];
  currentApartmentBuildings: SpaceBuildingDto[];
  mapViewInputPaneState: HsaConfiguratorMapViewInputPaneState;
}

const calculateTotal = (
  buildings: SpaceBuildingDto[],
  key: keyof SpaceBuildingDto
) => buildings.reduce((acc, building) => acc + (building[key] || 0), 0);

const calculateUnitMix = (
  buildings: SpaceBuildingDto[],
  totalApartments: number,
  type: SpaceType,
  ratioKey: keyof SpaceBuildingDto
) => {
  const count = buildings.reduce(
    (acc, building) =>
      acc + (building[ratioKey] / 100) * building.nrOfAppartments,
    0
  );
  return {
    name: type,
    color: getColor(type),
    count,
    percentage: Math.round((count / totalApartments) * 100),
  };
};

export default function ExportedBuildingDetailsPane({
  drawedPlot,
  currentApartmentBuildings,
  selectedExportedBuilingsIds,
  mapViewInputPaneState,
}: ExportedBuildingDetailsPaneProps) {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const totalParkingPlacesCar = useRef(0);
  const totalParkingPlacesSurfaceCar = useRef(0);

  const repeatedApartmentBuildings = currentApartmentBuildings.reduce(
    (acc, building) => {
      const exportedBuilding = selectedExportedBuilingsIds.find(
        (exported) => exported.code === building.code
      );
      if (exportedBuilding) {
        return [...acc, ...Array(exportedBuilding.count).fill(building)];
      }
      return acc;
    },
    [] as SpaceBuildingDto[]
  );

  const calculateUnitMix = (buildings: SpaceBuildingDto[]): UnitType[] => {
    const totalApartments = buildings.reduce((total, building) => {
      return total + building.nrOfAppartments;
    }, 0);

    const unitCounts = new Map<SpaceType, number>();
    buildings.forEach((building) => {
      building.residentialInformation.forEach((info) => {
        const currentCount = unitCounts.get(info.moduleType) || 0;
        unitCounts.set(info.moduleType, currentCount + info.count);
      });
    });

    return Array.from(unitCounts.entries()).map(
      ([spaceType, count]) =>
        ({
          name: spaceType,
          color: getColor(spaceType),
          count,
          ratio: Math.round((count / totalApartments) * 100),
        } as UnitType)
    );
  };

  const totalBuildingSurface = calculateTotal(
    repeatedApartmentBuildings,
    "grossFootprintAreaInM2"
  );
  const totalBuildingCosts = calculateTotal(
    repeatedApartmentBuildings,
    "costInEuro"
  );
  const totalApartments = calculateTotal(
    repeatedApartmentBuildings,
    "nrOfAppartments"
  );
  const bvoTotal = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaInM2"
  );
  const bvoLiving = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaLivingInM2"
  );
  const bvoOutdoor = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaOutdoorInM2"
  );
  const go = calculateTotal(repeatedApartmentBuildings, "usableAreaInM2");
  const bbo = calculateTotal(
    repeatedApartmentBuildings,
    "grossFootprintAreaInM2"
  );
  const bgo = calculateTotal(repeatedApartmentBuildings, "usableAreaInM2");
  const bdo = calculateTotal(repeatedApartmentBuildings, "grossRoofAreaInM2");

  const units = calculateUnitMix(repeatedApartmentBuildings);

  const onClick = (building: SpaceBuildingDto) => {
    openDialog({
      title: `${building.name} details`,
      content: (
        <SceneDetail
          currentObject={building}
          detailedInfo={BuildingDetailedInfo}
        />
      ),
      width: "75%",
      height: "85%",
    });
  };

  useEffect(() => {
    const carPerHouseNumber =
      parseFloat(
        String(
          mapViewInputPaneState.carParking.parkingSpotPerResidentialFactor
        ).replace(",", ".")
      ) || 0;
    totalParkingPlacesCar.current = Math.ceil(
      currentApartmentBuildings.reduce((acc, building) => {
        const exportedBuilding = selectedExportedBuilingsIds.find(
          (exported) => exported.code === building.code
        );
        if (exportedBuilding) {
          return (
            acc +
            exportedBuilding.count *
              building.nrOfAppartments *
              carPerHouseNumber
          );
        }
        return acc;
      }, 0)
    );
    const parkingEntraceSurfaceM2 = 6.3 * 6.75;
    totalParkingPlacesSurfaceCar.current =
      currentApartmentBuildings.reduce((acc, building) => {
        const exportedBuilding = selectedExportedBuilingsIds.find(
          (exported) => exported.code === building.code
        );
        if (exportedBuilding) {
          const sizeInMm2 = getParkingSpotSize(
            mapViewInputPaneState.carParking.type,
            mapViewInputPaneState.carParking.covered,
            exportedBuilding.count *
              building.nrOfAppartments *
              carPerHouseNumber
          );
          const sizeInM2 = sizeInMm2 ? sizeInMm2 / 1_000_000 : 0;
          return (
            acc +
            sizeInM2 *
              Math.ceil(
                exportedBuilding.count *
                  building.nrOfAppartments *
                  carPerHouseNumber
              )
          );
        }
        return acc;
      }, 0) + parkingEntraceSurfaceM2;
  }, [selectedExportedBuilingsIds, mapViewInputPaneState]);

  interface ParkingSpotSize {
    size50SpotsInMm2: number | null;
    size80SpotsInMm2: number | null;
    size200SpotsInMm2: number | null;
    size400SpotsInMm2: number | null;
  }

  function getParkingSpotSize(
    type: string,
    covered: boolean,
    nrOfParkingSpots: number
  ): number | null {
    let parkingSpotSize: ParkingSpotSize;

    switch (type) {
      case "GarageParking":
        parkingSpotSize = covered
          ? getParkingSpotSizeGarageParkingCovered()
          : getParkingSpotSizeGarageParkingUncovered();
        break;
      case "NonPublicParking":
        parkingSpotSize = covered
          ? getParkingSpotSizeNonPublicParkingCovered()
          : getParkingSpotSizeNonPublicParkingUncovered();
        break;
      default:
        parkingSpotSize = {
          size50SpotsInMm2: null,
          size80SpotsInMm2: null,
          size200SpotsInMm2: null,
          size400SpotsInMm2: null,
        };
    }

    return calculateParkingSpotSize(nrOfParkingSpots, parkingSpotSize);
  }

  function calculateParkingSpotSize(
    nrOfParkingSpots: number,
    parkingSpaceSize: ParkingSpotSize
  ): number | null {
    if (nrOfParkingSpots <= 50) {
      return parkingSpaceSize.size50SpotsInMm2;
    } else if (nrOfParkingSpots > 50 && nrOfParkingSpots < 80) {
      return (
        parkingSpaceSize.size80SpotsInMm2! +
        ((parkingSpaceSize.size50SpotsInMm2! -
          parkingSpaceSize.size80SpotsInMm2!) *
          (nrOfParkingSpots - 50)) /
          30
      );
    } else if (nrOfParkingSpots === 80) {
      return parkingSpaceSize.size80SpotsInMm2;
    } else {
      return calculateParkingSpotSizeLarge(nrOfParkingSpots, parkingSpaceSize);
    }
  }

  function calculateParkingSpotSizeLarge(
    nrOfParkingSpots: number,
    parkingSpaceSize: ParkingSpotSize
  ): number | null {
    if (
      parkingSpaceSize.size200SpotsInMm2 !== null &&
      parkingSpaceSize.size400SpotsInMm2 !== null
    ) {
      if (nrOfParkingSpots > 80 && nrOfParkingSpots < 200) {
        return (
          parkingSpaceSize.size200SpotsInMm2! +
          ((parkingSpaceSize.size80SpotsInMm2! -
            parkingSpaceSize.size200SpotsInMm2!) *
            (nrOfParkingSpots - 80)) /
            120
        );
      } else if (nrOfParkingSpots === 200) {
        return parkingSpaceSize.size200SpotsInMm2;
      } else if (nrOfParkingSpots > 200 && nrOfParkingSpots < 400) {
        return (
          parkingSpaceSize.size400SpotsInMm2! +
          ((parkingSpaceSize.size200SpotsInMm2! -
            parkingSpaceSize.size400SpotsInMm2!) *
            (nrOfParkingSpots - 200)) /
            200
        );
      } else if (nrOfParkingSpots === 400) {
        return parkingSpaceSize.size400SpotsInMm2;
      }
    }

    return null;
  }

  function getParkingSpotSizeGarageParkingUncovered(): ParkingSpotSize {
    return {
      size50SpotsInMm2: 28900000,
      size80SpotsInMm2: 25800000,
      size200SpotsInMm2: null,
      size400SpotsInMm2: null,
    };
  }

  function getParkingSpotSizeGarageParkingCovered(): ParkingSpotSize {
    return {
      size50SpotsInMm2: 29100000,
      size80SpotsInMm2: 26700000,
      size200SpotsInMm2: null,
      size400SpotsInMm2: null,
    };
  }

  function getParkingSpotSizeNonPublicParkingUncovered(): ParkingSpotSize {
    return {
      size50SpotsInMm2: 29300000,
      size80SpotsInMm2: 26700000,
      size200SpotsInMm2: 23200000,
      size400SpotsInMm2: 22100000,
    };
  }

  function getParkingSpotSizeNonPublicParkingCovered(): ParkingSpotSize {
    return {
      size50SpotsInMm2: 30200000,
      size80SpotsInMm2: 28100000,
      size200SpotsInMm2: 24700000,
      size400SpotsInMm2: 23000000,
    };
  }

  return (
    <>
      <div className="titel">{t("Total")}</div>
      <div className="titel-no-bold">{t("MapViewPage.Lot")}</div>
      <LabeledField
        labelId="buildable-plot-label"
        labelText={t("MapViewPage.BuildablePlot")}
        tooltipText={t("MapViewPage.BuildablePlotTooltip")}
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={drawedPlot.lotSurface}
        />
      </LabeledField>
      <BuildableArea
        disabled={true}
        percentage={Math.round(
          (drawedPlot.buildableSurface / drawedPlot.lotSurface) * 100
        )}
        buildableSurface={Math.round(drawedPlot.buildableSurface)}
      />

      <LabeledField
        className="mt5"
        labelId="buildings-surface-label"
        labelText={t("StackedHousing.ExportView.RequiredBuildingSurfaceArea")}
        tooltipText={t(
          "StackedHousing.ExportView.RequiredBuildingSurfaceAreaTooltip"
        )}
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalBuildingSurface}
        />
      </LabeledField>

      <LabeledField
        className="mt5"
        labelId="building-parking-label"
        labelText={t("StackedHousing.ExportView.RequiredParkingArea")}
        tooltipText={t("StackedHousing.ExportView.RequiredParkingAreaTooltip")}
      >
        <NumberField unit={"m²"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="building-area-label"
        labelText={t("StackedHousing.ExportView.BuiltUpArea")}
        tooltipText={t("StackedHousing.ExportView.BuiltUpAreaTooltip")}
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalBuildingSurface + 0}
        />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {t("StackedHousing.ExportView.Parking")}
      </div>
      <LabeledField
        labelId="parking-count-label"
        labelText={t("StackedHousing.ExportView.ParkingplacesCar")}
      >
        <NumberField
          unit={"st"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalParkingPlacesCar.current}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="parking-surface-label"
        labelText={t("StackedHousing.ExportView.SurfaceParkingplacesCar")}
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalParkingPlacesSurfaceCar.current}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="parking-places-bike-label"
        labelText={t("StackedHousing.ExportView.ParkingPlacesBike")}
      >
        <NumberField unit={"st"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="parking-surface-bike-lot-label"
        labelText={t("StackedHousing.ExportView.SurfaceParkingPlacesBikeLot")}
        tooltipText={t(
          "StackedHousing.ExportView.SurfaceParkingPlacesBikeLotTooltip"
        )}
      >
        <NumberField unit={"m²"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="parking-surface-bike-building-label"
        labelText={t(
          "StackedHousing.ExportView.SurfaceParkingPlacesBikeBuilding"
        )}
        tooltipText={t(
          "StackedHousing.ExportView.SurfaceParkingPlacesBikeBuildingTooltip"
        )}
      >
        <NumberField unit={"m²"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">{t("Valuation")}</div>
      <LabeledField
        labelId="indication-construction-budget-label"
        labelText={t(
          "StackedHousing.ExportView.IndicationOfConstructionBudget"
        )}
        tooltipText={t(
          "StackedHousing.ExportView.IndicationOfConstructionBudgetTooltip"
        )}
      >
        <NumberField
          unit={"€"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalBuildingCosts}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="indication-yields-label"
        labelText={t("StackedHousing.ExportView.IndicationOfInvestmentValue")}
        tooltipText={t(
          "StackedHousing.ExportView.IndicationOfInvestmentValueTooltip"
        )}
      >
        <NumberField unit={"€"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="rental-price-label"
        labelText={t("StackedHousing.ExportView.RentalPrice")}
        tooltipText={t("StackedHousing.ExportView.RentalPriceTooltip")}
      >
        <NumberField unit={"€"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="co2-footprint-label"
        labelText={t("StackedHousing.ExportView.CO2Footprint")}
        tooltipText={t("StackedHousing.ExportView.CO2FootprintTooltip")}
      >
        <NumberField unit={"kg"} disabled={true} showBorderBottomStyle={true} />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">{t("Buildings")}</div>
      <LabeledField
        labelId="total-quantity-apartments-label"
        labelText={t("StackedHousing.ExportView.TotalQuantityApartments")}
        tooltipText={t(
          "StackedHousing.ExportView.TotalQuantityApartmentsTooltip"
        )}
      >
        <NumberField
          unit={"st"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalApartments}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="unit-mix-label"
        labelText={"Unit mix"}
      >
        <div className="mt5">
          <UnitMix units={units} editPercentage={false} showSlider={false} />
        </div>
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bvo-label"
        labelText={t("BVOTotal")}
        tooltipText={t("BVOTotalTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bvoTotal}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bvo-living-label"
        labelText={t("BVOHousing")}
        tooltipText={t("BVOHousingTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bvoLiving}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bvo-outdoor-label"
        labelText={t("BVOOutdoor")}
        tooltipText={t("BVOOutdoorTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bvoOutdoor}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="go-label"
        labelText={t("GOHousing")}
        tooltipText={t("GOHousingTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={go}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bbo-label"
        labelText={t("StackedHousing.ExportView.BBO")}
        tooltipText={t("StackedHousing.ExportView.BBOTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bbo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bgo-label"
        labelText={t("StackedHousing.ExportView.BGO")}
        tooltipText={t("StackedHousing.ExportView.BGOTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bgo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="bdo-label"
        labelText={t("StackedHousing.ExportView.BDO")}
        tooltipText={t("StackedHousing.ExportView.BDOTooltip")}
      >
        <NumberField
          unit={"m²"}
          value={bdo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledField>

      <div className="mt10">
        {currentApartmentBuildings.map((building) => {
          return (
            <Typography component="div">
              <Link
                onClick={() => onClick(building)}
                component="button"
                sx={{
                  cursor: "pointer",
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginTop: "5px",
                }}
              >
                {`${t("Building")} ${building.name}`}
              </Link>
            </Typography>
          );
        })}
      </div>
    </>
  );
}

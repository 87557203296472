import { CheckCircleOutline, Clear } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadIcon } from "../../../../icons/UploadIcon";
import "./FileUpload.scss";

interface FileUploadProps {
  width?: string;
  height?: string;
  isEditing: boolean;
  multiselect?: boolean;
  acceptFiles?: string;
  showLoadedFiles?: boolean;
  onFilesSelected: (files: File[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  width = "100%",
  height = "100%",
  multiselect = true,
  isEditing = true,
  acceptFiles = ".pdf",
  showLoadedFiles = false,
  onFilesSelected,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (isEditing && selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (isEditing && droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const acceptExtensions = acceptFiles.split(",").map((ext) => ext.trim());
      setFiles((prevFiles) => [
        ...prevFiles,
        ...newFiles.filter((file) =>
          acceptExtensions.includes(file.name.slice(file.name.lastIndexOf(".")))
        ),
      ]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  const handleClick = () => {
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <section
      className="drag-drop"
      data-editing={isEditing}
      style={{ width: width, height: height }}
      onClick={handleClick}
    >
      <div
        className={`document-uploader ${
          files.length > 0 ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <div className="upload-info-text">
              <p>{t("DragFile")}</p>
              <p>{t("Or").toLowerCase()}</p>
              <p>{t("SearchFile")}</p>
              <input
                id="file"
                type="file"
                title=""
                disabled={!isEditing}
                placeholder="placeholder"
                multiple={multiselect}
                accept={acceptFiles}
                onChange={handleFileChange}
              />
            </div>
            <div className="upload-icon">
              <UploadIcon size={70}  />
            </div>
          </div>
        </>

        {files.length > 0 && showLoadedFiles && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                  </div>
                  <div className="file-actions">
                    <Clear onClick={() => handleRemoveFile(index)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && showLoadedFiles && (
          <div className="success-file">
            <CheckCircleOutline style={{ color: "#6DC24B", marginRight: 1 }} />
            <p>{files.length} file(s) selected</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default FileUpload;

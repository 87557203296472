import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Fade,
} from "@mui/material";
import React, { ReactNode, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../../components/CancelButton/CancelButton";

export interface OpenDialogOptions {
  title: string;
  content: ReactNode;
  actionButtons?: ReactNode[];
  dialogCustomHeader?: ReactNode;
  width?: string;
  height?: string;
  save?: () => boolean;
}

interface DialogContextType {
  openDialog: (dialogOptions: OpenDialogOptions) => string;
  closeDialog: (id?: string | undefined) => void;
}

export interface DialogQueueItem extends OpenDialogOptions {
  id: string;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);
interface CustomDialogProps extends Omit<DialogProps, "open"> {
  children: ReactNode;
}

export const DialogProvider: React.FC<CustomDialogProps> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const [dialogs, setDialogs] = useState<OpenDialogOptions[]>([]);
  const [dialogOptions, setDialogOptions] = useState<OpenDialogOptions>(
    {} as OpenDialogOptions
  );
  const [dialogSize, setDialogSize] = useState({
    width: "auto",
    height: "auto",
  });
  const [dialogQueue, setDialogQueue] = useState<DialogQueueItem[]>([]);

  const openDialog = (options: OpenDialogOptions) => {
    const dialogId = `dialog-${Date.now()}`;
    setDialogQueue((prev) => [...prev, { ...options, id: dialogId }]);
    return dialogId;
  };

  const closeDialog = (id?: string) => {
    if (id) {
      setDialogQueue((prev) => prev.filter((dialog) => dialog.id !== id));
    } else {
      setDialogQueue((prev) => prev.slice(0, -1));
    }
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      {dialogQueue.map((dialogOptions, index) => (
        <Dialog
          key={dialogOptions.id}
          {...props}
          open={true}
          onClose={() => closeDialog(dialogOptions.id)}
          maxWidth="xl"
          keepMounted
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: 300,
          }}
          PaperProps={{
            id: "dialog-paper",
            style: {
              borderRadius: 20,
              width: dialogOptions?.width ?? "auto",
              height: dialogOptions?.height ?? "auto",
              minWidth:
                dialogSize.width !== "auto" ? dialogSize.width : undefined,
              minHeight:
                dialogSize.height !== "auto" ? dialogSize.height : undefined,
            },
          }}
        >
          {dialogOptions.dialogCustomHeader ? (
            dialogOptions.dialogCustomHeader
          ) : (
            <DialogTitle
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 18,
                fontWeight: 900,
                color: "rgba(0, 52, 102, 1)",
              }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {t(dialogOptions.title)}
              <Box component={"div"} sx={{ ml: "auto", display: "flex" }}>
                <CancelButton onClick={() => closeDialog(dialogOptions.id)} />
              </Box>
            </DialogTitle>
          )}
          <Divider sx={{ marginTop: "-10px" }} />
          <DialogContent sx={{ overflow: "hidden", padding: "0px !important" }}>
            {dialogOptions.content}
          </DialogContent>
          {dialogOptions.actionButtons && (
            <DialogActions>{dialogOptions.actionButtons}</DialogActions>
          )}
        </Dialog>
      ))}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

import { Cancel, CancelOutlined } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { SpringValue, animated } from "react-spring";
import LinearProgressWithLabel from "../../../../components/LinearProgressWithLabel";
import { OpenIcon } from "../../../../icons/OpenIcon";
import { TypeObject, TypeObjectLabel } from "../../types/enums";
import SummaryInfoDto from "../../types/InfoField";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import PlusMinusButton from "./PlusMinusButton";
import SummaryView from "./SummaryView";

interface RenderHelperProps<T extends DTO> {
  currentPageType?: TypeObject | null;
  currentObject?: T;
  isCandidate?: boolean;
  isInOriginalList?: boolean;
  isHovered?: boolean;
  customTitle?: string;
  setIsHovered?: Dispatch<SetStateAction<boolean>>;
  handleSelectingDatacenterComponent?: () => void;
  removeFromCandidateList?: () => void;
  checkboxAnimationStyle?: {
    backgroundColor: SpringValue<string>;
    borderColor: SpringValue<string>;
  };
  checkmarkLength?: number;
  checkmarkAnimationStyle?: {
    x: SpringValue<number>;
  };
  t?: TFunction<"translation", undefined>;
  summaryInfo?: SummaryInfoDto[];
  color?: string;
  currentStore?: ExploreState<T> & ExploreActions<T>;
  isExportView?: boolean;
  selectedExportedBuilingsIds?: { code: string; count: number }[];
  setSelectedExportedBuildingsIds?: (
    values: { code: string; count: number }[]
  ) => void;
}

export function renderTitle<T extends DTO>({
  currentObject,
  currentPageType,
  t,
  customTitle,
  isInOriginalList,
}: RenderHelperProps<T>) {
  if (!currentObject || !t) return null;

  const typeLabel =
    TypeObjectLabel.get(
      (currentObject as T & { typeObject: TypeObject })?.typeObject ?? 0
    ) ?? "";

  let displayTitle = `${t(typeLabel)} ${
    customTitle !== undefined
      ? customTitle
      : (currentObject as T & { id: number })?.id
  }`;

  if (currentPageType === TypeObject.HSAResidential) {
    const codeBusiness = (currentObject as T & { codeBusiness?: string })
      ?.codeBusiness;
    if (codeBusiness) {
      displayTitle = `${t(typeLabel)} - ${codeBusiness}`;
    }
  }

  return (
    <Grid
      className="scene-title"
      item
      xs={8}
      sx={{
        fontSize: !isInOriginalList ? "xx-small !important" : "",
      }}
    >
      {displayTitle}
    </Grid>
  );
}

export function renderSummaryViewSection<T extends DTO>({
  currentObject,
  summaryInfo,
}: RenderHelperProps<T>) {
  return currentObject ? (
    <SummaryView
      currentObject={currentObject}
      summaryInfo={summaryInfo || []}
    />
  ) : null;
}

export function renderCandidateSection<T extends DTO>(
  {
    isCandidate,
    isInOriginalList,
    handleSelectingDatacenterComponent,
    checkboxAnimationStyle,
    checkmarkLength,
    checkmarkAnimationStyle,
    t,
  }: RenderHelperProps<T>,
  setCheckmarkLength: Dispatch<SetStateAction<number>>
) {
  if ((isCandidate || !isCandidate) && isInOriginalList) {
    return (
      <Grid item xs={4} justifyContent="flex-end" sx={{ marginTop: "-5px" }}>
        <div className="container-next-each-other-center">
          {!isCandidate && (
            <Button
              className="button"
              variant="contained"
              color="secondary"
              onClick={handleSelectingDatacenterComponent}
            >
              {t && t("Select")}
            </Button>
          )}
          {isCandidate && (
            <label>
              <input
                type="checkbox"
                onChange={handleSelectingDatacenterComponent}
              />
              <animated.svg
                style={checkboxAnimationStyle}
                className={`checkbox ${isCandidate ? "checkbox--active" : ""}`}
                viewBox="0 0 15 11"
                fill="none"
              >
                <animated.path
                  d="M1 4.5L5 9L14 1"
                  strokeWidth=".7"
                  stroke="#fff"
                  transform="scale(0.6) translate(5, 4)"
                  ref={(ref) => {
                    if (ref) {
                      setCheckmarkLength(ref.getTotalLength());
                    }
                  }}
                  strokeDasharray={checkmarkLength}
                  strokeDashoffset={checkmarkAnimationStyle?.x}
                />
              </animated.svg>
            </label>
          )}
          <IconButton onClick={handleSelectingDatacenterComponent}>
            <OpenIcon />
          </IconButton>
        </div>
      </Grid>
    );
  }
  return null;
}

export function renderNotInOriginalListSection<T extends DTO>({
  isExportView,
  isInOriginalList,
  isHovered,
  setIsHovered,
  removeFromCandidateList,
}: RenderHelperProps<T>) {
  if (!isInOriginalList && !isExportView) {
    return (
      <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          style={{ height: "26px", width: "26px" }}
          onClick={removeFromCandidateList}
          onMouseEnter={() => {
            setIsHovered && setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered && setIsHovered(false)}
        >
          {isHovered ? (
            <Cancel
              style={{ fontSize: "1.5rem", fill: "rgba(0, 52, 102, 1)" }}
            />
          ) : (
            <CancelOutlined
              style={{ fontSize: "1.5rem", fill: "rgba(0, 52, 102, 1)" }}
            />
          )}
        </IconButton>
      </Grid>
    );
  }
  return null;
}

export function renderBestFitRateInfoCardSection<T extends DTO>({
  isExportView,
  currentObject,
  t,
}: RenderHelperProps<T>) {
  if (!isExportView) {
    return (
      <>
        <Grid item xs={4}>
          <div className="sub-titel">{t && t("BestFitRateInfoCard")}:</div>
        </Grid>
        <Grid item xs={8}>
          <LinearProgressWithLabel
            backgroundColor={(currentObject as { color: string })?.color}
            value={(currentObject as { value: number })?.value}
          />
        </Grid>
      </>
    );
  }
  return null;
}

export function renderExportButtonSection<T extends DTO>({
  isExportView,
  currentObject,
  selectedExportedBuilingsIds,
  setSelectedExportedBuildingsIds,
}: RenderHelperProps<T>) {
  if (isExportView) {
    return (
      <Grid
        xs={4}
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ padding: "5px 0px" }}
      >
        <PlusMinusButton
          object={currentObject}
          selectedExportedBuilingsIds={selectedExportedBuilingsIds}
          setSelectedExportedBuildingsIds={setSelectedExportedBuildingsIds}
        />
      </Grid>
    );
  }
  return null;
}

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import useLayoutStore from "../../../../state/layoutState";
import * as React from "react";

type NumberFieldProps = {
  value?: string | number | null | undefined;
  disabled?: boolean;
  error?: boolean;
  unit?: string;
  showBorderBottomStyle?: boolean;
  onChange?: (value: string | undefined) => void;
};

const NumberField = ({
  value,
  onChange,
  disabled = false,
  showBorderBottomStyle = false,
  error,
  unit,
}: NumberFieldProps) => {
  const { t } = useTranslation();
  const { selectedLang } = useLayoutStore();

  const handleChange = (newValue: string | undefined) => {
    onChange?.(newValue);
  };

  const CustomTextField = useMemo(
    () => (props: TextFieldProps) =>
      (
        <TextField
          {...props}
          error={error}
          variant="standard"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& .MuiInputBase-root.Mui-disabled:before": {
              borderBottomStyle: showBorderBottomStyle ? "solid" : "none",
            },
            marginTop: disabled ? "2px" : "",
          }}
        />
      ),
    []
  );

  const formatNumericValue = (
    value: string | number | null | undefined,
    disabled: boolean
  ) => {
    if (disabled && value != null) {
      const numValue = Number(value);
      if (!Number.isNaN(numValue) && value.toString().includes(".")) {
        return numValue.toFixed(2);
      }
    }
    return value;
  };

  return (
    <FormControl fullWidth variant="standard" error={error}>
      <NumericFormat
        disabled={disabled}
        value={formatNumericValue(value, disabled)}
        onValueChange={(values) => handleChange(values.value)}
        customInput={CustomTextField}
        InputProps={{
          endAdornment: unit ? (
            <InputAdornment sx={{ color: "black" }} position="end">
              {unit}
            </InputAdornment>
          ) : null,
        }}
        thousandSeparator={selectedLang === "nl-NL" ? "." : ","}
        decimalSeparator={selectedLang === "nl-NL" ? "," : "."}
      />
      {error && <FormHelperText>{t("Field is required")}</FormHelperText>}
    </FormControl>
  );
};

export default NumberField;

import { TrackException } from "../../sharedLogic/logging/LoggingManager";
import { forgeApiClient } from "../../sharedLogic/services/AxiosClient";
import { FilterOptions } from "../../sharedLogic/types/RelationshipDto";

const baseUrl = "/api/v1/relations";

export async function EditModuleRelation(relationship: any): Promise<any> {
  try {
    let updatedRelationship = {} as any;
    await forgeApiClient
      .put<any>(baseUrl, relationship)
      .then((res) => (updatedRelationship = res.data))
      .catch((ex) => {
        TrackException(ex);
      });

    return updatedRelationship;
  } catch (ex) {
    TrackException(ex);
    return {} as any;
  }
}

export async function GetModuleRelations(
  moduleFlag: string
): Promise<any | null> {
  try {
    let response = await forgeApiClient.get<any>(
      `${baseUrl}/by-flag/${moduleFlag}`
    );

    return response.data;
  } catch (ex) {
    TrackException(ex);
    return null;
  }
}

export async function GetFilterOptions(
  moduleFlag: string
): Promise<FilterOptions | null> {
  try {
    let response = await forgeApiClient.get(
      `${baseUrl}/by-flag/${moduleFlag}/filter-options`
    );

    return response.data;
  } catch (ex) {
    TrackException(ex);
    return null;
  }
}

export async function GetFilteredModuleRelations(
  moduleFlag: string,
  filterOptions: FilterOptions
) {
  try {
    const queryParams = new URLSearchParams();
    filterOptions.spaceTypes.forEach((spaceType) =>
      queryParams.append("SpaceTypes", spaceType)
    );
    filterOptions.buildingLayers.forEach((buildingLayer) =>
      queryParams.append("BuildingLayers", buildingLayer)
    );
    filterOptions.moduleTypes.forEach((moduleType) =>
      queryParams.append("ModuleTypes", moduleType)
    );
    const response = await forgeApiClient.get(
      `${baseUrl}/by-flag/${moduleFlag}/filtered?${queryParams.toString()}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    return null;
  }
}

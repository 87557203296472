import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ButtonDefinition } from "../../modules/sharedLogic/types/ButtonDefinition";

interface SplitButtonProps {
  disabled?: boolean;
  buttonText: string;
  definitions: ButtonDefinition[];
}

export default function SplitButton({
  disabled = false,
  buttonText,
  definitions,
}: SplitButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLButtonElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        disabled={disabled}
        onClick={handleToggle}
        endIcon={<ArrowDropDown />}
      >
        {t(buttonText)}
      </Button>
      <Popper
        sx={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {definitions.map((definition, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        definition.onClick();
                        handleToggle();
                      }}
                    >
                      {t(definition.label)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

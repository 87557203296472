import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Drawer, Toolbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Router from "./Router";
import { loginRequest } from "./authentication/authConfig";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { AzureApplicationInsightsConnectionString } from "./config";
import "./locales/i18n";
import withAppInsights from "./modules/sharedLogic/logging/AppInsightsService";
import { TrackException } from "./logging/LoggingManager";
import useUserStore from "./modules/sharedLogic/state/userState";
import { IsStringUndefinedOrNullOrEmpty } from "./modules/sharedLogic/utils/stringUtils";
import NotAuthorizedPage from "./pages/Error/NotAuthorizedPage";
import useLayoutStore from "./state/layoutState";
import useNotificationStore from "./state/notificationState";

interface AppProps {
  msalInstance: IPublicClientApplication;
}

function App({ msalInstance }: AppProps) {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { appAbility, setName, setEmailAddress, setRoles } = useUserStore();
  const { instance, accounts } = useMsal();
  const { isOpen, message, type, closeNotification } = useNotificationStore();
  const { isSidebarOpen, isSidebarPersistent } = useLayoutStore();
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    const account = msalInstance.getActiveAccount();
    if (account) {
      getUserInformation(account);
    } else if (!isAuthenticated && accounts.length === 0) {
      instance.loginRedirect(loginRequest).catch((e) => {
        TrackException(e);
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInformation(accounts[0]);
    }
  }, [isAuthenticated]);

  function NotAuthenticated() {
    if (!pathname.endsWith("/api/health")) {
      setTimeout(() => {
        handleLogin();
      }, 50);
    }
    return <div></div>;
  }

  function getUserInformation(account: AccountInfo) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then((result) => {
        setName(account?.name ?? "");
        setEmailAddress(account?.username ?? "");
        setRoles((result?.idTokenClaims as { roles?: string[] })?.roles);
      });
  }

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      if (event?.message?.includes("ResizeObserver")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }

        event.stopImmediatePropagation();
        event.preventDefault();
      }   
    };

    const rejectionHandler = (event: PromiseRejectionEvent) => {
      if (event.reason?.message?.includes("ResizeObserver")) {
        event.preventDefault();
      }
    };

    window.addEventListener("error", errorHandler, true);
    window.addEventListener("unhandledrejection", rejectionHandler, true);

    return () => {
      window.removeEventListener("error", errorHandler, true);
      window.removeEventListener("unhandledrejection", rejectionHandler, true);
    };
  }, []);

  return (
    <div className="root">
      <AuthenticatedTemplate>
        <Header />
        {appAbility?.can("read", "main") ? (
          <>
            <Drawer
              open={isSidebarOpen}
              PaperProps={{ elevation: 0 }}
              sx={{ width: "200px", overflow: "hidden" }}
              variant={isSidebarPersistent ? "persistent" : "temporary"}
              className={isSidebarOpen ? "drawerOpen" : "drawerClose"}
            >
              <Sidebar />
            </Drawer>
            <div
              className={
                isSidebarPersistent && isSidebarOpen
                  ? "sidebar-persistent"
                  : "sidebar-temporary"
              }
            >
              <Toolbar />
              <Router />
            </div>
            <Snackbar
              open={isOpen}
              onClose={() => closeNotification()}
              autoHideDuration={5000}
            >
              <Alert onClose={() => closeNotification()} severity={type}>
                {message}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <NotAuthorizedPage />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route
            key={"healthCheck"}
            path={"/api/health"}
            element={<h3>Hey There!! The App is Healthy</h3>}
          />
          <Route
            key={"notAuthenticated"}
            path={"*"}
            element={<NotAuthenticated />}
          />
        </Routes>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default IsStringUndefinedOrNullOrEmpty(
  AzureApplicationInsightsConnectionString
)
  ? App
  : withAppInsights(App);

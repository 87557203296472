import { DimensionsDto } from "../../sharedLogic/types/api";
import { convertMilimetersToMeters } from "../../sharedLogic/utils/format";

interface ModuleThumbnailProps {
  dimensions: DimensionsDto;
  color: string;
  size?: number;
  marginTop?: number;
}

const ModuleThumbnail = ({
  dimensions,
  color,
  size = 125,
  marginTop = 40,
}: ModuleThumbnailProps) => {
  const getRelativeDimensions = () => {
    const getSizeInMeters = (sizeInMm: number | undefined) => {
      const sizeInM = convertMilimetersToMeters(sizeInMm);
      return sizeInM === 0 ? 1 : sizeInM;
    };

    const xSizeInM = getSizeInMeters(dimensions.xSizeInMm);
    const ySizeInM = getSizeInMeters(dimensions.ySizeInMm);
    const zSizeInM = getSizeInMeters(dimensions.zSizeInMm);

    const isCube = xSizeInM === ySizeInM && ySizeInM === zSizeInM;
    const maxDim = Math.max(xSizeInM, ySizeInM, zSizeInM);

    const scale = isCube ? 25 : 40;
    const width = (xSizeInM / maxDim) * scale;
    const height = (zSizeInM / maxDim) * scale;
    const depth = (ySizeInM / maxDim) * scale;

    return { width, height, depth };
  };

  const { width, depth, height } = getRelativeDimensions();

  const isoAngle = 30;
  const cos30 = Math.cos((isoAngle * Math.PI) / 180);
  const sin30 = Math.sin((isoAngle * Math.PI) / 180);

  const totalHeight = height + (width + depth) * sin30;
  const baseY = marginTop + totalHeight / 2;
  const baseX = 50 - ((width + depth) * cos30) / 2;

  const topShade = 1;
  const rightShade = 0.85;
  const leftShade = 0.7;

  const points = {
    frontBottomRight: `${baseX + width * cos30},${baseY}`,
    frontTopRight: `${baseX + width * cos30},${baseY - height}`,
    frontBottomLeft: `${baseX},${baseY - width * sin30}`,
    frontTopLeft: `${baseX},${baseY - height - width * sin30}`,

    rightBottomLeft: `${baseX + width * cos30},${baseY}`,
    rightBottomRight: `${baseX + (width + depth) * cos30},${
      baseY - depth * sin30
    }`,
    rightTopLeft: `${baseX + width * cos30},${baseY - height}`,
    rightTopRight: `${baseX + (width + depth) * cos30},${
      baseY - height - depth * sin30
    }`,

    topFrontLeft: `${baseX},${baseY - height - width * sin30}`,
    topFrontRight: `${baseX + width * cos30},${baseY - height}`,
    topBackLeft: `${baseX + depth * cos30},${
      baseY - height - (width + depth) * sin30
    }`,
    topBackRight: `${baseX + (width + depth) * cos30},${
      baseY - height - depth * sin30
    }`,
  };

  return (
    <div className="inline-block">
      <svg
        viewBox="0 0 100 100"
        width={size}
        height={size}
        className="overflow-visible"
      >
        <path
          d={`M ${points.rightBottomLeft} L ${points.rightBottomRight} L ${points.rightTopRight} L ${points.rightTopLeft} Z`}
          fill={color}
          opacity={rightShade}
          stroke="black"
          strokeWidth="0.2"
        />
        <path
          d={`M ${points.topFrontLeft} L ${points.topFrontRight} L ${points.topBackRight} L ${points.topBackLeft} Z`}
          fill={color}
          opacity={topShade}
          stroke="black"
          strokeWidth="0.2"
        />
        <path
          d={`M ${points.frontBottomLeft} L ${points.frontTopLeft} L ${points.frontTopRight} L ${points.frontBottomRight} Z`}
          fill={color}
          opacity={leftShade}
          stroke="black"
          strokeWidth="0.2"
        />
      </svg>
    </div>
  );
};

export default ModuleThumbnail;

import {
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import { authConfig } from "./modules/sharedLogic/authentication/authConfig";
import { DialogProvider } from "./modules/sharedLogic/components/Dialog/CustomDialog";

const heijmansYellow = "rgba(254, 184, 0, 1)";
const heijmansBlue = "rgba(0, 52, 102, 1)";

const fontSize = 12;

//MuiOverrides
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: "none",
      fontSize: fontSize,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
          marginLeft: "0px",
          "&.Mui-disabled": {
            paddingLeft: "0px",
            marginLeft: "0px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "70px",
          textTransform: "none",
          fontWeight: 700,
          color: heijmansBlue,
          backgroundColor: "white",
          border: heijmansBlue + " 2px solid",
          borderRadius: "30px",
          fontSize: fontSize,
          ":hover": {
            color: "white",
            backgroundColor: heijmansBlue,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "100%",
          minWidth: "475px",
          maxWidth: "750px",
          borderRadius: "30px",
          border: "1px solid #dcd7da",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: heijmansYellow,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          borderRadius: "50px",
          color: heijmansYellow,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: "1.8rem",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: "10px",
        },
        indicator: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxHeight: "40px",
          minWidth: "165px",
          "&.Mui-selected": {
            padding: "5px",
            color: "white",
            backgroundColor: heijmansYellow,
            borderRadius: "10px",
            minWidth: "165px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: fontSize,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          style: {
            maxHeight: 400,
          },
        },
      },
    },
  },
});

let msalInstance: IPublicClientApplication;
const getMsalInstance = (): IPublicClientApplication => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(authConfig);
    msalInstance.initialize();
  }
  return msalInstance;
};

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <ThemeProvider theme={theme}>
    <DialogProvider>
      <MsalProvider instance={getMsalInstance()}>
        <CssBaseline />
        <HashRouter>
          <App msalInstance={getMsalInstance()} />
        </HashRouter>
      </MsalProvider>
    </DialogProvider>
  </ThemeProvider>
);

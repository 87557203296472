import { useMsal } from "@azure/msal-react";
import {
  faCaretRight,
  faSlidersH,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandMoreOutlined, Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Breadcrumbs,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { EllipseIcon } from "../../icons/EllipseIcon";
import { TrackException } from "../../logging/LoggingManager";
import useProjectsStore from "../../modules/sharedLogic/state/projectsState";
import useUserStore from "../../modules/sharedLogic/state/userState";
import useLayoutStore from "../../state/layoutState";
import { IsGuid } from "../../utils/HelperFunctions";
import "./Header.scss";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const { name } = useUserStore();
  const { t, i18n } = useTranslation();
  const { currentProject } = useProjectsStore();
  const { selectedLang, setSelectedLang } = useLayoutStore();
  const { showBreadcrumb, isSidebarOpen, setIsSidebarOpen } = useLayoutStore();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLang(language);
  };

  const pathnames = location.pathname.split("/").filter((x) => x);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function capitalizeFirstLetter(inputString: string): string {
    return inputString?.charAt(0)?.toUpperCase() + inputString?.slice(1);
  }

  function getTextForTime() {
    const now = new Date();
    let text = "";
    if (now.getHours() > 5 && now.getHours() < 12) text = t("Good morning");
    else if (now.getHours() >= 12 && now.getHours() < 18)
      text = t("Good afternoon");
    else if (now.getHours() >= 18 && now.getHours() <= 22)
      text = t("Good evening");
    else text = t("Good night");

    return `${text}, ${name}!`;
  }

  async function logOut() {
    try {
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    } catch (error) {
      TrackException(error);
    }

    const accounts = instance.getAllAccounts();
    if (accounts.length !== 0) {
      logOut();
    }
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
      }}
      className="app-bar"
    >
      <Toolbar className="toolbar" sx={{ elevation: 0 }}>
        <IconButton
          className="headerMenuButton"
          color="inherit"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <MenuIcon className="headerIcon" />
        </IconButton>

        <img
          className="heijmans-logo"
          src="/assets/Heijmans-Synergy-New.png"
          onClick={() => navigate("../overview", { replace: true })}
          alt="Heijmans Synergy Logo"
        />

        {showBreadcrumb && (
          <div className="breadcrumb-view">
            <FontAwesomeIcon className="toolbar-nav-icon" icon={faSlidersH} />
            <Breadcrumbs
              style={{ marginLeft: "10px" }}
              aria-label="breadcrumb"
              separator={
                <FontAwesomeIcon
                  className="toolbar-nav-icon caretRightIcon"
                  icon={faCaretRight}
                />
              }
            >
              {pathnames.map((value, index) => {
                if (!IsGuid(value)) {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                  return last ? (
                    <Typography color="text.primary" key={to}>
                      {t(capitalizeFirstLetter(value))}
                    </Typography>
                  ) : (
                    <LinkRouter
                      underline="hover"
                      color="inherit"
                      to={to}
                      key={to}
                    >
                      {t(capitalizeFirstLetter(value))}
                    </LinkRouter>
                  );
                } else if (value === currentProject?.externalId) {
                  return (
                    <Typography color="text.primary" key={value}>
                      {t(capitalizeFirstLetter(currentProject?.name))}
                    </Typography>
                  );
                }
                return null;
              })}
            </Breadcrumbs>
          </div>
        )}

        {!showBreadcrumb && <div className="titel">{getTextForTime()}</div>}
        <div className="grow" />

        <Select
          value={selectedLang}
          onChange={(e) => changeLanguage(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={(props) => (
            <ExpandMoreOutlined className="svg" {...props} />
          )}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
          }}
        >
          <MenuItem value={"en-US"}>EN</MenuItem>
          <MenuItem value={"nl-NL"}>NL</MenuItem>
        </Select>

        <div className="userInfo">
          <FontAwesomeIcon className="userIcon" icon={faUserCircle} />
          <div className="header-label">{name}</div>

          <IconButton aria-describedby={id} onClick={handleClick}>
            <EllipseIcon className="icon" size={25} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography
              style={{ cursor: "pointer" }}
              sx={{ p: 2 }}
              onClick={() => logOut()}
            >
              {t("Log out")}
            </Typography>
          </Popover>
        </div>
      </Toolbar>
    </AppBar>
  );
}

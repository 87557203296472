import {
  ModuleDto,
  SpaceCommonAreaDto,
  SpaceComponentDto,
  SpaceResidentialDto,
} from "../types/api";

export function isSpaceResidentialDto(
  module: ModuleDto
): module is SpaceResidentialDto {
  if (module === null) {
    return false;
  }
  return module && "usableAreaInMm2" in module && "rentalPoints" in module;
}

export function isSpaceCommonAreaDto(
  module: ModuleDto
): module is SpaceComponentDto {
  if (module === null) {
    return false;
  }
  return module && "bayWidth1" in module && !("rentalPoints" in module);
}

export function isSpaceComponentDto(
  module: ModuleDto
): module is SpaceCommonAreaDto {
  if (module === null) {
    return false;
  }
  return module && !("rentalPoints" in module) && !("bayWidth1" in module);
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import { useDialog } from "../../../sharedLogic/components/Dialog/CustomDialog";
import { DimensionsDto } from "../../../sharedLogic/types/api";
import {
  convertMetersToMilimeters,
  convertMilimetersToMeters,
} from "../../../sharedLogic/utils/format";
import { GenerationService } from "../../services/GenerationService";
import { useModuleDetailsStore } from "../../state/moduleDetailsState";
import {
  GenerationDto,
  SpaceDto,
  SupportedValueNumbersDto,
} from "../../types/api";
import { SpaceType } from "../../types/enums";
import { getColor } from "../../utils/colors";
import GenerateBuildingControl from "./GenerateBuildingControl";
import "./GenerateBuildings.scss";
import { CalculateMinMaxnumberOfApartments } from "./GenerateBuildingHelper";

const livingRoomTypes = [
  SpaceType.Appartment1K,
  SpaceType.Appartment2K,
  SpaceType.Appartment3K,
  SpaceType.Appartment4K,
];

type LivingRoomType = (typeof livingRoomTypes)[number];

interface Dimensions {
  length: [number, number];
  height: [number, number];
  apartments: [number, number];
}

interface LivingRoom {
  value: [number, number];
  minMax: [number, number];
  modules: string[];
}

type LivingRooms = {
  [key in LivingRoomType]: LivingRoom;
};

interface GenerateBuildingsProps {
  modules: SpaceDto[];
}

export default function GenerateBuildings({ modules }: GenerateBuildingsProps) {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { setGenerationProcessId } = useModuleDetailsStore();
  const generationService = new GenerationService();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [accessibilityTypes, setAccessibilityTypes] = useState<string[]>([]);
  const [accessibilityType, setAccessibilityType] = useState<string[]>([]);
  const [filteredModules, setFilteredModules] = useState<SpaceDto[]>(modules);

  const [supportedValues, setSupportedValues] =
    useState<SupportedValueNumbersDto>();

  const [dimensions, setDimensions] = useState<Dimensions>({
    length: [0, 50],
    height: [0, 50],
    apartments: [1, 250],
  });

  const [minMaxDimensions, setMinMaxDimensions] = useState<Dimensions>({
    length: [0, 50],
    height: [0, 50],
    apartments: [1, 250],
  });

  const [livingRooms, setLivingRooms] = useState<LivingRooms>(
    livingRoomTypes.reduce(
      (acc, type) => ({
        ...acc,
        [type]: { value: [0, 100], minMax: [0, 100], modules: [] },
      }),
      {} as LivingRooms
    )
  );

  useEffect(() => {
    const fetchSupportedValues = async () => {
      const supportedValues = await generationService.getSupportedValueTypes();
      if (supportedValues.accessTypes) {
        setAccessibilityTypes(supportedValues.accessTypes);
      }
    };

    fetchSupportedValues();
  }, []);

  useEffect(() => {
    if (accessibilityType.length === 0) return;

    (async () => {
      const supportedValueNumbers =
        await generationService.getSupportedValueNumbers(accessibilityType[0]);

      setSupportedValues(supportedValueNumbers);

      const newLengthMinMax: [number, number] = [
        convertMilimetersToMeters(supportedValueNumbers.minLengthInMm),
        convertMilimetersToMeters(supportedValueNumbers.maxLengthInMm),
      ];
      const newHeightMinMax: [number, number] = [
        convertMilimetersToMeters(supportedValueNumbers.minHeightInMm),
        convertMilimetersToMeters(supportedValueNumbers.maxHeightInMm),
      ];
      const newApartmentsMinMax =
        accessibilityType[0] === "SingleFamilyHouse"
          ? dimensions.apartments
          : CalculateMinMaxnumberOfApartments(
              accessibilityType[0],
              newLengthMinMax,
              newHeightMinMax,
              supportedValueNumbers
            );

      const newMinMaxDimensions = {
        length: newLengthMinMax,
        height: newHeightMinMax,
        apartments: newApartmentsMinMax,
      } as Dimensions;

      setMinMaxDimensions(newMinMaxDimensions);
      setDimensions(newMinMaxDimensions);

      const newLivingRooms = { ...livingRooms };
      livingRoomTypes.forEach((type) => {
        const modulesOfType = modules.filter(
          (x) =>
            x.spaceType === type &&
            x.accessType === accessibilityType[0] &&
            x.grossFloorAreaInM2
        );
        const grossFloorAreas = modulesOfType.map(
          (module) => module.grossFloorAreaInM2 ?? 0
        );
        newLivingRooms[type] = {
          value: [
            Math.floor(Math.min(...grossFloorAreas)),
            Math.ceil(Math.max(...grossFloorAreas)),
          ],
          minMax: [
            Math.floor(Math.min(...grossFloorAreas)),
            Math.ceil(Math.max(...grossFloorAreas)),
          ],
          modules: [],
        };
      });
      setLivingRooms(newLivingRooms);
      setFilteredModules(
        modules.filter((x) => x.accessType === accessibilityType[0])
      );
    })();
  }, [accessibilityType, modules]);

  useEffect(() => {
    if (accessibilityType.length === 0) return;
    const [minApartments, maxApartments] = CalculateMinMaxnumberOfApartments(
      accessibilityType[0],
      dimensions.length,
      dimensions.height,
      supportedValues
    );
    setDimensions((prev) => ({
      ...prev,
      apartments: [minApartments, maxApartments],
    }));
  }, [dimensions.length, dimensions.height]);

  const handleContinue = async () => {
    var spacesToUse = Object.values(livingRooms).flatMap(
      (room) => room.modules
    );

    const depthInMm = supportedValues?.depthInMm ?? 0;
    const requirementSetDto: GenerationDto = {
      typeToGenerate: accessibilityType[0],
      spacesToUse: [...new Set(spacesToUse)],
      minDimensions: {
        xSizeInMm: convertMetersToMilimeters(dimensions.length[0]),
        ySizeInMm: depthInMm,
        zSizeInMm: convertMetersToMilimeters(dimensions.height[0]),
      } as DimensionsDto,
      maxDimensions: {
        xSizeInMm: convertMetersToMilimeters(dimensions.length[1]),
        ySizeInMm: depthInMm,
        zSizeInMm: convertMetersToMilimeters(dimensions.height[1]),
      } as DimensionsDto,
      numberOfAppartmentsToUse: {
        min: dimensions.apartments[0],
        max: dimensions.apartments[1],
      },
    };

    const processId = await generationService.startGeneration(
      requirementSetDto
    );
    setGenerationProcessId(processId);
    handleClose();
    closeDialog();
  };

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const updateDimension = (key: string, value: number[]) => {
    setDimensions((prev) => ({ ...prev, [key]: value }));
  };

  const updateLivingRoom = (type: SpaceType, key: string, value: any) => {
    setLivingRooms((prev) => ({
      ...prev,
      [type]: { ...prev[type], [key]: value },
    }));
  };

  return (
    <div className="generate-buildings">
      <div className="section-title">{t("StackedHousing.Building")}</div>
      <hr />
      <div className="section-properties">
        <GenerateBuildingControl
          label={t("StackedHousing.AccessType")}
          value={accessibilityType}
          setValue={(value: string[]) => {
            setAccessibilityType(value);
            setFilteredModules(
              modules.filter((x) => x.accessType === value[0])
            );
          }}
          items={accessibilityTypes}
        />

        {(
          Object.entries(dimensions) as [keyof Dimensions, [number, number]][]
        ).map(([key, value]) => (
          <GenerateBuildingControl
            key={key}
            label={`${t(key.charAt(0).toUpperCase() + key.slice(1))} ${
              key === "apartments" ? "" : "(m)"
            }`}
            min={minMaxDimensions[key][0]}
            max={minMaxDimensions[key][1]}
            sliderValue={value}
            setSliderValue={(newValue: number[]) =>
              updateDimension(key, newValue)
            }
          />
        ))}
      </div>

      <div className="section-title">{t("StackedHousing.UnitMix")}</div>
      <hr />
      <div className="section-properties">
        {livingRoomTypes.map((type, index) => (
          <GenerateBuildingControl
            key={type}
            label={`${index + 1} ${t("StackedHousing.LivingRoom")}`}
            color={getColor(type)}
            min={livingRooms[type].minMax[0]}
            max={livingRooms[type].minMax[1]}
            value={livingRooms[type].modules}
            sliderValue={livingRooms[type].value}
            setValue={(value: string[]) =>
              updateLivingRoom(type, "modules", value)
            }
            setSliderValue={(value: number[]) =>
              updateLivingRoom(type, "value", value)
            }
            items={filteredModules
              .filter(
                (x) =>
                  x.spaceType === type &&
                  x.accessType === accessibilityType[0] &&
                  x.grossFloorAreaInM2 &&
                  x.grossFloorAreaInM2 >= livingRooms[type].value[0] &&
                  x.grossFloorAreaInM2 <= livingRooms[type].value[1]
              )
              .map((module) => module.code)}
          />
        ))}
      </div>

      <div className="button-container">
        <Button
          disabled={
            accessibilityType.length === 0 ||
            Object.values(livingRooms).flatMap((room) => room.modules)
              .length === 0
          }
          onClick={handleOpen}
        >
          {t("Start")}
        </Button>
        <Button sx={{ marginLeft: "10px" }} onClick={() => closeDialog()}>
          {t("Cancel")}
        </Button>
      </div>

      <Dialog
        sx={{ zIndex: 9999 }}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}
        PaperProps={{
          style: {
            borderRadius: 20,
            width: "35%",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 18,
            fontWeight: 900,
            color: "rgba(0, 52, 102, 1)",
          }}
        >
          {t("StackedHousing.CreateBuildings")}
          <Box component={"div"} sx={{ ml: "auto" }}>
            <CancelButton onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "25px", marginTop: "-20px" }}>
          <div>{`${t("GenerateDataMessage")} ${t(
            "StackedHousing.Buildings"
          ).toLocaleLowerCase()}. ${t(
            "StackedHousing.GenerationDuration"
          )}`}</div>
          <br />
          <div>{t("ProceedMessage")}</div>
          <DialogActions sx={{ padding: "0px 25px 10px 20px !important" }}>
            <Button onClick={handleContinue}>{t("Continue")}</Button>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { GridColDef } from "@mui/x-data-grid";
import i18next from "i18next";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { NitrogenInputPaneState } from "../../../../state/NitrogenState/nitrogenInputPaneState";
import { ConfigurationDto } from "../../types/api";
import { formatValue } from "../../utils/format";

export function GetColumns(type: ProjectTypeEnum): GridColDef[] {
  switch (type) {
    case ProjectTypeEnum.Analyser:
      return getAnalyserColumns();
    case ProjectTypeEnum.HSA:
      return getHSAColumns();
    default:
      return [];
  }
}

function getHSAColumns(): GridColDef[] {
  return [
    {
      field: "bvo",
      headerName: i18next.t("StackedHousing.BVO"),
      flex: 1,
      valueFormatter(value, row) {
        if (row.configuration === "") {
          return "";
        }

        const configuration = JSON.parse(row.configuration) as ConfigurationDto;
        const lotSurface = configuration?.drawedPlot?.lotSurface ?? undefined;
        return lotSurface === undefined ? "" : `${formatValue(lotSurface)} m²`;    
      },
    },
  ];
}

function getAnalyserColumns(): GridColDef[] {
  return [
    {
      field: "yearLimit",
      headerName: i18next.t("NitrogenPage.YearLimit"),
      flex: 1,
      valueFormatter(value, row) {
        if (row.configuration === "") {
          return "";
        }
        
        const noxLimit =
          (JSON.parse(row.configuration) as NitrogenInputPaneState)
            ?.annualNOxLimit ?? "";
        return noxLimit === "" ? "" : `${noxLimit} kg NOₓ`;
      },
    },
  ];
}

import i18next from "i18next";
import moment from "moment";

export const convertMilimetersToMeters = (
  valueInMilimeter: string | number | undefined
): number => {
  const value = parseNumberInput(valueInMilimeter);
  return value / 1000;
};

export const convertMetersToMilimeters = (
  valueInMeters: string | number | undefined
): number => {
  const value = parseNumberInput(valueInMeters);
  return Math.round(value * 1000);
};

export const convertSquareMilimetersToSquareMeters = (
  valueInSquareMilimeter: string | number | undefined
): number => {
  const value = parseNumberInput(valueInSquareMilimeter);
  return value / 1000000;
};

export const convertSquareMetersToSquareMilimeters = (
  valueInSquareMeters: string | number | undefined
): number => {
  const value = parseNumberInput(valueInSquareMeters);
  return Math.round(value * 1000000);
};

export const convertCentsToEuro = (
  valueInCents: string | number | undefined
): number => {
  const value = parseNumberInput(valueInCents);
  return value / 100;
};

export const convertEuroToCents = (
  valueInEuro: string | number | undefined
): number => {
  const value = parseNumberInput(valueInEuro);
  return Math.round(value * 100);
};

export const formatValue = (
  amount: number | string | null | undefined,
  unit?: string
): string => {
  if (amount == null) return "";

  const getDecimalSeparator = () => {
    return (1.1).toLocaleString(i18next.language).charAt(1);
  };

  const decimalSeparator = getDecimalSeparator();

  if (unit?.includes("€")) {
    return Number(amount)
      .toLocaleString(i18next.language, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(/€|€\/m²/g, "")
      .trim();
  }

  if (unit === "st") {
    return amount.toString();
  }

  if (typeof amount === "number") {
    const hasDecimal = amount % 1 !== 0;
    return amount.toLocaleString(i18next.language, {
      style: "decimal",
      minimumFractionDigits: hasDecimal ? 2 : 0,
      maximumFractionDigits: 2,
    });
  }
  
  const normalizedValue = amount
    .toString()
    .replace(/[.,]/g, decimalSeparator)
    .trim();

  return normalizedValue;
};

export function roundNumber(num: number, fractionDigits: number): number {
  return Number(num.toFixed(fractionDigits));
}

export const parseNumberInput = (
  value: string | number | undefined
): number => {
  if (typeof value === "string") {
    const normalized = value.replace(",", ".");
    const parsed = parseFloat(normalized);
    return isNaN(parsed) ? 0 : parsed;
  }
  return value ?? 0;
};

export const formatDateToLocalDateString = (date: Date): string => {
  const tempDate = new Date(date.toDateString());
  return moment(tempDate).format("YYYY-MM-DD");
};

import { Box, Card, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { config, useChain, useSpring, useSpringRef } from "react-spring";
import ModuleThumbnail from "../../../HSA/components/ModuleThumbnail";
import { SpaceDto } from "../../../HSA/types/api";
import { getColor } from "../../../HSA/utils/colors";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import { TypeObject } from "../../types/enums";
import SummaryInfoDto from "../../types/InfoField";
import {
  renderBestFitRateInfoCardSection,
  renderCandidateSection,
  renderExportButtonSection,
  renderNotInOriginalListSection,
  renderSummaryViewSection,
  renderTitle,
} from "./renderSummaryHelpers";
import "./Scene.scss";

interface SceneProps<T extends DTO> {
  currentObject: T;
  currentState?: ExploreState<T> & ExploreActions<T>;
  summaryInfo: SummaryInfoDto[];
  onClickObject?: (selectedObject: T) => void;
  onHoverObject?: (hoveredObject: T | null) => void;
  isCandidate: boolean;
  isInOriginalList: boolean;
  isExportView?: boolean;
  selectedExportedBuilingsIds?: { code: string; count: number }[];
  setSelectedExportedBuildingsIds?: (
    values: { code: string; count: number }[]
  ) => void;
  customTitle?: string;
  onDoubleClick?: (currentObject: T) => void;
}

export default function Scene<T extends DTO>({
  currentObject,
  currentState,
  summaryInfo,
  isCandidate,
  isInOriginalList,
  isExportView = false,
  selectedExportedBuilingsIds,
  customTitle,
  onClickObject,
  setSelectedExportedBuildingsIds,
  onDoubleClick,
}: SceneProps<T>) {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isCandidate ? "#003466" : "#fff",
    borderColor: isCandidate ? "#003466" : "#ddd",
    config: config.gentle,
    ref: checkboxAnimationRef,
  });
  const [checkmarkLength, setCheckmarkLength] = useState<number>(0);
  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isCandidate ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef,
  });

  useChain(
    isCandidate
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0.1]
  );
  function handleSelectingDatacenterComponent() {
    if (onClickObject) onClickObject(currentObject);
  }

  function removeFromCandidateList() {
    if (onClickObject) onClickObject(currentObject);
  }

  if (!currentObject) {
    return <div></div>;
  }

  return (
    <>
      <Box
        component={"div"}
        className="scene"
        onDoubleClick={() => onDoubleClick?.(currentObject)}
      >
        <Card
          className="scene-item-card"
          sx={{
            border: isCandidate ? "1px solid #003466 !important" : "",
          }}
        >
          <CardContent className="card-content">
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="flex-start"
            >
              {currentObject.renderType === "image" ? (
                <img
                  className="scene-image"
                  alt="configuration"
                  src={(currentObject as SpaceDto).view2d_png}
                />
              ) : currentObject.renderType === "thumbnail" ? (
                <div style={{ marginLeft: "12.5px", marginRight: "12.5px" }}>
                  <ModuleThumbnail
                    size={100}
                    marginTop={50}
                    color={getColor((currentObject as SpaceDto).spaceType)}
                    dimensions={(currentObject as SpaceDto).dimensions}
                  />
                </div>
              ) : currentObject.renderType === "chart" ? (
                <></>
              ) : (
                <div>No specific rendering</div>
              )}
              <Grid item xs={12} sm>
                <Grid container>
                  {renderTitle({
                    currentObject,
                    currentPageType:
                      (currentObject as T as { typeObject: TypeObject })
                        .typeObject ?? 0,
                    t,
                    customTitle,
                    isInOriginalList,
                  })}

                  {renderExportButtonSection({
                    isExportView,
                    currentObject,
                    selectedExportedBuilingsIds,
                    setSelectedExportedBuildingsIds,
                  })}

                  {renderCandidateSection(
                    {
                      isCandidate,
                      isInOriginalList,
                      handleSelectingDatacenterComponent,
                      checkboxAnimationStyle,
                      checkmarkLength,
                      checkmarkAnimationStyle,
                      t,
                    },
                    setCheckmarkLength
                  )}
                  {renderNotInOriginalListSection({
                    isExportView,
                    isInOriginalList,
                    isHovered,
                    setIsHovered,
                    removeFromCandidateList,
                  })}
                  {renderSummaryViewSection({ currentObject, summaryInfo })}
                  <Grid item xs={12}>
                    <div className="mt10" />
                  </Grid>
                  {renderBestFitRateInfoCardSection({
                    isExportView,
                    currentObject,
                    t,
                  })}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Popover, Typography } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StyledDataGrid from "../../../../components/StyledDataGrid/StyledDataGrid";
import { EllipseIcon } from "../../../../icons/EllipseIcon";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { Subjects } from "../../authentication/PermissionsManager";
import { TrackException } from "../../logging/LoggingManager";
import { DeleteProject, GetProjects } from "../../services/ProjectService";
import useLayoutStore from "../../state/layoutState";
import useProjectsStore from "../../state/projectsState";
import useUserStore from "../../state/userState";
import ProjectDto from "../../types/api";
import "./BaseProjectsPage.scss";
import CreateOrUpdateProjectView from "./CreateOrUpdateProjectView";
import { GetColumns } from "./projectsConfig";

interface BaseProjectsPageProps {
  type: ProjectTypeEnum;
  subject: Subjects;
}

export default function BaseProjectsPage({
  type,
  subject,
}: BaseProjectsPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    projects,
    currentProject,
    setProjects,
    setRenamingProject,
    setCurrentProject,
    setIsProjectViewOpen,
  } = useProjectsStore();
  const { setShowBreadcrumb } = useLayoutStore();

  const { emailAddress, appAbility } = useUserStore();

  const [allColumns, setAllColumns] = React.useState<GridColDef[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popOverOpen, setPopOverOpen] = React.useState<boolean>(false);
  const [refreshData, setRefreshData] = React.useState<boolean>(false);
  const [showLoadingIndicator, setShowLoadingIndicator] =
    React.useState<boolean>(false);

  const handleRenamingProject = (project: ProjectDto) => {
    setPopOverOpen(false);
    setRenamingProject(true);
    setCurrentProject(project);
    setIsProjectViewOpen(true);
  };

  const handleDeletingProject = (project: ProjectDto) => {
    try {
      (async () => {
        await DeleteProject(type, project);
        setRefreshData(true);
      })();
    } catch (ex) {
      TrackException(ex);
    }
  };

  const firstColumns: GridColDef[] = [
    {
      field: "imgUrl",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <img
            src={
              type === ProjectTypeEnum.Analyser
                ? "/assets/tile_images/analysers/stikstof.png"
                : type === ProjectTypeEnum.HSA
                ? "/assets/buildings/galleryMatrix.png"
                : params.row.value
            }
            style={{
              width: "100%",
              height: "100px",
              padding: "5px",
              borderRadius: "20px",
              objectFit: "contain",
            }}
            alt="Project"
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
    },
    {
      field: "projectAddress",
      headerName: t("ProjectAddress"),
      width: 400,
    },
  ];

  const baseEndColumns: GridColDef[] = [
    {
      field: "createdBy",
      headerName: t("Owner"),
      flex: 1,
    },
    {
      field: "lastModifiedAt",
      headerName: t("LastModifiedAt"),
      flex: 1,
      valueFormatter(value, row) {
        const localTime = moment.utc(row.lastModifiedAt as string).local();
        return localTime.format("DD-MM-YYYY HH:mm");
      },
    },
    {
      field: "buttons",
      width: 75,
      headerName: "",
      renderCell: ({ row }) => {
        const createdBy = row.createdBy;
        if (createdBy && createdBy !== emailAddress) {
          return <></>;
        }
        return (
          <div className="full-height center-center">
            <IconButton
              style={{
                display: "flex",
                height: "40px",
              }}
              aria-describedby={popOverOpen ? "simple-popover" : undefined}
              onClick={(event: any) => {
                setAnchorEl(event.currentTarget);
                setPopOverOpen(true);
              }}
            >
              <EllipseIcon className="icon" size={25} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    setAllColumns([...firstColumns, ...GetColumns(type), ...baseEndColumns]);

    (async () => {
      setShowBreadcrumb(true);
      await LoadItems();
    })();
  }, []);

  React.useEffect(() => {
    if (refreshData) {
      setProjects([]);
      setRefreshData(false);
      setPopOverOpen(false);
      setIsProjectViewOpen(false);

      (async () => {
        await LoadItems();
      })();
    }
  }, [refreshData]);

  async function LoadItems() {
    try {
      setShowLoadingIndicator(true);
      setProjects(await GetProjects(type));
    } finally {
      setShowLoadingIndicator(false);
    }
  }

  const onCellClick: GridEventListener<"cellClick"> = (params) => {
    const project = params.row as ProjectDto;
    setCurrentProject(project);
    if (params.field !== "buttons" && project.externalId) {
      navigate(project.externalId);
    }
  };

  return (
    <div className="projects-view">
      <div className="projects-view-header">
        <div className="projects-view-title">
          <div className="titel">{t(ProjectTypeEnum[type])}</div>
          <div className="sub-titel">
            {t("Choose a project or create a new project")}
          </div>
        </div>
        <Button
          disabled={appAbility?.cannot("create", subject)}
          onClick={() => {
            setCurrentProject({
              configuration: "",
            } as ProjectDto);
            setIsProjectViewOpen(true);
          }}
          style={{ width: "120px", height: "40px" }}
          className="add-rule-button"
          startIcon={<AddIcon sx={{ fontSize: "20px" }} />}
          type="submit"
          variant="text"
        >
          {t("Create")}
        </Button>
      </div>

      <div className="data-grid-view">
        <StyledDataGrid
          loading={showLoadingIndicator}
          disableRowSelectionOnClick
          columns={allColumns}
          rows={projects}
          getRowId={(row) => row.externalId}
          onCellClick={onCellClick}
          rowHeight={100}
        />
      </div>

      <Popover
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setPopOverOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0), 0px 8px 10px 1px rgba(0,0,0,0), 0px 3px 14px 2px rgba(0,0,0,0.35)",
          },
        }}
      >
        <Typography
          style={{ cursor: "pointer" }}
          sx={{ p: 2 }}
          onClick={() => {
            handleRenamingProject(currentProject);
          }}
        >
          {t("Rename")}
        </Typography>

        <Typography
          style={{ cursor: "pointer" }}
          sx={{ p: 2 }}
          onClick={() => {
            handleDeletingProject(currentProject);
          }}
        >
          {t("Delete")}
        </Typography>
      </Popover>

      <CreateOrUpdateProjectView
        typeProject={type}
        refreshProjects={setRefreshData}
      />
    </div>
  );
}

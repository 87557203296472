import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SliderWithMarks from "../../../../components/SliderWithMarks/SliderWithMarks";
import PercentageInput from "../../../sharedLogic/components/PercentageInput/PercentageInput";
import { UnitType } from "../../../sharedLogic/types/UnitType";
import "./UnitMix.scss";

interface UnitMixProps {
  units: UnitType[];
  showSlider?: boolean;
  editPercentage?: boolean;
  setUnits?: (units: UnitType[]) => void;
}

const UnitMix: React.FC<UnitMixProps> = ({
  units,
  editPercentage = true,
  showSlider = true,
  setUnits,
}) => {
  const { t } = useTranslation();

  const handlePercentageChange = (index: number, newValue: number | null) => {
    const newUnits = [...units];
    newUnits[index].ratio = newValue === null ? undefined : newValue;
    if (setUnits) {
      setUnits(newUnits);
    }
  };

  const handleSliderChange = (index: number, newValue: number[]) => {
    const newUnits = [...units];
    newUnits[index].value = newValue;
    if (setUnits) {
      setUnits(newUnits);
    }
  };

  return (
    <Grid container spacing={1} alignItems="center">
      {units.map((unit, index) => (
        <Grid item xs={12} key={index}>
          <Grid container>
            {unit.count !== undefined && (
              <Grid item xs={2}>
                <span className="unit-mix__count">
                  {Math.round(unit.count)}st
                </span>
              </Grid>
            )}

            {!editPercentage && unit.ratio !== undefined && (
              <Grid item xs={2}>
                <span className="unit-mix__percentage">
                  {unit.ratio === undefined || unit.ratio === null
                    ? ""
                    : `${Math.round(unit.ratio)}%`}
                </span>
              </Grid>
            )}

            {editPercentage && (
              <div className="unit-mix__percentage-input">
                <PercentageInput
                  disabled={!editPercentage}
                  value={unit.ratio}
                  onChange={(value) => handlePercentageChange(index, value)}
                />
              </div>
            )}

            <Grid item xs>
              <div className="unit-mix__name-container">
                <div
                  className="unit-mix__color-indicator"
                  style={{ backgroundColor: unit.color }}
                />
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                  variant="body2"
                  className="unit-mix__item-name"
                >
                  {t(unit.name)}
                </Typography>
              </div>
            </Grid>

            {showSlider && (
              <div className="unit-mix__slider-container">
                <SliderWithMarks
                  value={unit.value}
                  min={unit.minValue}
                  max={unit.maxValue}
                  setValue={(value) => handleSliderChange(index, value)}
                  aria-labelledby={`${unit.name}-slider`}
                />
              </div>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default UnitMix;

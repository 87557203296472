import { CostKeyFigureDto } from "../../interfaces/aggregate/CostKeyFigureDto";
import { AggregatePageEnum } from "../../interfaces/aggregate/enums/AggregatePageEnum";
import { ExportEnum } from "../../interfaces/aggregate/enums/ExportEnum";
import { EquipmentKeyFigureDto } from "../../interfaces/aggregate/EquipmentKeyFigureDto";
import { OwnerDto } from "../../interfaces/aggregate/OwnerDto";
import { ProductionKeyFigureDto } from "../../interfaces/aggregate/ProductionKeyFigureDto";
import { TrackException } from "../../logging/LoggingManager";
import { aggregateApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import { ExportTableToCSV, ImportTableFromCSV } from "./ImportExportService";

interface Identifiable {
  id: number;
}

interface ApiResponse<T> {
  data: T;
}
const baseUrlCost: string = "/api/cost-key-figure";
const baseUrlProduction: string = "/api/production-key-figure";
const baseUrlEquipment: string = "/api/equipment-key-figure";
const baseUrlActivity: string = "/api/activity";

async function getData<T extends Identifiable>(url: string): Promise<T[]> {
  try {
    const response: ApiResponse<T[]> = await aggregateApiClient.get<T[]>(url);
    return response.data.sort((a, b) => a.id - b.id);
  } catch (ex) {
    TrackException(ex);
    return [];
  }
}

async function getObject<T>(url: string, id: number): Promise<T> {
  try {
    const response: ApiResponse<T> = await aggregateApiClient.get<T>(
      `${url}/${id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    return {} as T;
  }
}

async function updateObject<T extends Identifiable>(
  url: string,
  data: T
): Promise<number> {
  try {
    const response: ApiResponse<number> = await aggregateApiClient.put<number>(
      `${url}/${data.id}`,
      data
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    return 0;
  }
}

async function saveObject<T extends { owner?: OwnerDto }>(
  url: string,
  data: T
): Promise<number> {
  try {
    const response: ApiResponse<number> = await aggregateApiClient.post<number>(
      url,
      data
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    return 0;
  }
}

async function deleteObject<T>(url: string, id: number): Promise<T> {
  try {
    const response: ApiResponse<T> = await aggregateApiClient.delete<T>(
      `${url}/${id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex);
    return {} as T;
  }
}

export async function GetCostKeyFigures(
  categoryId: string
): Promise<CostKeyFigureDto[]> {
  return getData<CostKeyFigureDto>(
    `${baseUrlCost}?pageSize=500&categoryId=${categoryId}`
  );
}

export async function GetCostKeyFigure(id: number): Promise<CostKeyFigureDto> {
  return getObject<CostKeyFigureDto>(baseUrlCost, id);
}

export async function UpdateCostKeyFigure(
  data: CostKeyFigureDto
): Promise<number> {
  return updateObject<CostKeyFigureDto>(baseUrlCost, mapCostKeyFigure(data));
}

export async function SaveCostKeyFigure(
  data: CostKeyFigureDto
): Promise<number> {
  return saveObject<CostKeyFigureDto>(baseUrlCost, mapCostKeyFigure(data));
}

export async function DeleteCostKeyFigure(
  id: number
): Promise<CostKeyFigureDto> {
  return deleteObject<CostKeyFigureDto>(baseUrlCost, id);
}

export async function GetProductionKeyFigures(
  categoryId: string
): Promise<ProductionKeyFigureDto[]> {
  return getData<ProductionKeyFigureDto>(
    `${baseUrlProduction}?pageSize=500&categoryId=${categoryId}`
  );
}

export async function GetProductionKeyFigure(
  id: number
): Promise<ProductionKeyFigureDto> {
  return getObject<ProductionKeyFigureDto>(baseUrlProduction, id);
}

export async function GetProductionKeyFiguresByActivityId(
  activityId: string
): Promise<ProductionKeyFigureDto[]> {
  return getData<ProductionKeyFigureDto>(
    `${baseUrlProduction}?pageSize=500&activityId=${activityId}`
  );
}

export async function UpdateProductionKeyFigure(
  data: ProductionKeyFigureDto
): Promise<number> {
  return updateObject<ProductionKeyFigureDto>(
    baseUrlProduction,
    mapProductionKeyFigure(data)
  );
}

export async function SaveProductionKeyFigure(
  data: ProductionKeyFigureDto
): Promise<number> {
  return saveObject<ProductionKeyFigureDto>(
    baseUrlProduction,
    mapProductionKeyFigure(data)
  );
}

export async function DeleteProductionKeyFigure(
  id: number
): Promise<ProductionKeyFigureDto> {
  return deleteObject<ProductionKeyFigureDto>(baseUrlProduction, id);
}

export async function GetEquipmentKeyFigures(
  categoryId?: string,
  equipmentTypeId?: string
): Promise<EquipmentKeyFigureDto[]> {
  let url = `${baseUrlEquipment}?pageSize=500`;
  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }
  if (equipmentTypeId) {
    url += `&equipmentTypeId=${equipmentTypeId}`;
  }
  return getData<EquipmentKeyFigureDto>(url);
}

export async function GetEquipmentKeyFigure(
  id: number
): Promise<EquipmentKeyFigureDto> {
  return getObject<EquipmentKeyFigureDto>(baseUrlEquipment, id);
}

export async function SaveEquipmentKeyFigure(
  data: EquipmentKeyFigureDto
): Promise<number> {
  return saveObject<EquipmentKeyFigureDto>(
    baseUrlEquipment,
    mapEquipmentKeyFigure(data)
  );
}

export async function UpdateEquipmentKeyFigure(
  data: EquipmentKeyFigureDto
): Promise<number> {
  return updateObject<EquipmentKeyFigureDto>(
    baseUrlEquipment,
    mapEquipmentKeyFigure(data)
  );
}

export async function DeleteEquipmentKeyFigure(id: number) {
  return deleteObject<EquipmentKeyFigureDto>(baseUrlEquipment, id);
}

export async function ImportKeyFigures(
  currentPage: AggregatePageEnum,
  categoryId: string,
  file: FormData
): Promise<any> {
  try {
    const url = getImportExportUrl(currentPage, categoryId);
    await ImportTableFromCSV(file, undefined, url);
  } catch (ex) {
    TrackException(ex);
  }
}

export async function ExportKeyFigureToCSV(
  currentPage: AggregatePageEnum,
  categoryId: string
) {
  try {
    const url = getImportExportUrl(currentPage, categoryId);
    await ExportTableToCSV(url, currentPage, ExportEnum.KeyFigures, categoryId);
  } catch (ex) {
    TrackException(ex);
  }
}

function getImportExportUrl(
  currentPage: AggregatePageEnum,
  categoryId: string
): string {
  let url = "";
  switch (currentPage) {
    case AggregatePageEnum.CostKeyFigure:
      url = `${baseUrlCost}/csv`;
      break;
    case AggregatePageEnum.ProductionKeyFigure:
      url = `${baseUrlProduction}/csv`;
      break;
    case AggregatePageEnum.EquipmentKeyFigure:
      url = `${baseUrlEquipment}/csv`;
      break;
    case AggregatePageEnum.Activities:
      url = `${baseUrlActivity}/csv`;
      break;
    default:
      return "";
  }

  url += `?categoryId=${Number(categoryId)}`;
  return url;
}

function mapEquipmentKeyFigure(
  dto: EquipmentKeyFigureDto
): EquipmentKeyFigureDto {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    ownerId: dto.ownerId,
    categoryId: dto.categoryId,
    departmentId: dto.departmentId,
    equipmentTypeId: dto.equipmentTypeId,
    fuelConsumption: dto.fuelConsumption,
    adBlueConsumption: dto.adBlueConsumption,
    stageClass: dto.stageClass,
    powerInKw: dto.powerInKw,
    loadPercentage: dto.loadPercentage,
    hvoSuitable: dto.hvoSuitable,
    manufacturedYear: dto.manufacturedYear,
    carbonEmission: dto.carbonEmission,
    fuel: dto.fuel,
    brandId: dto.brandId,
    brandType: dto.brandType,
  } as EquipmentKeyFigureDto;
}

function mapCostKeyFigure(dto: CostKeyFigureDto): CostKeyFigureDto {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    ownerId: dto.ownerId,
    categoryId: dto.categoryId,
    departmentId: dto.departmentId,
    costCategoryId: dto.costCategoryId,
    costCodeId: dto.costCodeId,
    costUnitId: dto.costUnitId,
    objectTypeId: dto.objectTypeId,
    costPerUnit: dto.costPerUnit,
    type: dto.type,
    unit: dto.unit,
  } as CostKeyFigureDto;
}

function mapProductionKeyFigure(
  dto: ProductionKeyFigureDto
): ProductionKeyFigureDto {
  return {
    id: dto.id,    
    name: dto.name,
    type: dto.type,
    workForces: dto.workForces,
    description: dto.description,
    ownerId: dto.ownerId,
    categoryId: dto.categoryId,
    departmentId: dto.departmentId,
    activityId: dto.activityId,
    dimensionAmount: dto.dimensionAmount,
    dimensionUnitId: dto.dimensionUnitId,
    equipmentId: dto.equipmentId,
    equipmentTypeId: dto.equipmentTypeId,
    objectTypeId: dto.objectTypeId,
    productionPerHour: dto.productionPerHour,
    productionUnitId: dto.productionUnitId,
  } as ProductionKeyFigureDto;
}

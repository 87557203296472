import {
  GridColDef,
  GridColumnGroupingModel,
  GridValueOptionsParams,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import themeVariables from "../../../styles/theme-variables.module.scss";
import { EquipmentKeyFigureDto } from "../../../interfaces/aggregate/EquipmentKeyFigureDto";
import useEquipmentKeyFiguresStore from "../../../state/DatabaseState/equipmentKeyFiguresState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { AutoComplete } from "../components/AutoComplete/AutoComplete";
import { getOptionKey } from "../../../utils/HelperFunctions";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";

export const useColumnGroupingModel = (): GridColumnGroupingModel => {
  const { t } = useTranslation();

  return [
    {
      groupId: t("Element data"),
      children: [
        { field: "description" },
        { field: "equipmentType" },
        { field: "brand" },
        { field: "brandType" },
        { field: "fuel" },
      ],
    },
    {
      groupId: t("Production data"),
      children: [
        { field: "fuelConsumption" },
        { field: "adBlueConsumption" },
        { field: "stageClass" },
        { field: "powerInKw" },
        { field: "loadPercentage" },
        { field: "hvoSuitable" },
        { field: "manufacturedYear" },
        { field: "carbonEmission" },
      ],
    },
  ];
};

export const GridColDefComponent = (pageType?: TypeObject | undefined) => {
  const { t } = useTranslation();
  const { brands, equipmentTypes, equipmentKeyFigures } =
    useEquipmentKeyFiguresStore();
  const { isEditMode } = useDatabaseStore();
  const { dataGridColumnWidth } = themeVariables;

  const fuelOptions: { [key: string]: string } = {
    Diesel: t("Diesel"),
    DieselAdBlue: t("DieselAdBlue"),
    Gasoline: t("Gasoline"),
    Hydrogen: t("Hydrogen"),
    Electric: t("Electric"),
  };
  const stageClassOptions: { [key: string]: string } = {
    S1: t("S1"),
    S2: t("S2"),
    S3A: t("S3A"),
    S3B: t("S3B"),
    S4: t("S4"),
    S5: t("S5"),
    SElectric: t("SElectric"),
  };

  const columnDefs: GridColDef[] = useMemo(
    () => [
      {
        field: "equipmentType",
        headerName: t("EquipmentType"),
        editable: isEditMode,
        type: "singleSelect",
        width: Number(dataGridColumnWidth),
        getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
        getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
        renderCell: (params) => {
          const equipmentKeyFigure = params.row as EquipmentKeyFigureDto;
          if (equipmentKeyFigure.id === 0) {
            return "";
          }

          if (pageType === TypeObject.NitrogenAnalyser) {
            return equipmentKeyFigure.equipmentType?.name;
          }

          const equipmentKeyFiguresWithSameType = equipmentKeyFigures.filter(
            (x) =>
              x.equipmentType?.name ===
                equipmentKeyFigure?.equipmentType?.name && x.id !== 0
          );

          if (equipmentKeyFiguresWithSameType.length === 1) {
            return equipmentKeyFigure.equipmentType?.name;
          }

          const index = Math.floor(equipmentKeyFiguresWithSameType.length / 2);
          if (
            equipmentKeyFiguresWithSameType[index]?.id ===
            equipmentKeyFigure?.id
          ) {
            return equipmentKeyFigure.equipmentType?.name;
          } else return "";
        },
        renderEditCell: (params) => {
          return (
            <AutoComplete
              params={params}
              renderOptions={equipmentTypes.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
            />
          );
        },
      },
      {
        field: "description",
        headerName: t("Description"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
      {
        field: "brand",
        headerName: t("Brand"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
        type: "singleSelect",
        getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
        getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
        valueFormatter(value, row) {
          return row.brand?.name;
        },
        valueOptions: brands.sort((a, b) => a.name.localeCompare(b.name)),
        renderEditCell: (params) => {
          return (
            <AutoComplete
              params={params}
              renderOptions={brands.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
            />
          );
        },
      },
      {
        field: "brandType",
        width: Number(dataGridColumnWidth),
        headerName: t("BrandType"),
        editable: isEditMode,
      },
      {
        field: "fuel",
        headerName: t("Fuel"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
        type: "singleSelect",
        valueGetter: (value: string) => {
          return value;
        },
        valueFormatter: (params, row) =>
          t(fuelOptions[row?.value] || row?.value),
        renderCell: (params) => t(fuelOptions[params?.value] || params?.value),
        renderEditCell: (params) => (
          <AutoComplete
            params={params}
            onChange={(event, newValue) => {
              params.api.setEditCellValue(
                {
                  id: params.id,
                  field: params.field,
                  value: getOptionKey(newValue ?? "", fuelOptions),
                },
                event
              );
            }}
            renderOptions={Object.entries(fuelOptions).map(
              ([value, label]) => label
            )}
          />
        ),
      },
      {
        field: "fuelConsumption",
        headerName: `${t("Fuel consumption")} (l/h)`,
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
      {
        field: "adBlueConsumption",
        headerName: `${t("AdBlue consumption")} (l/h)`,
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
      {
        field: "stageClass",
        headerName: t("StageClass"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
        type: "singleSelect",
        valueGetter: (value: string) => {
          return value;
        },
        valueFormatter: (params, row) =>
          t(stageClassOptions[row?.value] || row?.value),
        renderCell: (params) =>
          t(stageClassOptions[params?.value] || params?.value),
        renderEditCell: (params) => (
          <AutoComplete
            params={params}
            onChange={(event, newValue) => {
              params.api.setEditCellValue(
                {
                  id: params.id,
                  field: params.field,
                  value: getOptionKey(newValue ?? "", stageClassOptions),
                },
                event
              );
            }}
            renderOptions={Object.entries(stageClassOptions).map(
              ([value, label]) => label
            )}
          />
        ),
      },
      {
        field: "powerInKw",
        headerName: t("PowerInkW"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
      {
        field: "loadPercentage",
        headerName: t("LoadPercentage"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
        renderCell: (params) => {
          if (params.cellMode === "view") {
            return params.row.loadPercentage
              ? `${params.row.loadPercentage}%`
              : "";
          }
          return params.row.loadPercentage;
        },
      },
      {
        field: "hvoSuitable",
        headerName: t("HVOSuitable"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
        type: "singleSelect",
        valueGetter(value, row) {
          return row.hvoSuitable
            ? t("Yes")
            : row.hvoSuitable !== undefined
            ? t("No")
            : "";
        },
        valueOptions: [t("Yes"), t("No")],
      },
      {
        field: "manufacturedYear",
        headerName: t("ManufacturedYear"),
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
      {
        field: "carbonEmission",
        headerName: `${t("CarbonEmission")} (kg/l)`,
        editable: isEditMode,
        width: Number(dataGridColumnWidth),
      },
    ],
    [
      brands,
      equipmentTypes,
      equipmentKeyFigures,
      dataGridColumnWidth,
      t,
      isEditMode,
    ]
  );

  return columnDefs;
};

import { forgeApiClient } from "../../sharedLogic/services/AxiosClient";
import {
  ExploreActions,
  ExploreState,
} from "../../sharedLogic/state/ExploreState/ExploreState";
import useProjectsStore from "../../sharedLogic/state/projectsState";
import { ProjectForgeDto } from "../../sharedLogic/types/api";
import { TypeObject } from "../../sharedLogic/types/enums";
import { UnitType } from "../../sharedLogic/types/UnitType";
import {
  convertCentsToEuro,
  convertMilimetersToMeters,
  convertSquareMilimetersToSquareMeters,
} from "../../sharedLogic/utils/format";
import {
  Layout,
  SpaceBuildingDto,
  SpaceCodeCounts,
  SpaceDto,
} from "../types/api";
import { getColor } from "../utils/colors";

const baseUrl = "/api/v1";
export async function GetSpaceBuilding(
  buildingCode: string
): Promise<SpaceBuildingDto> {
  try {
    const response = await forgeApiClient.get<SpaceBuildingDto>(
      `${baseUrl}/buildings/${buildingCode}`
    );
    const currentProject = useProjectsStore.getState()
      .currentProject as ProjectForgeDto;

    const building = MapSpaceBuilding(
      response.data,
      currentProject?.plotAreaInMm2
    );

    building.spaceCodeCounts = GetSpaceCodeCounts(building.layout);
    building.layout?.floorPlans?.forEach((floorPlan) => {
      floorPlan?.elements?.forEach((element) => {
        element.color = getColor(element.spaceType);
      });
    });

    var unitMix = [] as UnitType[];
    building.residentialInformation?.forEach((residentialInfo) => {
      unitMix.push({
        name: residentialInfo.moduleType,
        color: getColor(residentialInfo.moduleType),
        count: residentialInfo.count,
        ratio: residentialInfo.ratio,
      } as UnitType);
    });
    building.unitMix = unitMix;

    return building;
  } catch (error) {
    throw error;
  }
}

export const GetAllSpaceBuildingsShortInfo = async (
  currentState: ExploreState<SpaceDto> & ExploreActions<SpaceDto>,
  projectExternalId: string
): Promise<any> => {
  try {
    const response = await forgeApiClient.get<SpaceBuildingDto[]>(
      `${baseUrl}/projects/${projectExternalId}/buildings`
    );

    const currentProject = useProjectsStore.getState()
      .currentProject as ProjectForgeDto;

    const buildingsresult = response.data.map(
      (building: SpaceBuildingDto, index: number) => {
        return {
          ...MapSpaceBuilding(building, currentProject.plotAreaInMm2),
          id: index,
          investmentValue: 0,
          investmentValuePerGo: 0,
          co2: 0,
          costsVSRevenues: 0,
          renderType: "image" as "chart" | "image" | "other",
        };
      }
    );
    currentState?.setObjectsTotalCount(buildingsresult.length);
    return buildingsresult;
  } catch (error) {
    throw error;
  }
};

function relevantImageSrc(accessType: string): string {
  switch (accessType) {
    case "HSAGallery":
      return "/assets/buildings/galleryMatrix.png";
    case "HSACorridor":
      return "/assets/buildings/corridorMatrix.png";
    case "HSAPorchway":
      return "/assets/buildings/porchMatrix.png";
    default:
      return "none";
  }
}

function MapSpaceBuilding(
  building: SpaceBuildingDto,
  plotAreaInMm2?: number | undefined
): SpaceBuildingDto {
  const convertedBuilding = {
    ...building,
    renderType: "image",
    typeObject: TypeObject.HSABuilding,
    dimensions: {
      xSizeInM: convertMilimetersToMeters(building.dimensions?.xSizeInMm),
      ySizeInM: convertMilimetersToMeters(building.dimensions?.ySizeInMm),
      zSizeInM: convertMilimetersToMeters(building.dimensions?.zSizeInMm),
    },
    structuralHeightInM: convertMilimetersToMeters(
      building.dimensions.zSizeInMm
    ),
    functionalHeightInM: convertMilimetersToMeters(
      building.functionalHeightInMm
    ),
    lengthInM: convertMilimetersToMeters(building.dimensions.xSizeInMm),
    grossFloorAreaLivingInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaLivingInMm2
    ),
    grossFloorAreaOutdoorInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaOutdoorInMm2
    ),
    grossFloorAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFloorAreaInMm2
    ),
    grossFacadeAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFacadeAreaInMm2
    ),
    grossRoofAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossRoofAreaInMm2
    ),
    grossFootprintAreaInM2: convertSquareMilimetersToSquareMeters(
      building.grossFootprintAreaInMm2
    ),
    usableAreaInM2: convertSquareMilimetersToSquareMeters(
      building.usableAreaInMm2
    ),
    costInEuro: convertCentsToEuro(building.costInEuroCents),
    view2d_png: building.thumbnailUrl ?? relevantImageSrc(building.accessType),
  } as SpaceBuildingDto;

  convertedBuilding.goBvo =
    convertedBuilding.usableAreaInM2 /
    convertedBuilding.grossFloorAreaInM2;
  convertedBuilding.costInEuroPerGo =
    convertedBuilding.costInEuro / convertedBuilding.usableAreaInM2;
  convertedBuilding.fsi =
    plotAreaInMm2 !== undefined
      ? plotAreaInMm2 / convertedBuilding.grossFloorAreaInM2
      : 0;

  return convertedBuilding;
}

function GetSpaceCodeCounts(layout: Layout): SpaceCodeCounts[] {
  const spaceCodeCounts: SpaceCodeCounts[] = [];

  for (const floorPlan of layout.floorPlans) {
    for (const element of floorPlan.elements) {
      const existingCount = spaceCodeCounts.find(
        (dto) =>
          dto.spaceCode !== "GBGC01" &&
          element.spaceCode !== "GVHO01" &&
          dto.spaceCode === element.spaceCode &&
          dto.codeBusiness === element.codeBusiness
      );
      if (existingCount) {
        existingCount.count++;
      } else {
        if (element.spaceCode !== "GBGC01" && element.spaceCode !== "GVHO01")
          spaceCodeCounts.push({
            count: 1,
            spaceCode: element.spaceCode,
            codeBusiness: element.codeBusiness,
          });
      }
    }
  }

  return spaceCodeCounts;
}

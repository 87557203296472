import useInputPaneStore from "../../../../../state/DatacenterState/inputPaneState";
import useGeneralDatacenterObjectsStore from "../../../../../state/DatacenterState/generalDatacenterObjectsState";
import WhitespaceDto from "../../../../../interfaces/foundry/WhitespaceDto";
import SummaryInfoDto from "../../../../../interfaces/SummaryInfoDto";
import { createExploreStore } from "../../../../../state/ExploreState/ExploreState";
import { useMemo } from "react";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../../../interfaces/exploreConfig";
import ClickableAxesDto from "../../../../../interfaces/ClickableAxisDto";

export const useWhitespaceConfigStore =
  (): ExploreConfigStore<WhitespaceDto> => {
    const {
      powerWhitespaces,
      powerRack,
      coolingPrinciple,
      typeDataCentre,
      buildingType,
    } = useInputPaneStore();

    const { desiredPowerOptions } = useGeneralDatacenterObjectsStore();

    const currentStore = useMemo(() => createExploreStore<WhitespaceDto>(), []);
    const {
      setLoadedObjects,
      setFilteredObjects,
      setFilteredObjectsWithCoolingTypes,
      brushedObjects,
    } = currentStore();

    const sortByOptionsDictionary: { [key: string]: string } = {
      "Best fit rate": "value",
      "kw/m²": "kw_m2",
      "kw/m³": "kw_m3",
      "Cost value": "cost_value",
      "Construction time": "construction_time",
      "Whitespace cooling ratio": "cooling_area_effectivity",
    };

    const dimensions = [
      "true_power_in_w",
      "rack_power_in_w",
      "rack_amount",
      "length",
      "width",
      "height",
      "area",
      "volume",
      "cooling_area_effectivity",
      "kw_m2",
      "kw_m3",
    ];
    const chartLabels = [
      "Available IT Power",
      "Power / cab",
      "Cab count",
      "Net width(m)",
      "Net length(m)",
      "Net height(m)",
      "Area(m²)",
      "Volume(m³)",
      "Whitespace cooling ratio",
      "kW/m²",
      "kW/m³",
    ];
    const clickableAxes: ClickableAxesDto[] = [
      { axis: "cooling_area_effectivity", isColorReversed: false },
      { axis: "kw_m2", isColorReversed: false },
      { axis: "kw_m3", isColorReversed: false },
    ];
    const filterControlOptionsDictionary = {
      "kW/m²": "kw_m2",
      "kW/m³": "kw_m3",
    };
    const filterControlOptionsDictionaryReversed = {
      kw_m2: "kW/m²",
      kw_m3: "kW/m³",
    };

    const defaultSelectedAxes = ["kw_m2"];

    const summaryInfo: SummaryInfoDto[] = [
      { label: "IT Power", property: "true_power_in_w", unit: "MW" },
      { label: "Cooling type", property: "cooling_type_string", unit: "" },
      { label: "Power / cab", property: "rack_power_in_w", unit: "" },
    ];

    const tempPowerWhitespaces = desiredPowerOptions.filter(
      (power) => powerWhitespaces[0] <= power && powerWhitespaces[1] >= power
    );

    const filterConditions: FilterCondition<WhitespaceDto>[] = [
      {
        key: "desired_power_in_w",
        condition: (value) => tempPowerWhitespaces.includes(value),
      },
      {
        key: "building_types",
        condition: (value) =>
          !buildingType.length ||
          buildingType.some((bt) =>
            bt && bt.id !== -1
              ? value.some((wsBt: { id: number }) => wsBt.id === bt.id)
              : value.length === 0
          ),
      },
      {
        key: "rack_power_in_w",
        condition: (value) => !powerRack.length || powerRack.includes(value),
      },
      {
        key: "scope",
        condition: (value) =>
          typeof typeDataCentre === "string" &&
          value === typeDataCentre.toUpperCase(),
      },
      {
        key: "cooling_type_string",
        condition: (value) =>
          !coolingPrinciple.length || coolingPrinciple.includes(value),
      },
    ];

    const handleObjectFilters = (objects: WhitespaceDto[]) => {
      const whiteSpaces = objects as WhitespaceDto[];
      const tempPowerWhitespaces = [] as number[];
      desiredPowerOptions.forEach((power) => {
        if (powerWhitespaces[0] <= power && powerWhitespaces[1] >= power) {
          tempPowerWhitespaces.push(power);
        }
      });

      const filteredWhitespaces = whiteSpaces.filter(
        (whiteSpace) =>
          tempPowerWhitespaces.includes(whiteSpace.desired_power_in_w) &&
          (!buildingType.length ||
            buildingType.some((bt) =>
              bt && bt.id !== -1
                ? whiteSpace.building_types.some((wsBt) => wsBt.id === bt.id)
                : whiteSpace.building_types.length === 0
            )) &&
          (!powerRack.length ||
            powerRack.includes(whiteSpace.rack_power_in_w)) &&
          (typeDataCentre === "" ||
            (typeDataCentre &&
              whiteSpace.scope === typeDataCentre.toUpperCase()))
      );

      const filteredWhitespacesByCoolingType = filteredWhitespaces.filter(
        (whiteSpace) => {
          return (
            !coolingPrinciple.length ||
            coolingPrinciple.includes(whiteSpace.cooling_type_string)
          );
        }
      );

      const tempFilteredWhitespaces = [...filteredWhitespaces].filter(
        (whiteSpace) => {
          return (
            !coolingPrinciple.length ||
            coolingPrinciple.includes(whiteSpace.cooling_type_string)
          );
        }
      );
      if (tempFilteredWhitespaces.length === 0) {
        setLoadedObjects([]);
      }

      setFilteredObjects(filteredWhitespaces);
      setFilteredObjectsWithCoolingTypes(filteredWhitespacesByCoolingType);

      return filteredWhitespacesByCoolingType;
    };

    function handleCustomFilter(): WhitespaceDto[] {
      const tempWhitespaces = [...brushedObjects] as WhitespaceDto[];
      const filteredTempWhitespaces = tempWhitespaces.filter((whiteSpace) => {
        return (
          !coolingPrinciple.length ||
          coolingPrinciple.includes(whiteSpace.cooling_type_string)
        );
      });

      return filteredTempWhitespaces;
    }

    return {
      sortByOptionsDictionary,
      filterConditions,
      currentStore,
      summaryInfo,
      dimensions,
      chartLabels,
      clickableAxes,
      filterControlOptionsDictionary,
      filterControlOptionsDictionaryReversed,
      handleObjectFilters,
      handleCustomFilter,
      defaultSelectedAxes,
    };
  };

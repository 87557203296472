import { FormControl } from "@mui/material";
import React from "react";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";

interface LabeledFieldProps {
  className?: string;
  labelId: string;
  labelText: string;
  tooltipText?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const LabeledField: React.FC<LabeledFieldProps> = ({
  className = "",
  labelId,
  labelText,
  tooltipText,  
  style = {},
  children,
}) => {
  return (
    <div className={className}>
      <FormControl
        fullWidth
        variant="standard"
        style={{ marginTop: "10px", ...style }}
      >
        <CustomInputLabel
          id={labelId}
          showInfoButton={tooltipText !== undefined}
          labelText={labelText}
          tooltipText={tooltipText}
        />
        <div className="mt10"></div>
        {children}
      </FormControl>
    </div>
  );
};

export default LabeledField;

import { useMemo } from "react";
import SummaryInfoDto from "../../../../../interfaces/SummaryInfoDto";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../../../interfaces/exploreConfig";
import { DatacenterDto } from "../../../../../interfaces/foundry/DatacenterDto";
import useInputPaneStore from "../../../../../state/DatacenterState/inputPaneState";
import { createExploreStore } from "../../../../../state/ExploreState/ExploreState";
import ClickableAxesDto from "../../../../../interfaces/ClickableAxisDto";

export const useDatacenterConfigStore =
  (): ExploreConfigStore<DatacenterDto> => {
    const {
      autonomyInMin,
      tranfoType,
      powerWhitespaces,
      powerRack,
      coolingPrinciple,
      destinationPlan,
      desiredITCapability,
      maxBuildHeight,
      adminBuilding,
      waterTreatment,
      sprinklerBasin,
      purchasingStation,
    } = useInputPaneStore();

    const currentStore = useMemo(() => createExploreStore<DatacenterDto>(), []);
    const {
      brushedObjects,
      setFilteredObjects,
      setFilteredObjectsWithCoolingTypes,
      setLoadedObjects,
    } = currentStore();

    const sortByOptionsDictionary: { [key: string]: string } = {
      "Best fit rate": "value",
    };

    const defaultSelectedAxes = ["kw_m2", "kw_m3"];

    const summaryInfo: SummaryInfoDto[] = [
      { label: "IT Power", property: "true_power_in_w", unit: "MW" },
      { label: "Cooling type", property: "whitespace_cooling_type", unit: "" },
      {
        label: "Power/Whitespace",
        property: "whitespace_true_power_in_w",
        unit: "MW",
      },
    ];

    const dimensions = [
      "true_power_in_w",
      "whitespace_true_power_in_w",
      "rack_type_watts",
      "whitespace_amount",
      "building_height_mm",
      "building_layers",
      "ground_footprint",
      "total_bvo",
      "kw_m2",
      "kw_m3",
      "cost",
      "cost_per_kw",
      "construction_time_in_months",
      "co2_footprint_in_euros",
    ];
    const chartLabels = [
      "DatacenterPage.TruePower",
      "DatacenterPage.ITPowerWhitespace",
      "DatacenterPage.ITPowerPerRack",
      "DatacenterPage.WhitespaceAmount",
      "DatacenterPage.BuildingHeight",
      "DatacenterPage.NumberOfFloors",
      "DatacenterPage.GroundFootprint",
      "DatacenterPage.GroundFootprintTotal",
      "kW/m²",
      "kW/m³",
      "CostsCurrency",
      "DatacenterPage.CostsPerKw",
      "DatacenterPage.ConstructionTime",
      "DatacenterPage.CarbonFootprint",
    ];
    const clickableAxes: ClickableAxesDto[] = [
      { axis: "rack_type", isColorReversed: false },
      { axis: "whitespace_cooling_efficiency", isColorReversed: false },
      { axis: "total_bvo", isColorReversed: true },

      { axis: "kw_m3", isColorReversed: false },

      { axis: "ground_footprint", isColorReversed: true },
      { axis: "cost", isColorReversed: true },
      { axis: "cost_per_kw", isColorReversed: true },
      { axis: "construction_time_in_months", isColorReversed: true },
      { axis: "co2_footprint_in_euros", isColorReversed: true },
    ];

    const filterControlOptionsDictionary = {
      "DatacenterPage.TruePower": "true_power_in_w",
      "DatacenterPage.ITPowerWhitespace": "whitespace_true_power_in_w",
      "DatacenterPage.ITPowerPerRack": "rack_type_watts",
      "DatacenterPage.WhitespaceAmount": "whitespace_amount",
      "DatacenterPage.BuildingHeight": "building_height_mm",
      "DatacenterPage.NumberOfFloors": "building_layers",
      "DatacenterPage.GroundFootprint": "ground_footprint",
      "DatacenterPage.GroundFootprintTotal": "total_bvo",
      CostsCurrency: "cost",
      "DatacenterPage.CostsPerKw": "cost_per_kw",
      "DatacenterPage.ConstructionTime": "construction_time_in_months",
      "DatacenterPage.CarbonFootprint": "co2_footprint_in_euros",
      "kW/m²": "kw_m2",
      "kW/m³": "kw_m3",
    };
    const filterControlOptionsDictionaryReversed = {
      true_power_in_w: "DatacenterPage.TruePower",
      whitespace_true_power_in_w: "DatacenterPage.ITPowerWhitespace",
      rack_type_watts: "DatacenterPage.ITPowerPerRack",
      whitespace_amount: "DatacenterPage.WhitespaceAmount",
      whitespace_cooling_efficiency: "DatacenterPage.WhitespaceCoolingRatio",
      building_height_mm: "DatacenterPage.BuildingHeight",
      building_layers: "DatacenterPage.NumberOfFloors",
      ground_footprint: "DatacenterPage.GroundFootprint",
      total_bvo: "DatacenterPage.GroundFootprintTotal",
      cost: "CostsCurrency",
      cost_per_kw: "DatacenterPage.CostsPerKw",
      construction_time_in_months: "DatacenterPage.ConstructionTime",
      co2_footprint_in_euros: "DatacenterPage.CarbonFootprint",
      kw_m2: "kW/m²",
      kw_m3: "kW/m³",
    };

    const filterConditions: FilterCondition<DatacenterDto>[] = [
      {
        key: "admin_amount",
        condition: (value) =>
          (!adminBuilding && value === 0) ||
          (typeof value === "number" && adminBuilding && value > 0),
      },
      {
        key: "watertreatment_amount",
        condition: (value) =>
          (!waterTreatment && value === 0) ||
          (typeof value === "number" && waterTreatment && value > 0),
      },
      {
        key: "sprinklerbassin_amount",
        condition: (value) =>
          (!sprinklerBasin && value === 0) ||
          (typeof value === "number" && sprinklerBasin && value > 0),
      },
      {
        key: "energysupplystation_amount",
        condition: (value) =>
          (!purchasingStation && value === 0) ||
          (typeof value === "number" && purchasingStation && value > 0),
      },
      {
        key: "destination_plan",
        condition: (value) =>
          !destinationPlan || destinationPlan?.toUpperCase() === value,
      },
      {
        key: "desired_power_in_w",
        condition: (value) =>
          typeof value === "number" &&
          (!desiredITCapability ||
            value === desiredITCapability ||
            value === desiredITCapability - 2 ||
            value === desiredITCapability + 2),
      },
      {
        key: "building_height_mm",
        condition: (value) =>
          !maxBuildHeight ||
          (typeof value === "number" && value <= maxBuildHeight),
      },
      {
        key: "rack_type_watts",
        condition: (value) =>
          powerRack.length === 0 ||
          (typeof value === "number" && powerRack.includes(value)),
      },
      {
        key: "whitespace_true_power_in_w",
        condition: (value) =>
          powerWhitespaces.length === 0 ||
          (typeof value === "number" &&
            powerWhitespaces[0] !== 0 &&
            powerWhitespaces[1] !== 0 &&
            value >= powerWhitespaces[0] &&
            value <= powerWhitespaces[1]),
      },
      {
        key: "lsr_autonomy_in_min",
        condition: (value) => autonomyInMin === null || value === autonomyInMin,
      },
      {
        key: "lsr_trafo_type",
        condition: (value) => {
          const tranfoTypesId = tranfoType.map((type) => type.id);
          return (
            tranfoType.length === 0 ||
            (typeof value === "number" && tranfoTypesId.includes(value))
          );
        },
      },
      {
        key: "whitespace_cooling_type",
        condition: (value) =>
          coolingPrinciple.length === 0 ||
          (typeof value === "string" && coolingPrinciple.includes(value)),
      },
    ];

    function handleObjectFilters(objects: DatacenterDto[]) {
      let filteredDatacenters = objects as DatacenterDto[];

      filteredDatacenters = filteredDatacenters.filter(
        (datacenter) =>
          ((!adminBuilding && datacenter.admin_amount === 0) ||
            (adminBuilding && datacenter.admin_amount > 0)) &&
          ((!waterTreatment && datacenter.watertreatment_amount === 0) ||
            (waterTreatment && datacenter.watertreatment_amount > 0)) &&
          ((!sprinklerBasin && datacenter.sprinklerbassin_amount === 0) ||
            (sprinklerBasin && datacenter.sprinklerbassin_amount > 0)) &&
          ((!purchasingStation &&
            datacenter.energysupplystation_amount === 0) ||
            (purchasingStation && datacenter.energysupplystation_amount > 0)) &&
          (!destinationPlan ||
            destinationPlan?.toUpperCase() === datacenter.destination_plan) &&
          (!desiredITCapability ||
            datacenter.desired_power_in_w === desiredITCapability ||
            datacenter.desired_power_in_w === desiredITCapability - 2 ||
            datacenter.desired_power_in_w === desiredITCapability + 2) &&
          (!maxBuildHeight || datacenter.building_height_mm <= maxBuildHeight)
      );

      if (powerRack.length !== 0) {
        filteredDatacenters = filteredDatacenters.filter((datacenter) => {
          return (
            datacenter.rack_type_watts &&
            powerRack.includes(datacenter.rack_type_watts)
          );
        });
      }

      if (
        powerWhitespaces.length !== 0 &&
        powerWhitespaces[0] !== 0 &&
        powerWhitespaces[1] !== 0
      ) {
        filteredDatacenters = filteredDatacenters.filter((datacenter) => {
          return (
            datacenter.whitespace_true_power_in_w >= powerWhitespaces[0] &&
            datacenter.whitespace_true_power_in_w <= powerWhitespaces[1]
          );
        });
      }

      if (autonomyInMin !== null) {
        filteredDatacenters = filteredDatacenters.filter((datacenter) => {
          return datacenter.lsr_autonomy_in_min === autonomyInMin;
        });
      }

      if (tranfoType.length !== 0) {
        const tranfoTypesId = tranfoType.map((type) => type.id);
        filteredDatacenters = filteredDatacenters.filter((datacenter) => {
          return tranfoTypesId.includes(datacenter.lsr_trafo_type);
        });
      }

      setFilteredObjects(filteredDatacenters);

      if (coolingPrinciple.length !== 0) {
        let filteredDatacentersByCoolingType: DatacenterDto[] = [];
        filteredDatacentersByCoolingType = filteredDatacenters.filter(
          (datacenter) => {
            return coolingPrinciple.includes(
              datacenter.whitespace_cooling_type
            );
          }
        );

        setFilteredObjectsWithCoolingTypes(filteredDatacentersByCoolingType);
        if (filteredDatacentersByCoolingType.length === 0) {
          setLoadedObjects([]);
        }
      } else {
        setFilteredObjectsWithCoolingTypes(filteredDatacenters);
      }

      return filteredDatacenters;
    }
    function handleCustomFilter(): DatacenterDto[] {
      const tempWhitespaces = [...brushedObjects] as DatacenterDto[];
      return tempWhitespaces;
    }

    return {
      sortByOptionsDictionary,
      filterConditions,
      currentStore,
      summaryInfo,
      dimensions,
      chartLabels,
      clickableAxes,
      filterControlOptionsDictionary,
      filterControlOptionsDictionaryReversed,
      handleObjectFilters,
      handleCustomFilter,
      defaultSelectedAxes,
    };
  };

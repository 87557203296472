import { create } from "zustand";
import { BaseDatabaseOptionDto } from "../../interfaces/aggregate/BaseDatabaseOptionDto";
import TreeNodeDto from "../../interfaces/aggregate/TreeNodeDto";
import { CategoryDto } from "../../interfaces/aggregate/CategoryDto";
import { GridInitialState, GridScrollParams } from "@mui/x-data-grid";
import { DepartmentDto } from "../../interfaces/aggregate/DepartmentDto";
import { InputDataCatalogEnum } from "../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { AggregatePageEnum } from "../../interfaces/aggregate/enums/AggregatePageEnum";
import { OwnerDto } from "../../interfaces/aggregate/OwnerDto";

interface DatabaseState {
  currentPage: AggregatePageEnum;
  options: InputDataCatalogEnum[];
  isEditMode: boolean;
  isAdmin: boolean;
  expandedNodes: string[];
  selectedNode: string;
  categories: CategoryDto[];
  departments: DepartmentDto[];
  owners: OwnerDto[];
  treeNodeData: TreeNodeDto[];
  isInputPaneOpen: boolean;
  currentDatabaseOptions: BaseDatabaseOptionDto[];
  currentTreeNodeData: TreeNodeDto;
  gridState: GridInitialState;
  scrollPosition: GridScrollParams;
}

interface DatabaseStateActions {
  setCurrentPage(value: AggregatePageEnum): void;
  setOptions: (value: InputDataCatalogEnum[]) => void;
  setIsEditMode: (value: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentDatabaseOptions: (value: BaseDatabaseOptionDto[]) => void;
  setTreeNodeData: (newValue: TreeNodeDto[]) => void;
  setDepartments: (value: DepartmentDto[]) => void;
  setOwners: (value: OwnerDto[]) => void;
  setExpandedNodes: (value: string[]) => void;
  setSelectedNode: (value: string) => void;
  setCategories: (value: CategoryDto[]) => void;
  setCurrentTreeNodeData: (value: TreeNodeDto) => void;
  setGridState: (value: GridInitialState) => void;
  setScrollPosition: (value: GridScrollParams) => void;
  resetDatabaseState: () => void;
}

const initialState: DatabaseState = {
  currentPage: AggregatePageEnum.CostKeyFigure,
  options: [],
  isEditMode: false,
  isInputPaneOpen: true,
  currentDatabaseOptions: [],
  expandedNodes: [],
  selectedNode: "",
  treeNodeData: [],
  categories: [],
  departments: [],
  owners: [],
  currentTreeNodeData: {
    id: "",
    label: "",
    children: [],
  },
  isAdmin: false,
  scrollPosition: { left: 0, top: 0 },
  gridState: { pagination: { paginationModel: { pageSize: 100 } } },
};

const useDatabaseStore = create<DatabaseState & DatabaseStateActions>()(
  (set) => ({
    ...initialState,
    setCurrentPage: (value) => set({ currentPage: value }),
    setTreeNodeData: (newValue) => set({ treeNodeData: newValue }),
    setExpandedNodes: (newValue) => set({ expandedNodes: newValue }),
    setSelectedNode: (newValue) => set({ selectedNode: newValue }),
    setOptions: (value) => set({ options: value }),
    setIsEditMode: (value) => set({ isEditMode: value }),
    setIsInputPaneOpen: (value) => set({ isInputPaneOpen: value }),
    setCurrentDatabaseOptions: (value) =>
      set({ currentDatabaseOptions: value }),
    setCategories: (value) => set({ categories: value }),
    setDepartments: (value) => set({ departments: value }),
    setOwners: (value) => set({ owners: value }),
    setCurrentTreeNodeData: (value) => set({ currentTreeNodeData: value }),
    setGridState: (value) => set({ gridState: value }),
    setScrollPosition: (value) => set({ scrollPosition: value }),
    resetDatabaseState: () => set({ ...initialState }),
  })
);

export default useDatabaseStore;

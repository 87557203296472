import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreApi, UseBoundStore } from "zustand";
import CustomInputLabel from "../../../../../components/InputLabel/CustomInputLabel";
import SliderWithMarks from "../../../../../components/SliderWithMarks/SliderWithMarks";
import MapViewInputPane from "../../../../sharedLogic/components/InputPane/MapView/MapViewInputPane";
import BooleanField from "../../../../sharedLogic/components/PropertySectionView/BooleanField";
import LabeledField from "../../../../sharedLogic/components/PropertySectionView/LabeledField";
import NumberField from "../../../../sharedLogic/components/PropertySectionView/NumberField";
import SelectField from "../../../../sharedLogic/components/PropertySectionView/SelectField";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../../sharedLogic/state/baseConfiguratorState";
import { createExploreStore } from "../../../../sharedLogic/state/ExploreState/ExploreState";
import { ProjectForgeDto } from "../../../../sharedLogic/types/api";
import { UnitType } from "../../../../sharedLogic/types/UnitType";
import {
  convertMilimetersToMeters,
  convertSquareMetersToSquareMilimeters,
  convertSquareMilimetersToSquareMeters,
  parseNumberInput,
} from "../../../../sharedLogic/utils/format";
import { HsaConfiguratorMapViewInputPaneState } from "../../../state/hsaConfiguratorMapViewInputPaneState";
import { SpaceBuildingDto } from "../../../types/api";
import { SpaceType } from "../../../types/enums";
import { getColor } from "../../../utils/colors";
import UnitMix from "../../UnitMix/UnitMix";
import { IsStringUndefinedOrNullOrEmpty } from "../../../../sharedLogic/utils/stringUtils";

export const useMapViewConfigStore = (
  accessTypesOptions: string[],
  selectedAccessTypes: string[],
  setSelectedAccessTypes: (options: string[]) => void,
  configuratorStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >
) => {
  const { t } = useTranslation();
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );
  const [maxBuildingHeight, setMaxBuildingHeight] = useState<
    string | undefined
  >();
  const [maxGutterHeight, setMaxGutterHeight] = useState<string | undefined>();
  const [carPerHouse, setCarPerHouse] = useState<string>("0");
  const [gutterHeightError, setGutterHeightError] = useState<string>("");
  const [typedMaxGutterHeight, setTypedMaxGutterHeight] = useState<
    string | undefined
  >("");
  const [typedMaxBuildingHeight, setTypedMaxBuildingHeight] = useState<
    string | undefined
  >("");

  const [parkingCovered, setParkingCovered] = useState<boolean>(false);
  const [parkingType, setParkingType] = useState<string>("");
  const [numberOfAppartments, setNumberOfAppartments] = useState<number[]>([
    0, 250,
  ]);
  const [units, setUnits] = useState<UnitType[]>([
    {
      name: SpaceType.Appartment1K,
      color: getColor(SpaceType.Appartment1K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment2K,
      color: getColor(SpaceType.Appartment2K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment3K,
      color: getColor(SpaceType.Appartment3K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment4K,
      color: getColor(SpaceType.Appartment4K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
  ] as UnitType[]);

  const parkingOptions = [
    { label: t("StackedHousing.Garage"), value: "GarageParking" },
    { label: t("StackedHousing.NonPublic"), value: "NonPublicParking" },
  ];

  const setCurrentState = (forgeProject: ProjectForgeDto) => {
    const maxBuildingHeight = convertMilimetersToMeters(
      forgeProject.maxBuildingHeightInMm
    );
    const maxGutterHeight = convertMilimetersToMeters(
      forgeProject.maxBuildingGutterHeightInMm
    );
    const parking = forgeProject.parking
      ? forgeProject.parking
      : {
          parkingSpotPerResidentialFactor: 0,
          covered: false,
          type: "Unknown",
          nrOfParkingSpots: 0,
          parkingLotInMm2: 0,
        };

    setMaxBuildingHeight(
      maxBuildingHeight > 0 ? maxBuildingHeight.toString() : undefined
    );
    setMaxGutterHeight(
      maxGutterHeight > 0 ? maxGutterHeight.toString() : undefined
    );
    setNumberOfAppartments([
      forgeProject.residentialRange.min,
      forgeProject.residentialRange.max,
    ]);
    setCarPerHouse(parking.parkingSpotPerResidentialFactor.toString());
    setParkingCovered(parking.covered);
    setParkingType(parking.type);

    if (forgeProject.residentialInformation.length > 0) {
      setUnits((prevUnits) =>
        prevUnits.map((unit) => {
          const updatedUnit = forgeProject.residentialInformation.find(
            (info) => info.moduleType === unit.name
          );

          if (updatedUnit) {
            return {
              ...unit,
              color: getColor(updatedUnit.moduleType as SpaceType),
              minValue: 0,
              maxValue: 250,
              value: [
                convertSquareMilimetersToSquareMeters(
                  updatedUnit.minUsableAreaInMm2
                ),
                convertSquareMilimetersToSquareMeters(
                  updatedUnit.maxUsableAreaInMm2
                ),
              ],
              ratio: updatedUnit.ratio,
            };
          }

          return unit;
        })
      );
    }
  };

  const getCurrentState = (): HsaConfiguratorMapViewInputPaneState => {
    return {
      maxBuildingHeight: maxBuildingHeight,
      maxGutterHeight: maxGutterHeight,
      residentialRange: numberOfAppartments,
      residentialInformation: units
        .filter(
          (unit) =>
            !IsStringUndefinedOrNullOrEmpty(
              unit.ratio === undefined ? "" : unit.ratio!.toString()
            )
        )
        .map((unit) => ({
        moduleType: unit.name,
        ratio: unit.ratio,
        minUsableAreaInMm2: convertSquareMetersToSquareMilimeters(
          unit.value[0]
        ),
        maxUsableAreaInMm2: convertSquareMetersToSquareMilimeters(
          unit.value[1]
        ),
      })),
      carParking: {
        parkingSpotPerResidentialFactor: carPerHouse
          ? Number(parseNumberInput(carPerHouse))
          : 0,
        covered: parkingCovered ? parkingCovered : false,
        type: parkingType ? parkingType : "Unknown",
        nrOfParkingSpots: 0,
        parkingLotInMm2: 0,
      },
    } as HsaConfiguratorMapViewInputPaneState;
  };

  const inputPaneContent = () => (
    <>
      <MapViewInputPane currentConfiguratorStore={configuratorStore} />
      <LabeledField
        className="mt10"
        labelId="max-building-height-label"
        labelText={t("MapViewPage.MaxBuildingHeight")}
        tooltipText={t("MapViewPage.MaxBuildingHeightTooltip")}
      >
        <NumberField
          unit="m"
          value={typedMaxBuildingHeight || maxBuildingHeight}
          onChange={(value) => {
            setTypedMaxBuildingHeight(value);

            const newMaxBuildingHeight = parseFloat(value || "0");
            const currentMaxGutterHeight = parseFloat(
              typedMaxGutterHeight || maxGutterHeight || "0"
            );

            if (currentMaxGutterHeight > newMaxBuildingHeight) {
              setGutterHeightError(
                t("MapViewPage.MaxGutterHeightExceedsMaxBuildingHeight")
              );
            } else {
              setMaxBuildingHeight(value);
              setTypedMaxBuildingHeight("");
              if (
                typedMaxGutterHeight &&
                currentMaxGutterHeight <= newMaxBuildingHeight
              ) {
                setMaxGutterHeight(typedMaxGutterHeight);
                setTypedMaxGutterHeight("");
              }
              setGutterHeightError("");
            }
          }}
        />
      </LabeledField>

      <LabeledField
        className="mt10"
        labelId="total-gutter-height-label"
        labelText={t("MapViewPage.MaxGutterHeight")}
        tooltipText={t("MapViewPage.MaxGutterHeightTooltip")}
      >
        <NumberField
          unit="m"
          value={typedMaxGutterHeight || maxGutterHeight}
          onChange={(value) => {
            setTypedMaxGutterHeight(value);

            const newMaxGutterHeight = parseFloat(value || "0");
            const currentMaxBuildingHeight = parseFloat(
              maxBuildingHeight || "0"
            );

            if (newMaxGutterHeight > currentMaxBuildingHeight) {
              setGutterHeightError(
                t("MapViewPage.MaxGutterHeightExceedsMaxBuildingHeight")
              );
            } else {
              setMaxGutterHeight(value);
              setTypedMaxGutterHeight("");
              if (
                typedMaxBuildingHeight &&
                parseFloat(typedMaxBuildingHeight || "0") >= newMaxGutterHeight
              ) {
                setMaxBuildingHeight(typedMaxBuildingHeight);
                setTypedMaxBuildingHeight("");
              }
              setGutterHeightError("");
            }
          }}
        />
        {gutterHeightError && (
          <div className="error-message" style={{ color: "red" }}>
            {gutterHeightError}
          </div>
        )}
      </LabeledField>

      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {t("MapViewPage.ProjectRequirements")}
      </div>
      <LabeledField
        labelId="parking-standard-label"
        labelText={t("MapViewPage.ParkingStandard")}
        tooltipText={t("MapViewPage.ParkingStandardTooltip")}
      >
        <NumberField
          unit={t("MapViewPage.CarPerHouse")}
          value={carPerHouse}
          onChange={(value) => setCarPerHouse(value || "0")}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="parking-covered-label"
        labelText={t("MapViewPage.ParkingCovered")}
        tooltipText={t("MapViewPage.ParkingCoveredTooltip")}
      >
        <BooleanField
          value={parkingCovered}
          onChange={(value) => setParkingCovered(value)}
        />
      </LabeledField>
      <LabeledField
        className="mt5"
        labelId="type-parking-label"
        labelText={t("MapViewPage.ParkingType")}
        tooltipText={t("MapViewPage.ParkingTypeTooltip")}
      >
        <SelectField
          value={
            parkingOptions.find((opt) => opt.value === parkingType)?.label || ""
          }
          options={parkingOptions.map((opt) => opt.label)} // Pass only the labels
          onChange={(selectedLabel) => {
            const selectedOption = parkingOptions.find(
              (opt) => opt.label === selectedLabel
            );
            if (selectedOption) {
              setParkingType(selectedOption.value); // Set backend-friendly value
            }
          }}
        />
      </LabeledField>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {`${t("StackedHousing.BuildingBasicPrinciples")}`}
      </div>
      <LabeledField
        labelId="type-parking-label"
        labelText={t("StackedHousing.AccessType")}
        tooltipText={""}
      >
        <SelectField
          multiple={true}
          options={accessTypesOptions}
          valueMultiple={selectedAccessTypes}
          onChangeMultiple={(value) => setSelectedAccessTypes(value)}
        />
      </LabeledField>

      <LabeledField
        className="mt5"
        labelId="number-of-appartments-label"
        labelText={t("StackedHousing.NumberOfAppartments")}
        tooltipText={""}
      >
        <div className="p10">
          <SliderWithMarks
            value={numberOfAppartments}
            setValue={(value) => {
              setNumberOfAppartments(value);
            }}
            min={0}
            max={250}
          />
        </div>
      </LabeledField>

      <div className="mt20 mb40">
        <CustomInputLabel
          id="unit-mix-label"
          showInfoButton={true}
          labelText={`${t("Unit mix")} ${t("Building").toLowerCase()}`}
          tooltipText={""}
        />
        <UnitMix units={units} setUnits={setUnits} />
      </div>
    </>
  );

  return {
    currentStore,
    inputPaneContent,
    setCurrentState,
    getCurrentState,
  };
};

import { useMemo } from "react";
import ClickableAxesDto from "../../../interfaces/ClickableAxisDto";
import SummaryInfoDto from "../../../interfaces/SummaryInfoDto";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../interfaces/exploreConfig";
import { createExploreStore } from "../../../state/ExploreState/ExploreState";
import { ScenarioDto } from "../types/api";

export const useNitrogenAnalysisConfigStore =
  (): ExploreConfigStore<ScenarioDto> => {
    const currentStore = useMemo(() => createExploreStore<ScenarioDto>(), []);
    const {
      setFilteredObjects,
      setFilteredObjectsWithCoolingTypes,
      brushedObjects,
    } = currentStore();

    const sortByOptionsDictionary: { [key: string]: string } = {
      "Best fit rate": "value",
    };

    const dimensions = [
      "annualNitrogenLimitExceeded",
      "nitrogenEmissionInKg",
      "ammoniaEmissionInKg",
      "carbonEmissionInKg",
      "electricEquipmentUsageInPercentage",
      "fuelConsumptionInL",
    ];

    const chartLabels = [
      "NitrogenPage.AnnualLimitExceed",
      "NitrogenPage.TotalNitrogenEmission",
      "NitrogenPage.TotalAmmoniaEmission",
      "NitrogenPage.TotalCarbonEmission",
      "NitrogenPage.ElectricInProcent",
      "NitrogenPage.LitersOfFuel",
    ];

    const clickableAxes: ClickableAxesDto[] = [
      { axis: "annualNitrogenLimitExceeded", isColorReversed: true },
      { axis: "ammoniaEmissionInKg", isColorReversed: true },
      { axis: "nitrogenEmissionInKg", isColorReversed: true },
      { axis: "carbonEmissionInKg", isColorReversed: true },
      { axis: "electricEquipmentUsageInPercentage", isColorReversed: false },
      { axis: "fuelConsumptionInL", isColorReversed: true },
    ];

    const filterControlOptionsDictionary = {
      "NitrogenPage.AnnualLimitExceed": "annualNitrogenLimitExceeded",
      "NitrogenPage.TotalAmmoniaEmission": "ammoniaEmissionInKg",
      "NitrogenPage.TotalNitrogenEmission": "nitrogenEmissionInKg",
      "NitrogenPage.TotalCarbonEmission": "carbonEmissionInKg",
      "NitrogenPage.ElectricInProcent": "electricEquipmentUsageInPercentage",
      "NitrogenPage.LitersOfFuel": "fuelConsumptionInL",
    };
    const filterControlOptionsDictionaryReversed = {
      annualNitrogenLimitExceeded: "NitrogenPage.AnnualLimitExceed",
      ammoniaEmissionInKg: "NitrogenPage.TotalAmmoniaEmission",
      nitrogenEmissionInKg: "NitrogenPage.TotalNitrogenEmission",
      carbonEmissionInKg: "NitrogenPage.TotalCarbonEmission",
      electricEquipmentUsageInPercentage: "NitrogenPage.ElectricInProcent",
      fuelConsumptionInL: "NitrogenPage.LitersOfFuel",
    };

    const defaultSelectedAxes = ["nitrogenEmissionInKg"];

    const summaryInfo: SummaryInfoDto[] = [
      {
        label: "NitrogenPage.AnnualLimitExceed",
        property: "annualNitrogenLimitExceeded",
        unit: "",
      },
      {
        label: "NitrogenPage.LitersOfFuel",
        property: "fuelConsumptionInL",
        unit: "L",
      },
      {
        label: "NitrogenPage.ElectricInProcent",
        property: "electricEquipmentUsageInPercentage",
        unit: "",
      },
    ];

    const filterConditions: FilterCondition<ScenarioDto>[] = [
      {
        key: "typeObject",
        condition: (value) => {
          return true;
        },
      },
    ];

    const handleObjectFilters = (objects: ScenarioDto[]) => {
      const scenarios = objects;

      setFilteredObjects(objects);
      setFilteredObjectsWithCoolingTypes(objects);

      return objects;
    };

    const handleCustomFilter = () => {
      const tempScenarios = [...brushedObjects];
      return tempScenarios;
    };

    return {
      sortByOptionsDictionary,
      filterConditions,
      currentStore,
      summaryInfo,
      dimensions,
      chartLabels,
      clickableAxes,
      filterControlOptionsDictionary,
      filterControlOptionsDictionaryReversed,
      handleCustomFilter,
      handleObjectFilters,
      defaultSelectedAxes,
    };
  };

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SliderWithMarks from "../../../../components/SliderWithMarks/SliderWithMarks";

interface GenerateBuildingControlProps {
  label: string;
  value?: string[];
  sliderValue?: number[];
  color?: string;
  min?: number;
  max?: number;
  items?: string[];
  setValue?: (value: string[]) => void;
  setSliderValue?: (value: number[]) => void;
}

const GenerateBuildingControl = React.memo(
  ({
    label,
    value,
    sliderValue,
    color,
    min,
    max,
    items,
    setValue,
    setSliderValue,
  }: GenerateBuildingControlProps) => {
    const { t } = useTranslation();

    const handleChangeSelect = useCallback(
      (event: SelectChangeEvent<typeof value>) => {
        const selectedValue = event.target.value as string[];
        if (!setValue) return;

        if (
          !Array.isArray(selectedValue) ||
          selectedValue.length === 0 ||
          !items
        ) {
          setValue([]);
        } else if (selectedValue[selectedValue.length - 1] === "all") {
          const count = selectedValue.length - 1;
          setValue(count === 0 || count !== items.length ? items : []);
        } else {
          setValue(selectedValue);
        }
      },
      [items, setValue]
    );

    return (
      <Grid
        container
        columnSpacing={1}
        className="dialog-sidebar-section-property"
        sx={{ marginBottom: "12px" }}
      >
        <Grid item xs={4} className="center-center">
          {color && (
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                backgroundColor: color,
                border: "1px solid #D3D3D3",
                marginRight: 8,
              }}
            />
          )}
          {label}
        </Grid>
        <Grid item xs={1} className="center-center">
          {color ? "GO:" : ":"}
        </Grid>
        <Grid item xs={7} className="center-center">
          {items && !color ? (
            <FormControl fullWidth variant="standard">
              <Select
                style={{ height: "auto" }}
                value={value}
                onChange={(event) => {
                  setValue!([event.target.value as string]);
                }}
                label={label}
              >
                {items.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={t(name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <SliderWithMarks
              value={sliderValue!}
              setValue={setSliderValue!}
              min={min!}
              max={max!}
            />
          )}
        </Grid>
        {sliderValue && value && items && (
          <Grid item xs={12} className="center-center">
            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginTop: "10px" }}
            >
              <Select
                multiple
                value={value}
                style={{ height: "auto" }}
                onChange={handleChangeSelect}
                renderValue={(selected) => (
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                  >
                    {(selected as string[]).map((selectedValue) => (
                      <Chip
                        key={selectedValue}
                        label={t(selectedValue)}
                        onMouseDown={(event: any) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => {
                          if (!setValue) return;
                          setValue(
                            (value! as string[]).filter(
                              (v) => v !== selectedValue
                            )
                          );
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        items.length > 0 && value!.length === items.length
                      }
                      indeterminate={
                        value!.length > 0 && value!.length < items.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={t("Select all")} />
                </MenuItem>
                {items.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={value!.indexOf(name) > -1} />
                    <ListItemText primary={t(name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default GenerateBuildingControl;

import {
  Box,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo } from "react";
import CustomFormControl from "../../../../../components/CustomFormControl/CustomFormControl";
import {
  AxisConfig,
  createExploreStore,
} from "../../../../sharedLogic/state/ExploreState/ExploreState";
import {
  ExploreConfigStore,
  FilterCondition,
} from "../../../../sharedLogic/types/ExploreConfig";
import InfoField, {
  DetailedInfo,
} from "../../../../sharedLogic/types/InfoField";
import { SpaceBuildingDto } from "../../../types/api";

export const BuildingDetailedInfo: DetailedInfo[] = [
  {
    category: "General",
    fields: [
      {
        label: "StackedHousing.AccessType",
        property: "accessType",
        unit: "",
      },
      {
        label: "StackedHousing.Apartment",
        property: "nrOfAppartments",
        unit: "st",
        type: "number",
      },
      {
        label: "StackedHousing.Floors",
        property: "nrOfFloors",
        unit: "st",
        type: "number",
      },
      {
        label: "StackedHousing.UnitMix",
        property: "unitMix",
        type: "unitMix",
        unit: "",
      },
    ],
  },
  {
    category: "Dimensions",
    fields: [
      {
        label: "Length",
        property: "dimensions.xSizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "Width",
        property: "dimensions.ySizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "Height",
        property: "dimensions.zSizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "StackedHousing.BVOTotal",
        property: "grossFloorAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BVOHousing",
        property: "grossFloorAreaLivingInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BVOOutdoor",
        property: "grossFloorAreaOutdoorInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.GOHousing",
        property: "usableAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BBO",
        property: "grossFootprintAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BGO",
        property: "usableAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BDO",
        property: "grossRoofAreaInM2",
        unit: "m²",
        type: "number",
      },
    ],
  },
  {
    category: "Efficiency",
    fields: [
      {
        label: "StackedHousing.FSI",
        property: "fsi",
        unit: "-",
        type: "number",
      },
      {
        label: "StackedHousing.GO/BVO",
        property: "goBvo",
        unit: "-",
        type: "number",
      },
      {
        label: "StackedHousing.ValuePerGo",
        property: "costInEuroPerGo",
        unit: "€/m²",
        type: "number",
      },
      {
        label: "StackedHousing.ValuePerApp",
        property: "valuePerAppartment",
        unit: "€/st",
        type: "number",
      },
    ],
  },
  {
    category: "Valuation",
    fields: [
      {
        label: "StackedHousing.DirectBuildingCosts",
        property: "costInEuro",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.InvestmentValue",
        property: "investmentValue",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.RentPrice",
        property: "rentalPrice",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.CO₂",
        property: "co2Footprint",
        unit: "kg",
        type: "number",
      },
    ],
  },
  {
    category: "Components",
    fields: [
      {
        label: "Components",
        property: "spaceCodeCounts",
        type: "componentsList",
      },
    ],
  },
];

export const useBuildingConfigStore = (
  accessTypeOptions: string[],
  selectedAccessTypes: string[],
  setSelectedAccessTypes: (options: string[]) => void
): ExploreConfigStore<SpaceBuildingDto> => {
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );

  const detailedInfo = BuildingDetailedInfo;

  const {
    objects,
    brushedObjects,
    setFilteredObjects,
    setFilteredObjectsBrushedOnPCG,
  } = currentStore();

  useEffect(() => {
    if (objects !== null) {
      handleObjectFilters(objects ?? []);
    }
  }, [selectedAccessTypes]);

  const sortByOptionsDictionary: { [key: string]: string } = {
    "Best fit rate": "value",
  };

  const defaultSelectedAxes = ["nrOfFloors"];

  const summaryInfo: InfoField[] = [
    { label: "StackedHousing.AccessType", property: "accessType", unit: "" },
    { label: "Apartments", property: "nrOfAppartments", unit: "st" },
    { label: "absoluteValue", property: "costInEuro", unit: "€" },
  ];

  const dimensions = [
    { dimension: "nrOfAppartments", format: "integer" },
    { dimension: "structuralHeightInM", format: "integer" },
    { dimension: "lengthInM", format: "integer" },
    { dimension: "nrOfFloors", format: "integer" },
    { dimension: "grossFloorAreaInM2", format: "float" },
    { dimension: "grossFootprintAreaInM2", format: "float" },
    { dimension: "usableAreaInM2", format: "float" },
    { dimension: "fsi", format: "float" },
    { dimension: "goBvo", format: "float" },
    { dimension: "costInEuro", format: "float" },
    { dimension: "costInEuroPerGo", format: "float" },
    { dimension: "investmentValue", format: "float" },
    { dimension: "investmentValuePerGo", format: "float" },
    { dimension: "co2", format: "float" },
    { dimension: "costsVSRevenues", format: "float" },
  ] as AxisConfig[];

  const chartLabels = [
    "StackedHousing.BuildingGraph.Appartments",
    "StackedHousing.BuildingGraph.Height",
    "StackedHousing.BuildingGraph.Length",
    "StackedHousing.BuildingGraph.Floors",
    "StackedHousing.BuildingGraph.BVO",
    "StackedHousing.BuildingGraph.BBO",
    "StackedHousing.BuildingGraph.GO",
    "StackedHousing.BuildingGraph.FSI",
    "StackedHousing.BuildingGraph.GO/BVO",
    "StackedHousing.BuildingGraph.DirectBuildingCosts",
    "StackedHousing.BuildingGraph.DirectBuildingCostsPerGo",
    "StackedHousing.BuildingGraph.InvestmentValue",
    "StackedHousing.BuildingGraph.ValuePerGo",
    "StackedHousing.BuildingGraph.CO₂",
    "StackedHousing.BuildingGraph.CostsVSRevenues",
  ];

  const clickableAxes = [
    "nrOfAppartments",
    "structuralHeightInM",
    "lengthInM",
    "nrOfFloors",
    "grossFloorAreaInM2",
  ];

  const bestFitOptionsDictionary = {
    "StackedHousing.BuildingGraph.Height": "structuralHeightInM",
    "StackedHousing.BuildingGraph.Length": "lengthInM",
    "StackedHousing.BuildingGraph.Floors": "nrOfFloors",
    "StackedHousing.BuildingGraph.Appartments": "nrOfAppartments",
    "StackedHousing.BuildingGraph.BVO": "grossFloorAreaInM2",
    "StackedHousing.BuildingGraph.BBO": "grossFootprintAreaInM2",
    "StackedHousing.BuildingGraph.GO": "usableAreaInM2",
    "StackedHousing.BuildingGraph.FSI": "fsi",
    "StackedHousing.BuildingGraph.GO/BVO": "goBvo",
    "StackedHousing.BuildingGraph.DirectBuildingCosts": "costInEuro",
    "StackedHousing.BuildingGraph.DirectBuildingCostsPerGo": "costInEuroPerGo",
  };

  const bestFitOptionsDictionaryReversed = {
    structuralHeightInM: "StackedHousing.BuildingGraph.Height",
    lengthInM: "StackedHousing.BuildingGraph.Length",
    nrOfFloors: "StackedHousing.BuildingGraph.Floors",
    nrOfAppartments: "StackedHousing.BuildingGraph.Appartments",
    grossFloorAreaInM2: "StackedHousing.BuildingGraph.BVO",
    grossFootprintAreaInM2: "StackedHousing.BuildingGraph.BBO",
    usableAreaInM2: "StackedHousing.BuildingGraph.GO",
    fsi: "StackedHousing.BuildingGraph.FSI",
    goBvo: "StackedHousing.BuildingGraph.GO/BVO",
    costInEuro: "StackedHousing.BuildingGraph.DirectBuildingCosts",
    costInEuroPerGo: "StackedHousing.BuildingGraph.DirectBuildingCostsPerGo",
  };

  const inputPaneContent = () => (
    <>
      <div className="title">{""}</div>
      <CustomFormControl
        labelText={t("StackedHousing.AccessType")}
        showInfoButton={true}
        toolTipText={t("StackedHousing.AccessType")}
      >
        <Select
          multiple
          value={selectedAccessTypes}
          style={{ height: "auto" }}
          labelId="type-power-rack-label"
          id="type-power-rack-select"
          onChange={handleChangeAccessType}
          label={t("StackedHousing.AccessType")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={t(value)} />
              ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  accessTypeOptions.length > 0 &&
                  selectedAccessTypes.length === accessTypeOptions.length
                }
                indeterminate={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  selectedAccessTypes.length > 0 &&
                  selectedAccessTypes.length < accessTypeOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {accessTypeOptions?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAccessTypes?.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </>
  );

  const handleChangeAccessType = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    const valuesAccessType = Object.values(accessTypeOptions);

    if (selectedOptions.includes("all")) {
      setSelectedAccessTypes(
        selectedAccessTypes.length === valuesAccessType.length
          ? []
          : valuesAccessType
      );
    } else {
      const selectedAccessTypes = valuesAccessType.filter((accessType) =>
        accessType
          ? selectedOptions.includes(accessType.toString())
          : selectedOptions.includes("-1")
      );
      setSelectedAccessTypes(selectedAccessTypes);
    }
  };

  const filterConditions: FilterCondition<SpaceBuildingDto>[] = [];

  const handleObjectFilters = (objects: SpaceBuildingDto[]) => {
    let filteredBuidings = objects;
    if (selectedAccessTypes.length !== 0) {
      filteredBuidings = filteredBuidings.filter((datacenter) => {
        return selectedAccessTypes.includes(datacenter.accessType);
      });
    }

    setFilteredObjects(filteredBuidings);
    setFilteredObjectsBrushedOnPCG(filteredBuidings);

    return filteredBuidings;
  };
  function handleCustomFilter() {
    const tempWhitespaces = [...brushedObjects];
    return tempWhitespaces;
  }

  return {
    sortByOptionsDictionary,
    filterConditions,
    currentStore,
    summaryInfo,
    detailedInfo,
    inputPaneContent,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleObjectFilters,
    handleCustomFilter,
    defaultSelectedAxes,
  };
};

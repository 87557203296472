import LoadingIndicator from "../../../../../components/LoadingIndicator/LoadingIndicator";
import { DTO } from "../../../state/ExploreState/ExploreState";
import { DetailedInfo } from "../../../types/InfoField";
import DetailedViewSidebar from "../../DetailedViewSidebar/DetailedViewSidebar";
import SceneCanvas from "../SceneCanvas/SceneCanvas";
import "./SceneDetail.scss";

interface SceneDetailProps<T extends DTO> {
  currentObject: T;
  detailedInfo?: DetailedInfo[];
}
export default function SceneDetail<T extends DTO>({
  currentObject,
  detailedInfo,
}: SceneDetailProps<T>) {
  const currentTypical = currentObject as DTO;

  return (
    <div className="scene-details-view">
      {!currentTypical ? (
        <div className="loading-indicator-scene-details">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="tab-view">
          <div className="image-view">
            {currentTypical && <SceneCanvas currentObject={currentTypical} />}
          </div>
          <div className="scene-sidebar-view">
            <DetailedViewSidebar
              data={currentTypical}
              detailedInfo={detailedInfo ?? []}
            />
          </div>
        </div>
      )}
    </div>
  );
}

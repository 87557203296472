import * as d3 from "d3";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import { SpaceDto } from "../../../HSA/types/api";
import {
  AxisConfig,
  ExploreActions,
  ExploreState,
} from "../../state/ExploreState/ExploreState";
import { formatValue } from "../../utils/format";
import { useHover } from "../Scene/HoverContext";
import "./ParallelCoordinatesPlot.scss";

interface ParallelCoordinatesPlotProps<T extends SpaceDto> {
  currentState: ExploreState<T> & ExploreActions<T>;
  dimensions: AxisConfig[];
  chartLabels: string[];
  clickableAxes: string[];
  onBrushObjects: (
    brushedObjects: T[],
    sortProperty: string,
    isSortPropertyAscending: boolean
  ) => void;
}

export default function ParallelCoordinatesPlot<T extends SpaceDto>({
  currentState,
  dimensions,
  chartLabels,
  clickableAxes,
  onBrushObjects,
}: ParallelCoordinatesPlotProps<T>) {
  const { hovered } = useHover<T>();
  const { t } = useTranslation();
  const { isInputPaneOpen } = useDatacenterGeneratorStore();

  const plotRef = useRef<HTMLInputElement>();
  const foregroundRef = useRef<any>();

  const height = useRef<number>(0);
  const x = useRef(d3.scalePoint().range([0, 0]));
  const plot = useRef<any>();
  const y = useRef<{
    [key: string]: d3.AxisScale<d3.AxisDomain> & {
      brushSelectionValue?: any;
      invert?: any;
      brush?: any;
    };
  }>({});
  const prevHoveredRef = useRef<T>();
  const prevSelecedRef = useRef<string[]>([]);

  const selectedColor = "RGB(0, 51, 102)";
  const colors = useMemo(
    () =>
      d3.scaleSequential(
        d3.interpolateRgbBasis([
          "RGB(255, 124, 128)",
          "RGB(253, 220, 117)",
          "RGB(120, 177, 79)",
        ])
      ),
    []
  );

  const axisMinMaxValues = useRef<{
    [key: string]: { min: number; max: number };
  }>({});

  const getNestedValue = <T extends Record<string, any>>(
    obj: T,
    path: string
  ): any => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  useMemo(() => {
    currentState.selectedAxes?.forEach((axis) => {
      const col: number[] = currentState?.filteredObjects?.map((item: T) => {
        const value = getNestedValue(item, axis);
        return +value;
      });

      if (col && col.length > 0) {
        axisMinMaxValues.current[axis] = {
          min: Math.min(...col),
          max: Math.max(...col),
        };
      }
    });
  }, [currentState.selectedAxes, currentState?.filteredObjects]);

  const getColor = useCallback(
    (d: T): number => {
      if (!currentState.selectedAxes.length) {
        return 0;
      }

      const avgColorValue: number =
        currentState.selectedAxes.reduce((sum: number, axis: string) => {
          const { min, max } = axisMinMaxValues.current[axis];
          const value = getNestedValue(d, axis) as number;

          return max !== min ? sum + (value - min) / (max - min) : sum + 1 / 2;
        }, 0) / currentState.selectedAxes.length;

      return avgColorValue;
    },
    [currentState.selectedAxes]
  );

  const calculateChartDimensions = useCallback(
    (data: T[]) => {
      dimensions?.forEach((dim: AxisConfig) => {
        if (y.current) {
          const yAs = d3
            .scaleLinear()
            .range([height.current, 0])
            .domain(
              d3.extent(data, function (d: T) {
                const value = +(getNestedValue(d, dim.dimension) ?? 0);
                return value;
              }) as [number, number]
            );

          y.current[dim.dimension] = yAs as d3.AxisScale<d3.AxisDomain>;
        }
      });
    },
    [dimensions]
  );

  const path = useCallback(
    (d: T) => {
      return d3.line()(
        dimensions.map(function (p: AxisConfig) {
          const value = getNestedValue(d, p.dimension);
          return [
            x.current(p.dimension) ?? 0,
            y.current[p.dimension](Number(value)) || 0,
          ];
        })
      );
    },
    [dimensions]
  );

  const drawGraphs = useCallback(
    (data: T[]) => {
      return plot.current
        .append("g")
        .attr("class", "foreground selection-cursor")
        .selectAll("path")
        .data(data)
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", "none")
        .style("opacity", 0);
    },
    [path]
  );

  const updateLabelStyles = useCallback(() => {
    d3.selectAll<SVGTextElement, AxisConfig>("text").style(
      "font-weight",
      (d) => {
        return currentState.selectedAxes.some((axis) => axis === d.dimension)
          ? "bold"
          : "normal";
      }
    );

    d3.selectAll<SVGTextElement, string>("rect").style("fill", (d: string) =>
      currentState.selectedAxes.some((axis) => axis === d) ? "black" : "none"
    );
  }, [currentState.selectedAxes]);

  const drawVerticalAxes = useCallback(() => {
    return plot.current
      .append("g")
      .attr("class", "dimension")
      .selectAll("g")
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "axis")
      .attr("transform", function (d: AxisConfig) {
        const xPos = x.current(d.dimension);
        if (xPos === undefined) return "translate(0)";
        return "translate(" + xPos + ")";
      })
      .each(function (this: SVGGElement, d: AxisConfig) {
        const yScale = y.current[d.dimension];
        if (d.format === "integer") {
          const domain = yScale.domain() as number[];
          const range = Math.floor(domain[1]) - Math.ceil(domain[0]);
          const step = Math.ceil(range / 9);

          const ticks = d3.range(
            Math.ceil(domain[0]),
            Math.floor(domain[1]) + 1,
            step
          );
          const axis = d3.axisLeft(yScale).tickValues(ticks);
          d3.select(this).call(axis);
        } else {
          const axis = d3.axisLeft(yScale).tickFormat((d: any) => {
            if (d === undefined || d === null || Number.isNaN(d)) {
              return "";
            }
            return formatValue(d);
          });

          if (axis && typeof axis === "function") {
            d3.select(this).call(axis);
          }
        }
      });
  }, [dimensions]);

  const brushChart = (
    verticalAxes: {
      append: (arg0: string) => {
        (): T;
        new (): T;
        attr: {
          (arg0: string, arg1: string): {
            (): T;
            new (): T;
            each: {
              (arg0: (this: SVGGElement, d: AxisConfig) => void): void;
              new (): T;
            };
          };
          new (): T;
        };
      };
    },
    brush: d3.BrushBehavior<unknown>
  ) => {
    verticalAxes
      .append("g")
      .attr("class", "brush")
      .each(function (this: SVGGElement, d: AxisConfig) {
        d3.select(this).call((y.current[d.dimension].brush = brush));
      });
  };

  const brushMove = useCallback(() => {
    const actives: { dimension: string; extent: number[] }[] = [];
    plot.current
      .selectAll(".brush")
      .filter(function (this: SVGGElement, d: AxisConfig) {
        y.current[d.dimension].brushSelectionValue = d3.brushSelection(this);
        return d3.brushSelection(this);
      })
      .each(function (this: SVGGElement, d: AxisConfig) {
        const index = actives.findIndex(
          (active) => active.dimension === d.dimension
        );
        if (index !== -1) {
          actives.splice(index, 1);
        }

        actives.push({
          dimension: d.dimension,
          extent:
            d3.brushSelection(this)?.map(y.current[d.dimension].invert) || [],
        });
      });

    setCurrentBrushedObjects(actives);
  }, [currentState.filteredObjectsBrushedOnPCG]);

  const reAppendAxes = () => {
    const axes = plot.current.selectAll(".axis").nodes();
    axes.forEach((axis: Element) => {
      plot.current.node().appendChild(axis);
    });
  };

  const setCurrentBrushedObjects = (
    actives: { dimension: string; extent: number[] }[]
  ) => {
    const selected = foregroundRef.current?.data().filter(function (d: T) {
      return actives.every(function (active: {
        dimension: string;
        extent: number[];
      }) {
        const value = getNestedValue(d, active.dimension); // Safely access nested fields
        return active.extent[1] <= value && value <= active.extent[0];
      });
    });

    const brushed: T[] = selected.filter((d: T) =>
      currentState.filteredObjectsBrushedOnPCG.includes(d)
    );

    if (brushed.length !== 0) {
      currentState.setBrushedObjects(brushed);
    }
  };

  const makeSkeleton = useCallback(
    (allData: T[]) => {
      if (plotRef.current) {
        d3.select(plotRef.current).selectAll("svg").remove();
      }

      const margin = { top: 40, right: 0, bottom: 35, left: 0 };
      const width = plotRef.current?.clientWidth
        ? plotRef.current?.clientWidth - margin.left - margin.right
        : 0;
      height.current = plotRef.current?.clientHeight
        ? plotRef.current?.clientHeight - margin.top - margin.bottom
        : 0;

      plot.current = plotRef.current
        ? d3
            .select(plotRef.current)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .append("g")
            .attr(
              "transform",
              "translate(" + margin.left + "," + margin.top + ")"
            )
        : null;

      y.current = {};
      calculateChartDimensions(allData);
      x.current = d3
        .scalePoint()
        .range([0, width])
        .padding(1)
        .domain(dimensions.map((d) => d.dimension));

      const verticalAxes = drawVerticalAxes();

      verticalAxes.each(function (this: SVGGElement, d: AxisConfig, i: number) {
        const axis = d3.select(this);
        const axisWidth = 30;

        const words = t(chartLabels[i])
          .split(/[\s-]+/)
          .filter((word) => word.length > 0);

        const labelContainer = axis
          .append("g")
          .attr("class", "label-container")
          .attr(
            "transform",
            `translate(${-axisWidth / 2}, ${words.length * -10})`
          );

        const label = labelContainer
          .append("text")
          .attr("class", "axis-label")
          .attr("text-anchor", "middle")
          .attr("font-size", "10px")
          .attr("fill", "black");

        let line: string[] = [];
        const lineHeight = 1.2;
        words.forEach((word, j) => {
          line.push(word);
          label
            .append("tspan")
            .attr("x", axisWidth / 2)
            .attr("dy", j === 0 ? 0 : `${lineHeight}em`)
            .text(line.join(" "));

          line = [];
        });
      });

      const brush = d3
        .brushY()
        .extent([
          [-10, 0],
          [10, height.current],
        ])
        .on("brush", brushMove);

      brushChart(verticalAxes, brush);
      updateLabelStyles();
    },
    [
      calculateChartDimensions,
      dimensions,
      drawVerticalAxes,
      brushMove,
      t,
      chartLabels,
      updateLabelStyles,
      currentState.selectedAxes,
    ]
  );

  const styleGraphsOnChart = useCallback(() => {
    foregroundRef.current
      .style("stroke", function (d: T) {
        const graphColor = getColor(d);
        d.value = graphColor * 100;
        d.color = colors(graphColor);
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredObjectsBrushedOnPCG.includes(d)
            ? currentState?.selectedIds?.find((x) => x === d.code)
              ? selectedColor
              : colors(graphColor)
            : "grey";
        } else {
          return "grey";
        }
      })
      .style("opacity", function (d: T) {
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredObjectsBrushedOnPCG.includes(d)
            ? 1
            : 0.4;
        } else {
          return 1;
        }
      })
      .style("stroke-width", function (d: T) {
        if (currentState.brushedObjects.length !== 0) {
          return currentState.brushedObjects.includes(d) &&
            currentState.filteredObjectsBrushedOnPCG.includes(d)
            ? currentState?.selectedIds?.find((x) => x === d.code)
              ? 3
              : 1
            : 0.2;
        } else {
          return 1;
        }
      });

    reAppendAxes();
  }, [
    colors,
    getColor,
    currentState.brushedObjects,
    currentState.filteredObjectsBrushedOnPCG,
  ]);

  useEffect(() => {
    const prevSelectedItemsOnGraphSet = new Set(
      prevSelecedRef.current?.map((item) => item)
    );
    const prevSelectedOrHoveredItemsOnGraph = foregroundRef?.current?.filter(
      (item: T) =>
        prevSelectedItemsOnGraphSet.has(item.code) ||
        item.id === prevHoveredRef.current?.id
    );

    prevSelectedOrHoveredItemsOnGraph
      ?.style("stroke", function (d: T) {
        return colors(getColor(d));
      })
      .style("stroke-width", function () {
        return 1;
      });

    const selectedItemsSet = new Set(
      currentState.selectedIds.map((item) => item)
    );
    const selectedItemsOnGraph = foregroundRef?.current?.filter((item: T) =>
      selectedItemsSet.has(item.code)
    );

    const hoveredItemOnGraph = foregroundRef?.current?.filter(
      (item: T) => item.id === hovered?.id
    );
    hoveredItemOnGraph
      ?.raise()
      .style("stroke", function (d: T) {
        return "black";
      })
      .style("stroke-width", function (d: T) {
        return 3;
      });

    if (hovered) {
      prevHoveredRef.current = hovered;
    }

    selectedItemsOnGraph
      ?.raise()
      ?.style("stroke", function (d: T) {
        if (d.id === hovered?.id) return "black";
        else return "RGB(0, 51, 102)";
      })
      ?.style("stroke-width", function (d: T) {
        return 3;
      });

    prevSelecedRef.current = currentState.selectedIds.map((item) => item);
  }, [colors, getColor, currentState.selectedIds, hovered]);

  useEffect(() => {
    updateLabelStyles();
  }, [currentState.selectedAxes, updateLabelStyles]);

  useEffect(() => {
    if (foregroundRef.current) {
      styleGraphsOnChart();
    }
  }, [styleGraphsOnChart, isInputPaneOpen]);

  useEffect(() => {
    if (currentState.brushedObjects && currentState.brushedObjects.length > 0) {
      onBrushObjects(
        currentState.brushedObjects,
        currentState.objectSortProperty,
        currentState.objectSortAscending
      );
    }
  }, [
    currentState.brushedObjects,
    currentState.objectSortProperty,
    currentState.objectSortAscending,
    onBrushObjects,
  ]);

  useEffect(() => {
    drawGraph();
    function handleWindowWidthChange() {
      drawGraph();
    }
    window.addEventListener("resize", handleWindowWidthChange);
    return () => {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, [currentState.filteredObjects, currentState.isObjectsLoaded]);

  useEffect(() => {
    if (
      currentState.isReset ||
      currentState.filteredObjects ||
      currentState.filteredObjectsBrushedOnPCG
    ) {
      drawGraph();
    }
  }, [
    currentState.isReset,
    currentState.filteredObjects,
    currentState.filteredObjectsBrushedOnPCG,
  ]);

  function drawGraph() {
    if (currentState.isObjectsLoaded) {
      makeSkeleton(currentState.filteredObjects);
      foregroundRef.current = drawGraphs(currentState.filteredObjects);
      currentState.setBrushedObjects([
        ...currentState.filteredObjectsBrushedOnPCG,
      ]);

      const actives: any[] = [];
      if (actives.length > 0) {
        actives.forEach((active) => {
          try {
            const dimension = active.dimension;
            const extent = active.extent;
            if (!y.current[dimension] || isNaN(extent[0]) || isNaN(extent[1])) {
              return;
            }

            const brushMoveValues = extent.map((value: number) =>
              y.current[dimension](value)
            );
            if (
              !brushMoveValues ||
              brushMoveValues.some((value: number) => isNaN(value))
            ) {
              return;
            }

            plot.current
              .selectAll(".brush")
              .filter(function (d: number) {
                return d === dimension;
              })
              ?.call(y.current[dimension]?.brush?.move, brushMoveValues);
          } catch (ex) {}
        });
      }
    }
  }

  if (currentState.isObjectsLoaded) {
    return (
      <div
        ref={plotRef as React.MutableRefObject<HTMLDivElement | null>}
        className="noselect"
        style={{ width: "100%", height: "90%", cursor: "crosshair" }}
      ></div>
    );
  }
}

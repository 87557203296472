import { Fab } from "@mui/material";
import { GridApi, GridColDef, GridSortModel, useGridApiRef } from "@mui/x-data-grid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SplitButton from "../../../components/SplitButton/SplitButton";
import { ForgeApiUrl } from "../../../config";
import { ScrewDriverIcon } from "../../../icons/ScrewDriverIcon";
import colors from "../../../styles/colors.module.scss";
import {
  OpenDialogOptions,
  useDialog,
} from "../../sharedLogic/components/Dialog/CustomDialog";
import { FilterDefinition } from "../../sharedLogic/components/FilterableDataGrid/FilterableDataGrid";
import { TrackException } from "../../sharedLogic/logging/LoggingManager";
import { HttpService } from "../../sharedLogic/services/forge/httpService";
import { useGetApiHook } from "../../sharedLogic/services/forge/useGridApiHook";
import useUserStore from "../../sharedLogic/state/userState";
import {
  convertSquareMilimetersToSquareMeters,
  formatValue,
} from "../../sharedLogic/utils/format";
import { useModuleDetailsStore } from "../state/moduleDetailsState";
import {
  ModuleDto,
  SpaceCommonAreaDto,
  SpaceComponentDto,
  SpaceDto,
  SpaceResidentialDto,
} from "../types/api";
import { getColor } from "../utils/colors";
import GenerateBuildings from "./GenerateBuildings/GenerateBuildings";
import { ModuleDetailedView } from "./ModuleDetailedView/ModuleDetailedView";
import { ModuleDetailedDialogHeader } from "./ModuleDetailedView/ModuleDetailedViewHeader";
import { ModuleListView } from "./ModuleListView";
import ModuleThumbnail from "./ModuleThumbnail";

interface FloatingActionButtonProps {
  modules: SpaceDto[];
}

interface GridState {
  sortModel: GridSortModel;
  scrollPosition: { left: number; top: number };
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({
  modules,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { heijmansBlue } = colors;

  return (
    <Fab
      className="fab"
      aria-label="add"
      sx={{
        zIndex: 10,
        border: "3px solid white",
        borderColor: heijmansBlue,
        backgroundColor: "white",
        position: "fixed",
        height: 70,
        width: 70,
        bottom: 20,
        right: 20,
        "&:hover": {
          backgroundColor: heijmansBlue,
          "& .icon": {
            color: "white",
          },
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() =>
        openDialog({
          title: t("StackedHousing.CreateBuildings"),
          content: <GenerateBuildings modules={modules} />,
          width: "30%",
        } as OpenDialogOptions)
      }
    >
      <ScrewDriverIcon
        className={isHovered ? "icon-hovered" : "icon"}
        size={45}
      />
    </Fab>
  );
};

const MemoizedModuleListView = React.memo(ModuleListView);

export function ModuleCatalog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    modules,
    setModules,
    setFile,
    setIsEditing,
    setModuleCode,
    clearValidationErrors,
  } = useModuleDetailsStore();
  const { appAbility } = useUserStore();
  const { openDialog, closeDialog } = useDialog();

  const gridRef = useGridApiRef();
  const gridStateRef = useRef<GridState>({
    sortModel: [],
    scrollPosition: { left: 0, top: 0 },
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const moduleUrl = "api/v1/modules";
  const { data, loading } = useGetApiHook<SpaceDto>({
    baseUrl: ForgeApiUrl,
    url: moduleUrl,
  });

  useEffect(() => {
    if (data) {
      const processedModules = (data as SpaceDto[]).map((module) => ({
        ...module,
        grossFloorAreaInM2: convertSquareMilimetersToSquareMeters(
          module.grossFloorAreaInMm2
        ),
      }));
      setModules(processedModules);
    }
  }, [data, setModules]);

  const columns = useMemo(
    () =>
      [
        {
          field: "imgUrl",
          headerName: "",
          width: 150,
          renderCell: ({ value, row }) => (
            <ModuleThumbnail
              color={getColor(row.spaceType)}
              dimensions={row.dimensions}
            />
          ),
        },
        { field: "version", headerName: t("Version"), width: 65 },
        {
          field: "codeBusiness",
          headerName: t("StackedHousing.TypeHSA"),
          width: 100,
        },
        {
          field: "code",
          headerName: t("StackedHousing.TypeSynergy"),
          flex: 1,
        },
        { field: "description", headerName: t("Description"), flex: 1 },
        {
          field: "accessType",
          headerName: t("StackedHousing.AccessType"),
          flex: 1,
          renderCell: ({ value }) =>
            (value as string)
              ?.split(",")
              .map((x) => t(x.trim()))
              .join(", "),
          sortComparator: (v1, v2) => t(v1).localeCompare(t(v2)),
        },
        {
          field: "buildingLayer",
          headerName: t("StackedHousing.BuildingLayer"),
          flex: 1,
          renderCell: ({ value }) => t(value as string),
          sortComparator: (v1, v2) => t(v1).localeCompare(t(v2)),
        },
        {
          field: "spaceType",
          headerName: t("StackedHousing.ModuleType"),
          flex: 1,
          renderCell: ({ value }) => t(value as string),
          sortComparator: (v1, v2) => t(v1).localeCompare(t(v2)),
        },
        {
          field: "grossFloorAreaInM2",
          headerName: `${t("GO")} (m²)`,
          renderCell: ({ value }) => formatValue(value),
          width: 75,
        },
        {
          field: "owner",
          headerName: t("Owner"),
          flex: 1,
        },
      ] as GridColDef[],
    [t]
  );

  const filters = useMemo(() => {
    if (!modules) return [];

    return [
      {
        key: "accessType",
        label: t("StackedHousing.AccessType"),
        type: "checkbox",
        options: Array.from(new Set(modules.flatMap((d) => d.accessType))).sort(
          (a, b) => a.localeCompare(b)
        ),
      },
      {
        key: "spaceType",
        label: t("StackedHousing.ModuleType"),
        type: "checkbox",
        options: Array.from(new Set(modules.map((d) => d.spaceType))).sort(
          (a, b) => a.toString().localeCompare(b.toString())
        ),
      },
      {
        key: "buildingLayer",
        label: t("StackedHousing.BuildingLayer"),
        type: "checkbox",
        options: Array.from(new Set(modules.map((d) => d.buildingLayer))).sort(
          (a, b) => a.localeCompare(b)
        ),
      },
      {
        key: "grossFloorAreaInM2",
        label: t("GO"),
        type: "range",
        options: [
          Math.min(...modules.map((d) => d.grossFloorAreaInM2 ?? 0)).toString(),
          Math.max(...modules.map((d) => d.grossFloorAreaInM2 ?? 0)).toString(),
        ],
      },
    ] as FilterDefinition[];
  }, [modules]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    gridStateRef.current.sortModel = newModel;
  };

  const saveGridState = useCallback(() => {
    if (gridRef.current) {
      const scrollPosition = gridRef.current.getScrollPosition();
      gridStateRef.current.scrollPosition = scrollPosition;
    }
  }, []);

  const restoreGridState = useCallback(() => {
    requestAnimationFrame(() => {
      if (gridRef.current) {        
        gridRef.current.scroll(gridStateRef.current.scrollPosition);
      }
    });
  }, []);

  function redirectToRelevantMatrixPage(accessType: string) {
    switch (accessType) {
      case "HSAGallery":
        navigate("/heijmansintelligence/stackedhousing/gallery");
        break;
      case "HSACorridor":
        navigate("/heijmansintelligence/stackedhousing/corridor");
        break;
      case "HSAPorchway":
        navigate("/heijmansintelligence/stackedhousing/porchway");
        break;
      default:
        break;
    }
  }

  const deleteTypical = async (code: string) => {
    try {
      const result = await new HttpService(ForgeApiUrl).delete<number>(
        `${moduleUrl}/${code}`
      );
      if (result !== 0) {
        // setTypicals((prevTypicals) =>
        //   prevTypicals.filter((typical) => typical.code !== code)
        // );
      }
    } catch (ex) {
      TrackException(ex);
    }
  };

  const closeCurrentDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const openModuleDetails = useCallback(
    (code?: string, module?: ModuleDto) => {
      saveGridState();
      const currentCode = module?.code ?? code ?? "";
      setFile(null);
      setIsEditing(currentCode === "");
      setModuleCode(currentCode);
      clearValidationErrors();
      openDialog({
        title: currentCode,
        content: (
          <ModuleDetailedView currentModuleCode={code} currentModule={module} />
        ),
        width: "75%",
        height: "85%",
        dialogCustomHeader: (
          <ModuleDetailedDialogHeader
            onClose={() => {
              closeCurrentDialog();
              restoreGridState();
            }}
            onSave={redirectToRelevantMatrixPage}
          />
        ),
      });
    },
    [openDialog, closeCurrentDialog, setModuleCode]
  );

  return (
    <>
      <MemoizedModuleListView
        gridRef={gridRef}
        data={modules}
        loading={loading}
        columns={columns}
        filters={filters}
        idPropertyName="code"
        title={"StackedHousing.ModulesTitle"}
        subtitle={"StackedHousing.ModulesSubtitle"}
        openTypicalDetails={openModuleDetails}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        buttons={[
          {
            label: "StackedHousing.SwitchingMatrix",
            onClick: () => navigate("../heijmansintelligence/stackedhousing"),
          },
          {
            label: "Create",
            onClick: () => {},
            customComponent: (
              <SplitButton
                disabled={appAbility?.cannot(
                  "create",
                  "catalog.stackedhousing"
                )}
                buttonText="Create"
                definitions={[
                  {
                    label: "StackedHousing.Residential",
                    onClick: () => {
                      openModuleDetails("", {
                        usableAreaInMm2: undefined,
                        rentalPoints: undefined,
                        dimensions: {},
                      } as SpaceResidentialDto);
                    },
                  },
                  {
                    label: "StackedHousing.CommonArea",
                    onClick: () => {
                      openModuleDetails("", {
                        bayWidth1: undefined,
                        dimensions: {},
                      } as SpaceCommonAreaDto);
                    },
                  },
                  {
                    label: "StackedHousing.Component",
                    onClick: () => {
                      openModuleDetails("", {
                        dimensions: {},
                      } as SpaceComponentDto);
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />

      {appAbility?.can("manage", "catalog.stackedhousing") && (
        <FloatingActionButton modules={modules} />
      )}
    </>
  );
}

import { Slider } from "@mui/material";

interface SliderWithMarksProps {
  min: number;
  max: number;
  value: number[];
  setValue: (value: number[]) => void;
  steps?: number;
}

export default function SliderWithMarks({
  min,
  max,
  value,
  setValue,
  steps,
}: SliderWithMarksProps) {
  const marks = [
    { value: min, label: `${min}`, style: { marginLeft: "-40px" } },
    { value: max, label: `${max}`, style: { marginLeft: "-30px" } },
  ];

  return (
    <Slider
      value={value}
      onChange={(_event: Event, newValue: number[] | number) => {
        if (Array.isArray(newValue)) {
          setValue(newValue);
        }
      }}
      valueLabelDisplay="auto"
      step={steps ? steps : 1}
      min={min}
      max={max}
      marks={marks}
      disabled={min === Infinity}
      sx={{
        margin: "0px",
        color: "#003366",
        "& .MuiSlider-track": {
          height: "3px",
          opacity: 0.8,
          backgroundColor: "#003366",
        },
        "& .MuiSlider-rail": {
          height: "2px",
          opacity: 0.6,
          backgroundColor: "#003366",
        },
        "& .MuiSlider-thumb": {
          backgroundColor: "white",
          border: "1px solid #003366",
          height: "18px",
          width: "18px",
        },
        "& .MuiSlider-markLabel": {
          top: "25px",
        },
      }}
    />
  );
}
